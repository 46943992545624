<div class="w-full">
  <mat-label *ngIf="title">{{ title }}</mat-label>
  <div class="relative">
    <ngx-dropzone
      *ngIf="!fileUploaded"
      class="dropzone w-full border-2 border-dashed border-primary-500 rounded-md flex items-center justify-center bg-neutral"
      (change)="onSelectFile($event)"
      [accept]="accept"
      [multiple]="multiple"
      [maxFileSize]="maxFileSize"
      [dz-remove-button-text]="''"
    >
      <ngx-dropzone-label>
        <div class="flex flex-col items-center justify-center">
          <mat-icon
            class="text-neutral-200"
            svgIcon="mat_solid:cloud_upload"
          ></mat-icon>
          <span class="text-lg text-neutral-50"
            >Drag and drop your file here or upload from your computer</span
          >
          <button
            mat-flat-button
            class="rounded-md max-h-9 h-9 bg-primary mt-5 mb-5"
          >
            Choose a File
          </button>
          <ng-container *ngIf="accept">
            <span class="text-xs text-neutral-50 leading-3">
              Supported files: {{ accept }}
            </span>
          </ng-container>
          <ng-container *ngIf="maxFileSize">
            <span class="text-xs text-neutral-50 mt-2 leading-3">
              Max file size: {{ maxFileSize | filesize }}
            </span>
          </ng-container>
        </div>
      </ngx-dropzone-label>
    </ngx-dropzone>
    <div class="preview">
      <ngx-dropzone-preview
        *ngFor="let f of files"
        [removable]="true"
        (removed)="onRemoveFile(f)"
        class="bg-neutral border border-neutral-400 rounded-lg"
      >
        <ngx-dropzone-label>
          <div class="flex flex-row items-center justify-start gap-2">
            <div
              class="flex items-center justify-center w-10 h-10 rounded-full bg-neutral-650"
            >
              <mat-icon
                class="scale-75 justify-start text-primary"
                *ngIf="f.type.includes('image')"
                svgIcon="mat_solid:image"
              ></mat-icon>
              <mat-icon
                class="scale-75 justify-start text-primary"
                *ngIf="f.type.includes('video')"
                svgIcon="mat_solid:movie"
              ></mat-icon>
              <mat-icon
                class="scale-75 justify-start text-primary"
                *ngIf="f.type.includes('audio')"
                svgIcon="mat_solid:volume_up"
              ></mat-icon>
              <mat-icon
                class="scale-75 justify-start text-primary"
                *ngIf="f.type.includes('text')"
                svgIcon="mat_solid:text_fields"
              ></mat-icon>
            </div>
            <div class="flex flex-col items-start justify-start">
              <span #ellipsisEle class="text-sm text-neutral-50 ellipsis" matTooltip="{{ f.name }}" [matTooltipDisabled]="!isTooltipEnabled">{{ f.name }}</span>
              <span class="text-sm text-neutral-50">{{
                f.size | filesize
              }}</span>
            </div>
          </div>
        </ngx-dropzone-label>
      </ngx-dropzone-preview>
    </div>
  </div>
</div>
