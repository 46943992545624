import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'shared-form-boxed-radio-button',
  styles: [
    `
      :host {
        ::ng-deep {
          .mat-radio-button {
            width: 100%;
          }
          .mat-radio-button {
            .boxed-radio-content {
              border-width: 2px;
              border-color: transparent;
            }
          }
          .mat-radio-button.mat-radio-checked {
            .boxed-radio-content {
              border-color: theme('colors.primary.DEFAULT');
              border-width: 2px;
            }
          }
          .mat-radio-button.mat-radio-checked {
            .mat-radio-outer-circle {
              border-color: theme('colors.primary.DEFAULT') !important;
            }
          }
          .mat-radio-button.mat-radio-checked {
            .mat-radio-inner-circle {
              background-color: #ffffff !important;
            }
          }
          .mat-radio-label {
            white-space: normal !important;
            width: 100%;
          }
          .mat-radio-label-content {
            display: flex;
            width: 100%;
          }
        }
      }
    `,
  ],
  template: `<mat-radio-group
    aria-labelledby="shared-form-boxed-radio-button"
    class="boxed-radio-button-selection"
    [formControl]="control"
  >
    <div class="w-full mb-2" *ngFor="let option of options">
      <mat-radio-button
        class="boxed-radio-button-selection-radio-button"
        [value]="option"
        (change)="handleSelect($event)"
      >
        <span
          class="boxed-radio-content box-border w-full bg-neutral-700 p-3 rounded"
        >
          {{ option.message }}
        </span>
      </mat-radio-button>
    </div>
  </mat-radio-group>`,
})
export class FormBoxedRadioButtonComponent implements OnInit {
  @Input() options: any[];
  @Input() control: FormControl;
  @Output() onSelect = new EventEmitter();
  ngOnInit(): void {}

  handleSelect(event: MatRadioChange): void {
    this.onSelect.emit(event.value);
  }
}
