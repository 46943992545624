import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { MapLibreService } from 'app/modules/dashboard/gis-map/services/map-libre.service';

@Component({
  selector: 'zoom-tool',
  templateUrl: './zoom-tool.component.html',
  styleUrls: ['./zoom-tool.component.scss'],
})
export class ZoomToolComponent implements OnInit {
  @Input() zoomStep: number = 1;
  @Input() minZoom: number = 0;
  @Input() maxZoom: number = 24;
  @Input() isUseSlider: boolean = true;
  @Input() isMinimap: boolean = false;
  @Output() setZoomTo = new EventEmitter<number>();
  @Output() setZoom = new EventEmitter<string>();

  zoomValue: number = 0;

  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    if (!this.isMinimap) {
      this.dashboardService.mapZoom$.subscribe((zoom) => {
        // Inside the subscription, use Promise.resolve().then() to ensure that the 'setZoomValue' function
        // is called in the next tick of the event loop. This helps in avoiding any change detection issues.
        Promise.resolve().then(() => this.setZoomValue(zoom));
      });
    }
  }

  onZoomIn() {
    if (this.zoomValue < this.maxZoom) {
      this.setZoomValue(this.zoomValue + this.zoomStep);
      this.setZoom.emit('zoom-in');
    }
  }

  onZoomOut() {
    if (this.zoomValue > this.minZoom) {
      this.setZoomValue(this.zoomValue - this.zoomStep);
      this.setZoom.emit('zoom-out');
    }
  }

  sliderChange(event: MatSliderChange): void {
    this.setZoomValue(event.value);
    this.setZoomTo.emit(event.value);
  }

  private setZoomValue(zoom: number) {
    this.zoomValue = zoom;
  }
}
