<mat-form-field
  id="rm-auto-complete"
  class="w-full relative"
  [ngClass]="{
    invalid: validation === 'invalid'
  }"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    type="text"
    [placeholder]="placeholder"
    matInput
    [matAutocomplete]="auto"
    [formControl]="formControl"
    (input)="onHandleTyped($event.target.value)"
  />
  <div
    class="fake-placeholder absolute w-full text-neutral-200"
    *ngIf="
      !isPanelOpened && (!formControl.value || formControl.value.length === 0)
    "
  >
    {{ placeholder }}
  </div>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="handleChangeOption($event.option)"
    (opened)="panelOpened()"
    (closed)="panelClosed()"
  >
    <mat-option
      *ngFor="let option of filteredOptions | async"
      [value]="option.value"
    >
      {{ option.display }}
    </mat-option>
  </mat-autocomplete>
  <ng-content select="[icon]"></ng-content>
</mat-form-field>
<div *ngIf="validation === 'invalid'" class="text-[#dc2626] -mt-4 mb-4">
  <div class="flex items-center">
    <mat-icon class="scale-75 text-[#dc2626]">warning</mat-icon> This field is
    required
  </div>
</div>
