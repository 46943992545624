import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MonitoringRobot } from 'app/modules/dashboard/dashboard.service';
import { map } from 'rxjs';
import { AppService } from 'app/app.service';
import { ApiRobot, ListQuery } from 'rm-api-services/dist/api-services';

export interface StateMonitoringRobot extends MonitoringRobot {
  stateDetail?: string;
}

@Component({
  selector: 'malfunction-issue-dialog',
  templateUrl: './issue-dialog.component.html',
})
export class MalfunctionIssueDialogComponent implements OnInit {
  displayedColumns: string[] = ['name', 'issues', 'location', 'liveView'];
  robotData: MatTableDataSource<MonitoringRobot>;
  dataLayouts = [];
  totalRobots: number;
  dataRobotIds = [];
  isLoading: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: string[],
    private appService: AppService,
    private robotApi: ApiRobot
  ) {
    this.totalRobots = data.length;

    this.getRobots();
  }

  ngOnInit(): void {}

  gotoTeleops(robotId: string) {
    window.open(`/teleoperation/${robotId}`, robotId);
  }

  public getRobots() {
    const payload: ListQuery = {
      pageNo: 1,
      pageSize: 20,
    };
    this.isLoading = true;
    this.robotApi
      .getAllDataList(payload)
      .then((robots) => {
        this.isLoading = false;
        const datas = robots.filter((robot) => this.data.includes(robot.id));

        this.robotData = new MatTableDataSource(datas);
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
