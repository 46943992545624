<h3 class="text-3xl font-bold text-neutral-50">Select Robot</h3>

<p class="mt-1 text-neutral-100">
  The order of robots are arranged based of proximity from the incident site.
</p>

<div class="mt-8">
  <!-- Robot List -->
  <ng-container *ngIf="robotOnlineList.length > 0">
    <mat-radio-group class="boxed-radio-button-selection">
      <mat-radio-button
        *ngFor="let robot of robotOnlineList"
        class="flex justify-between items-center mb-4 robot-list-radio-button"
        [value]="robot.id"
        (change)="selectRobot($event.value)"
      >
        <app-robot-list-item [robot]="robot"></app-robot-list-item>
      </mat-radio-button>
    </mat-radio-group>
  </ng-container>
  <!-- All robots offline state -->
  <shared-no-robot-available
    *ngIf="robotOnlineList.length === 0"
  ></shared-no-robot-available>
</div>
