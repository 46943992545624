<div
  class="toast-item relative flex flex-row justify-start h-auto gap-1 mb-2 px-4 py-6 transition-all border-solid rounded-lg bg-neutral-500 border-neutral-800 border-x border-y drop-shadow-md"
>
  <div
    class="absolute top-1 right-1 cursor-pointer scale-75"
    (click)="closeCardToast()"
  >
    <mat-icon
      [svgIcon]="'mat_solid:close'"
      class="malfunction-icon text-neutral-300"
    ></mat-icon>
  </div>

  <div>
    <mat-icon
      [svgIcon]="'mat_outline:dispatch_robot'"
      class="malfunction-icon mr-2 text-white pt-[2px]"
    ></mat-icon>
  </div>
  <div class="flex flex-col">
    <span class="block mb-2 font-semibold"
      >{{ data.data.robot?.name }} has arrived at
      {{
        mission?.description ? mission?.description : 'dispatch location'
      }}</span
    >
    <div class="flex flex-row gap-4">
      <button
        mat-flat-button
        class="h-10 transition bg-white rounded-md max-h-10 text-neutral hover:text-primary-500"
        (click)="gotoTeleops()"
      >
        <mat-icon class="mr-1" svgIcon="mat_solid:camera_rear"></mat-icon>
        Live View
      </button>
      <button
        class="font-medium text-base leading-5 px-3 py-[7px]"
        (click)="gotoDetails(data.data.dispatchId)"
      >
        Details
      </button>
    </div>
  </div>
</div>
