import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiBase,
  Notification,
  ResponseList,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiNotification extends ApiBase<Notification> {
  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = 'notification';
  }

  // patch Notification to "read"
  // to mark as read, just need to send "action" with value "read" (for singel notification id or array of notification id)
  // to "read" one by one need to send query param
  //to mark as read all, need to send user id
  public markAsRead(
    notificationId?: string,
    userId?: string
  ): Observable<ResponseList<null>> {
    //mark as read for individual or array of notifications ID
    if (notificationId) {
      return this.patch(`${this.resourceBaseUrl}?ids=${notificationId}`, {
        action: 'read',
      });
    }

    //mark as read for all notifications
    if (userId) {
      return this.patch(`${this.resourceBaseUrl}/mark-all-as-read`, {
        userId: userId,
        action: 'read',
      });
    }
  }
}
