import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RobotDetail } from 'app/modules/dashboard/robot-detail/robot-detail.component';
import {
  ApiBase,
  ListQuery,
  ResponseList,
  Event,
  ApiEvent,
  EventMedia,
} from 'rm-api-services/dist/api-services';
import { IResponse } from 'rm-api-services/dist/api-services/lib/robot_maps.model';
import { Observable, ReplaySubject } from 'rxjs';
import { EventFilterTime } from './api.types';

export interface EventWithImage extends Event {
  companyId: string;
  createdAt: string;
  createdBy: string;
  date: string;
  deletedAt?: string;
  description: string;
  id: string;
  isNew?: boolean;
  eventId?: string;
  label: string[];
  layout: ImageXYZ;
  layoutId: string;
  location: string;
  mediaFiles: EventMediaWithPanoramic[];
  mission: string;
  missionId: string;
  point: WorldCoordinates;
  read: boolean;
  robotId: string;
  severity: number;
  source: string;
  status: number;
  title: string;
  updatedAt: string;
  updatedBy: string;
  mediaUrl?: string;
  isVideo?: boolean;
  layoutName?: string;
  robotName?: string;
  timestamp?: number;
  metadata?: any;
}

export interface EventMediaWithPanoramic extends EventMedia {
  rotatable: number;
}

export interface WorldCoordinates {
  lng: number;
  lat: number;
  alt: number;
}

export interface ImageXYZ {
  x: number;
  y: number;
  z: number;
}

@Injectable({
  providedIn: 'root',
})
export class ApiEventDetection extends ApiBase<Event> {
  //use to check if the user update the detection from unresolve to resolve or vise versa
  isAfterActionDetectionSubject = new ReplaySubject<boolean>(1);

  //use to send data from robot-details component to filter detection panel base on robotId/robot details
  filterEventBySelectedRobot = new ReplaySubject<RobotDetail>(1);

  constructor(
    http: HttpClient,
    private _apiEvent: ApiEvent,
    private _sanitizer: DomSanitizer,
    @Inject('environment') environment: any
  ) {
    super(http, environment);
    this.resourceBaseUrl = 'events';
  }

  loadEventsImage(
    data: EventWithImage,
    isAllData: boolean = false,
    original: boolean = false
  ): EventWithImage {
    let remapData = data;
    //check if "data" has one media file or more
    //isAllData === true meaning "data" has more than one media file
    if (!isAllData) {
      if (
        data.mediaFiles &&
        data.mediaFiles[0] &&
        data.mediaFiles[0].mediaUrl
      ) {
        let mediaUrl: string;
        let isVideo: boolean = false;

        //get the 1st image only
        if (original && data.mediaFiles[0].mediaType !== 2) {
          mediaUrl = `${data.mediaFiles[0].mediaUrl}_origin`;
          isVideo = data.mediaFiles[0].mediaType === 2;
        } else {
          //for detection panel
          //looping to make sure the mediaUrl is for image (not video)
          for (const media of data.mediaFiles) {
            if (media.mediaType !== 2 && media.rotatable !== 1) {
              mediaUrl = media.mediaUrl;
              isVideo = media.mediaType === 2;
              break;
            }
          }
        }

        this.getImage(mediaUrl, (result: string) => {
          remapData.mediaUrl = result;
          remapData.isVideo = isVideo;
        });
      } else {
        remapData.mediaUrl = undefined;
        remapData.isVideo = false;
      }
    } else {
      remapData.mediaFiles.map((media) => {
        let mediaUrl: string;
        if (original && media.mediaType !== 2) {
          mediaUrl = `${media.mediaUrl}_origin`;
        } else {
          mediaUrl = media.mediaUrl;
        }
        this.getImage(mediaUrl, (result: string) => {
          media.mediaUrl = result;
        });
      });
    }
    return remapData;
  }

  //get the Image from Api Get Asset File of Api Event
  getImage(mediaUrl: string, callback) {
    if (mediaUrl) {
      this._apiEvent.getAssetFile(mediaUrl).subscribe((result: Blob) => {
        const unsafeImageUrl = URL.createObjectURL(result);
        callback(this._sanitizer.bypassSecurityTrustUrl(unsafeImageUrl));
      });
    }
  }

  postComment(data: {
    content: string;
    eventId: string;
  }): Observable<IResponse> {
    return this.post(
      `event-comment
    `,
      data
    );
  }

  getComments(payload): Observable<IResponse> {
    return this.post(`event-comments/list`, payload);
  }

  updateStatus(id: string, data: { action: string }): Observable<IResponse> {
    return this.http.put<IResponse>(
      `${this.base}/${this.resourceBaseUrl}/${id}`,
      data,
      this.headers
    );
  }

  //get the event payload from json
  getEventFilterTime(): Promise<EventFilterTime> {
    return new Promise((resolve, reject) => {
      fetch('/assets/config/filter-event-by-time.json')
        .then((response) => response.text())
        .then((data) => {
          const parseJson = JSON.parse(data);
          resolve(parseJson);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  // Recursive method to fetch all data with a query payload
  public getAllComments(queryPayload): Promise<IResponse> {
    const { pageNo, pageSize = 50, ...restQueryParams } = queryPayload;

    // check if pageNo is valid
    if (typeof pageNo !== 'number' || pageNo < 1) {
      return Promise.reject(new Error('Invalid pageNo'));
    }

    return this.getComments({
      pageNo,
      pageSize,
      ...restQueryParams, // Merge any additional query parameters
    })
      .toPromise()
      .then((res: IResponse) => {
        const data = res.result.list;
        const totalRecords = res.result.totalRecords;

        if (data.length === 0 || pageNo * pageSize >= totalRecords) {
          // All data has been fetched or reached the end
          return data;
        } else {
          // Fetch the next page of data
          return this.getAllComments({
            ...queryPayload,
            pageNo: pageNo + 1,
          })
            .then((dataRes) =>
              // Concatenate the current and next page of data
              data.concat(dataRes)
            )
            .catch((err) => Promise.reject(err));
        }
      })
      .catch((err) => Promise.reject(err));
  }
}
