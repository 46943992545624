import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CallService } from 'app/services/call.service';
import { CallRobot } from 'app/modules/teleoperations/teleconference/teleconference.component';
import { QUEUE_BAR_DATA } from 'ngx-mat-queue-bar';

/**
 * @title Snack-bar with configurable position
 */
@Component({
  selector: 'global-incomingcall',
  templateUrl: 'global-incomingcall.component.html',
  styleUrls: ['global-incomingcall.component.scss'],
})
export class GlobalIncomingCall implements OnInit, OnDestroy {
  callData: CallRobot;
  robotname: string;

  constructor(
    private callSvc: CallService,
    @Inject(QUEUE_BAR_DATA) public data
  ) {
    this.callData = data;
    this.robotname = data.name;
    this.callSvc.playRingtone();
    setTimeout(() => {
      this.callSvc.stopRingTone(true);
    }, 30000);
  }

  ngOnInit(): void {
    window.addEventListener('storage', () => {
      const answer = JSON.parse(localStorage.getItem('crossTabAnswer'));
      const decline = JSON.parse(localStorage.getItem('crossTabDecline'));
      if (answer) {
        this.callSvc.dismissAllcalls();
        localStorage.setItem('crossTabAnswer', 'false');
      }
      if (decline != 'null') {
        this.callSvc.dismissCall(decline?.robotId);
        localStorage.setItem('crossTabDecline', 'null');
      }
    });

    this.callSvc.channel.onmessage = (event) => {
      if (event.data.type === 'answerCall') {
        // answer call from other tab will close the call, only one call can be answered
        this.callSvc.dismissAllcalls();
      }
      // if the user decline also hide the call for this modal if robot id is the same
      if (event.data.type === 'declineCall') {
        if (event.data.data.robotId === this.callData.robotId) {
          this.callSvc.dismissCall(event.data.data.robotId);
        }
      }
    };
  }

  answerCall(call) {
    this.callSvc.stopRingTone();
    localStorage.setItem('answeredCall', JSON.stringify(call));
    //save the current call in localstorage
    this.callSvc.dismissAllcalls(); // answer 1 call, dismisses all other calls from this user

    //if the user is in another teleoperations page
    this.callSvc.channel.postMessage({ type: 'answerCall', data: call });
    window.open(`/teleoperation/${call.robotId}`, call.robotId);
  }

  declineCall(call) {
    this.callSvc.dismissCall(call.robotId);
    this.callSvc.channel.postMessage({ type: 'declineCall', data: call });
    localStorage.setItem('crossTabDecline', JSON.stringify(this.callData));
    this.callSvc.declineCall(call);
    this.callSvc.stopRingTone();
  }

  ngOnDestroy() {
    this.callSvc.stopRingTone();
  }
}
