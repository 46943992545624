<div>
  <h3 class="text-3xl font-bold text-neutral-50">Patrol areas</h3>

  <p class="mt-1 text-neutral-100">
    Command robot to move along a route of multiple destinations within a
    building.
  </p>

  <!-- PM request to disable this component for now. In case we need it later on, we can enable it -->
  <!-- Select Start Job  -->
  <div class="mt-5 max-w-[450px]">
    <!-- <rm-select-start-job-type
      [label]="'When does this job start?'"
      (selectionChange)="selectStartJobType($event)"
      (jobSchedule)="jobSchedule($event)"
    ></rm-select-start-job-type> -->
  </div>

  <p class="mt-0 font-normal text-white">Zone</p>

  <div class="flex items-start justify-center gap-4 -mt-4">
    <div class="mt-5 w-5/12 flex flex-col justify-center">
      <div class="flex flex-col justify-center w-full routing-list">
        <!-- Item -->
        <div
          *ngFor="let zone of selectedZoneList; let i = index"
          class="flex justify-between routing-drag-item"
        >
          <!-- Dropdown field -->
          <div class="w-full">
            <div *ngIf="zone !== ''">
              <patrol-routing-name
                [name]="getZoneName(zone)"
              ></patrol-routing-name>
            </div>

            <shared-form-select
              *ngIf="zone === ''"
              class="w-56 max-w-56"
              [data]="selectZones"
              [showLabel]="false"
              [selectedOption]="zone"
              (selectionChange)="handleChangeZone($event, 'option', i)"
            ></shared-form-select>
          </div>
          <!-- ./ Dropdown field -->

          <!-- delete button -->
          <button
            class="self-center w-5 h-5 mb-3 ml-2"
            (click)="removeZoneDropdown(i)"
            title="Remove destination"
          >
            <mat-icon
              svgIcon="feather:x-circle"
              class="text-neutral-200 icon-size-4"
            ></mat-icon>
          </button>
          <!-- ./ delete button -->
        </div>
        <!-- ./ End item -->
      </div>
      <button
        *ngIf="selectZones.length !== selectedZoneList.length"
        class="flex items-center text-white justify-center gap-2 bg-neutral-400 hover:bg-neutral-300 p-2 rounded w-[calc(100%-28px)]"
        [ngClass]="{
          'cursor-default': disableAddZonePatrol,
          'opacity-70': disableAddZonePatrol
        }"
        (click)="addZoneDropdown()"
      >
        <mat-icon class="text-white" svgIcon="mat_outline:add"></mat-icon>
        Add more
      </button>
    </div>

    <div class="w-full mt-5">
      <shared-mini-layout-map
        [isShowAlert]="true"
        (changeMarker)="handleChangeZone($event.event, $event.action)"
      >
        <div messageAlert>Click on zone to set the patrol area.</div>
      </shared-mini-layout-map>
    </div>
  </div>

  <div class="w-full mt-5">
    <rm-repeat
      radioButtonLabel="Repeat patrol"
      inputFieldLabel="No. of repeat"
      placeholder="1"
      inputType="number"
      [min]="1"
      (onRepeatChange)="handleRepeatChange($event)"
    ></rm-repeat>
  </div>

  <ng-container *ngIf="blinkingLightsSkill">
    <mat-divider class="my-6"></mat-divider>
    <div class="w-full">
      <slide-toggle
        class="mt-2"
        [label]="'Blinking lights'"
        [description]="'Flash blinking lights until this job is completed.'"
        (onChange)="handleBlinkingLightsCheck($event)"
      ></slide-toggle>
    </div>
  </ng-container>

  <ng-container *ngIf="broadcastMessageSkill || ttsMessageSkill">
    <mat-divider class="mt-6 mb-8"></mat-divider>
    <div class="flex flex-row w-full">
      <slide-toggle
        class="mt-2"
        (onChange)="handleBroadcastMessageCheck($event)"
      ></slide-toggle>
      <div class="flex flex-grow">
        <broadcast-job-creation
          class="w-full"
          [robot]="robot"
          [robotSkills]="robotSkills"
          [skill]="broadcastMessageSkill"
          [isChecked]="isBroadcastMessageCheck"
          [isConcurrentSkill]="true"
          (tasksData)="taskDataBroadcastMessage($event)"
        ></broadcast-job-creation>
      </div>
    </div>
  </ng-container>

  <div class="w-full" #bottomOfJobCreation></div>
</div>
