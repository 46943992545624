import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';

/**
 * Sanitize HTML
 */
@Pipe({
  name: 'safeUrl',
})
export class SafeUrlPipe implements PipeTransform {
  /**
   * Pipe Constructor
   *
   * @param _sanitizer: DomSanitezer
   */
  constructor(protected _sanitizer: DomSanitizer) {}

  public transform(url: string): SafeResourceUrl {
    // const token = sessionStorage.getItem('accessToken');
    // const authorisedUrl = `${url}?Authorization=${encodeURIComponent(token)}`;
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
