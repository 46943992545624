import { Component, Input } from '@angular/core';
import { Robot } from 'rm-api-services/dist/api-services';

@Component({
  selector: 'app-robot-list-item',
  template: `
    <div class="flex items-center justify-between gap-4">
      <span
        class="text-base font-semibold text-neutral-50 rounded-lg bg-neutral-650 py-5 px-4 w-full"
      >
        {{ robot.name }}
      </span>
      <div class="flex items-center justify-between min-w-[185px]">
        <!-- Robot Status -->
        <robot-status [robot]="robot"></robot-status>
        <!-- Robot Battery -->
        <shared-robot-battery
          [percentage]="robot.battery"
        ></shared-robot-battery>
      </div>
    </div>
  `,
})
export class RobotListItemComponent {
  @Input() robot: Robot;
}
