import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, ReplaySubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import {
  ApiBase,
  ListQuery,
  Mission,
  ResponseList,
} from 'rm-api-services/dist/api-services';

@Injectable({
  providedIn: 'root',
})
export class NewJobService extends ApiBase<Mission> {
  updateSumarry$ = new Subject<boolean>();

  //use to check if the user click addJob button
  public isSubmited$ = new ReplaySubject<boolean>(1);
  public isNewJobCreated$ = new ReplaySubject<boolean>(1);

  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = 'mission';
    this.base = environment.coreApiUrl;
  }

  public newJob = (query): Observable<ResponseList<Mission>> =>
    this.post(`${this.resourceBaseUrl}`, query);

  //function to check if the new job is conflict with the current job
  public conflictJob(
    newJobName: string,
    currentJob: Mission,
    selectedStartJob: number,
    isBroadcastMessageCheck?: boolean
  ): boolean {
    //re-init the isConflictJob
    //so the next time we check the conflict job, it will be reset
    let isConflictJob = false;

    switch (newJobName) {
      //check the new job for 'go to' and 'patrol areas'
      //then return true if there is a conflict
      case 'go to':
      case 'patrol areas':
        //current job is 'go to' or 'patrol areas'
        if (
          selectedStartJob === 1 &&
          (currentJob.name.toLowerCase().includes('go to') ||
            currentJob.name.toLowerCase().includes('patrol'))
        ) {
          isConflictJob = true;
          return isConflictJob;
        }

        //current job is 'broadcasting'
        if (
          selectedStartJob === 1 &&
          currentJob.name.toLowerCase().includes('broadcast') &&
          isBroadcastMessageCheck
        ) {
          isConflictJob = true;
          return isConflictJob;
        }
        break;

      //check the new job for 'broadcasting'
      //then return true if there is a conflict
      case 'broadcasting':
        //current job is 'go to' or 'patrol areas'
        if (
          selectedStartJob === 1 &&
          (currentJob.name.toLowerCase().includes('go to') ||
            currentJob.name.toLowerCase().includes('patrol'))
        ) {
          //get broadcast data from the current job
          const broadcastData = currentJob.tasks.find((task) =>
            task.skillName.toLowerCase().includes('broadcast')
          );

          if (broadcastData) {
            isConflictJob = true;
            return isConflictJob;
          }
        }

        //current job is 'broadcasting'
        if (
          selectedStartJob === 1 &&
          currentJob.name.toLowerCase().includes('broadcast') &&
          isBroadcastMessageCheck
        ) {
          isConflictJob = true;

          return isConflictJob;
        }
        break;
    }

    return isConflictJob;
  }
}
