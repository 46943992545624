<div class="flex flex-col w-3/4 my-8 ml-[26px] pr-[26px]">
  <!-- Add Remark -->
  <div class="mb-5 text-2xl font-semibold text-neutral-50">
    Remarks by Operator
  </div>
  <ng-container *ngIf="!isCaseClosed">
    <div class="mb-1 font-semibold text-neutral-50">Add a remark</div>
    <textarea
      class="w-full p-3 border rounded-md bg-neutral-700 text-neutral-300"
      rows="3"
      placeholder="Type your message here and click “Add remark” button..."
      [formControl]="remarkForm.controls['comment']"
    ></textarea>

    <div
      *ngIf="
        remarkForm.get('comment').invalid &&
        (remarkForm.get('comment').dirty ||
          remarkForm.get('comment').touched ||
          isInvalid)
      "
      class="text-sm font-light text-error-500"
    >
      <div *ngIf="remarkForm.get('comment').errors?.['required']">
        Remarks is required.
      </div>
    </div>

    <div class="flex flex-row items-center justify-end w-full mt-3">
      <mat-icon
        svgIcon="heroicons_outline:information-circle"
        class="h-4 w-4 text-neutral-200 icon-size-[18px]"
      ></mat-icon>
      <div class="ml-2 mr-6 text-neutral-100">
        Please note that you cannot delete or edit the remark after it is added.
      </div>
      <button
        mat-flat-button
        class="px-3 py-2 text-lg font-medium rounded-md"
        [color]="'primary'"
        (click)="addRemark()"
      >
        Add remark
      </button>
    </div>
  </ng-container>
  <div *ngIf="listRemarks && listRemarks.length > 0" #remarks>
    <!-- divider -->
    <div
      class="border-neutral border-t my-[24px]"
      *ngIf="!isCaseClosed && listRemarks?.length !== 0"
    ></div>

    <!-- List Remark by Malfunction Id -->
    <ng-container *ngIf="moduleName === 'malfunction'">
      <div
        class="flex flex-col w-full p-6 mb-4 rounded-lg bg-neutral-700"
        *ngFor="let remark of listRemarks"
      >
        <ng-container *ngIf="remark.note">
          <div class="mb-2 font-bold text-neutral-50">
            {{ remark.createdAt | formatDateTimePipe }}
            <ng-container *ngIf="remark.note.includes('[Case closed]')">
              • Case closed
            </ng-container>
            <ng-container *ngIf="remark.note.includes('[Case re-opened]')">
              • Case re-opened
            </ng-container>
          </div>
        </ng-container>

        <div class="text-neutral-100">
          <ng-container
            *ngIf="remark.note.includes('[Case closed]'); else reopen"
          >
            {{ remark.note.replace('[Case closed]', '') }}
          </ng-container>
          <ng-template #reopen>
            <ng-container *ngIf="remark.note.includes('[Case re-opened]')">
              {{ remark.note.replace('[Case re-opened]', '') }}
            </ng-container>
            <ng-container *ngIf="!remark.note.includes('[Case re-opened]')">
              {{ remark.note }}
            </ng-container>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="moduleName === 'detection'">
      <!-- List Remark by Detection Id -->
      <div
        class="flex flex-col w-full p-6 mb-4 rounded-lg bg-neutral-700"
        *ngFor="let remark of listRemarks"
      >
        <ng-container *ngIf="remark.content">
          <div class="mb-2 font-bold text-neutral-50">
            {{ remark.createdAt | formatDateTimePipe }}
            <ng-container *ngIf="remark.content.includes('[Case closed]')">
              • Case closed
            </ng-container>
            <ng-container *ngIf="remark.content.includes('[Case re-opened]')">
              • Case re-opened
            </ng-container>
          </div>
        </ng-container>
        <div class="text-neutral-100">
          <ng-container
            *ngIf="remark.content.includes('[Case closed]'); else reopen"
          >
            {{ remark.content.replace('[Case closed]', '') }}
          </ng-container>
          <ng-template #reopen>
            <ng-container *ngIf="remark.content.includes('[Case re-opened]')">
              {{ remark.content.replace('[Case re-opened]', '') }}
            </ng-container>
            <ng-container *ngIf="!remark.content.includes('[Case re-opened]')">
              {{ remark.content }}
            </ng-container>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <div class="w-full flex justify-end bg-neutral-600">
      <div>
        <mat-paginator
          #paginator
          [length]="totalRemarks"
          [pageIndex]="pageNumber - 1"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          (page)="pageChanged($event)"
          aria-label="Select page"
          class="w-full bg-neutral-600"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
