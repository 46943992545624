import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'shared-no-robot-available',
  templateUrl: './no-robot-available.component.html',
  styleUrls: ['./no-robot-available.component.scss'],
})
export class NoRobotAvailableComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
