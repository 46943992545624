<div class="mx-2">
  <div class="flex justify-between mb-4 text-2xl text-neutral-50">
    <h1 mat-dialog-title>
      Add new and replace current {{ data.currentJobName }}?
    </h1>
    <button (click)="cancel()">
      <mat-icon
        svgIcon="heroicons_outline:x"
        class="h-4 w-4 text-neutral-50 icon-size-[18px]"
      ></mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div contentDialog>
      <span class="text-neutral-200"
        >Robot is already {{ data.currentJobName }} currently. In order to start
        a new {{ data.newJobName }} <b class="text-white">together with</b> the
        current job, it has to replace the existing one</span
      >
    </div>
  </div>
  <div
    mat-dialog-actions
    align="end"
    class="flex items-center justify-end mt-4 gap-5"
  >
    <button
      (click)="cancel()"
      mat-button
      class="text-lg hover:bg-neutral-400 rounded-md font-medium"
    >
      Cancel
    </button>
    <button
      (click)="confirm()"
      class="px-3 py-2 rounded-md h-[36px] w-auto text-lg transition font-medium hover:bg-primary-800"
      mat-flat-button
      [color]="'primary'"
    >
      Execute this and pause current job
    </button>
  </div>
</div>
