import { Pipe, PipeTransform } from '@angular/core';
import { trim } from 'lodash';

@Pipe({
  name: 'robotJobStatus',
})
export class RobotJobStatusPipe implements PipeTransform {
  transform(value: string): string {
    const trimmedValue = trim(value).toLowerCase();
    const robotJobStatuses = {
      goto: 'Moving',
      'go to': 'Moving',
      patrol: 'Patrolling',
      broadcast: 'Broadcasting',
      blink: 'On blinking light',
      teleop: 'Teleoperating',
      charging: 'Charging',
      'go charging': 'Go charging',
      recharging: 'Recharging',
      siren: 'Playing siren',
    };

    for (const [key, value] of Object.entries(robotJobStatuses)) {
      if (trimmedValue.includes(key)) {
        return value;
      }
    }

    // uppercase first letter and lowercase the rest
    const newValue = trimmedValue[0].toUpperCase() + trimmedValue.slice(1);
    return newValue;
  }
}

@Pipe({
  name: 'robotDescriptionPipe',
})
export class RobotDescriptionPipe implements PipeTransform {
  transform(value: string): string {
    if (value.includes('|;|')) {
      value = value.split('|;|')[0].trim();
    } else {
      value;
    }
    return value;
  }
}
