import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import {
  delay,
  forkJoin,
  map,
  Subject,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import { Notification as Notifications } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import moment from 'moment';
import { ApiNotification } from 'app/services/notification.service';
import {
  ResponseList,
  Notification,
  ApiLayout,
  ApiRobot,
  MqttSettings,
} from 'rm-api-services/dist/api-services';
import { Router } from '@angular/router';
import { ApiEventDetection, EventWithImage } from 'app/services/event.service';
import { result } from 'lodash';

export interface NotificationVApp extends Notification {
  layoutId?: string;
  layoutName?: string;
  robotId?: string;
  robotName?: string;
}

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'notifications',
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel')
  private _notificationsPanel: TemplateRef<any>;

  notifications: Notifications[];
  notificationEvents: Notifications[];
  notificationTeleconferences: Notifications[];
  unreadCount: number = 0;
  unreadEventCount: number = 0;
  unreadTeleconferenceCount: number = 0;
  notificationEventsSelected: boolean = true;
  notificationTeleconferencesSelected: boolean = false;

  totalEvents: number = 0;
  totalTeleconferences: number = 0;
  pageNo: number = 1;
  pageNoEvent: number = 1;
  pageNoTeleconference: number = 1;
  pageSize: number = 10;

  // get userId from localStorage
  userId = localStorage.getItem('user_id');

  private socketNotificationSub: Subscription;

  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _apiNotification: ApiNotification,
    private _apiLayout: ApiLayout,
    private _apiRobot: ApiRobot,
    private router: Router,
    private apiEvent: ApiEventDetection,
    private _mqttSettings: MqttSettings
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to notification changes
    this._notificationsService.notifications$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notifications[]) => {
        // Fetch the notifications
        this.fetchNotificationData();
      });

    //subscribe to the mqtt of notification topic
    //if there is new notification, fetch the notification again
    this.socketNotificationSub =
      this._mqttSettings.socketNotificationData$.subscribe(
        (data: Notifications) => {
          this.fetchNotificationData();
        }
      );
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  //fetch the notification from DB
  //the notification is filter by (set in json filter-dashboard-data-by-time))
  fetchNotificationData(): void {
    //first get the startDate from json filter-dashboard-data-by-time
    this.apiEvent.getEventFilterTime().then((response) => {
      const startDate = moment()
        .subtract(response.timeValue, response.timeType)
        .format('YYYY-MM-DD HH:mm:ss');

      let payload = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        order: [
          {
            name: 'Created Time',
            column: 'created_at',
            type: 'desc' as const,
          },
        ],
        filter: [
          {
            name: 'userId',
            column: 'notifications.user_id',
            operator: 'eq',
            value: [this.userId],
            extra: '',
            dataType: '',
            virtual: false,
          },
        ],
      };

      // We divide payload to different type to easier joining the payload base on needed

      let payloadFilterDays = [
        {
          column: 'created_at',
          dataType: 'datetime',
          extra: "pattern:'YYYY-MM-DD'",
          name: 'Start Date',
          operator: 'ge',
          value: [startDate],
          virtual: false,
        },
      ];

      let payloadUnread = {
        name: 'Unread',
        column: 'read',
        operator: 'eq',
        value: ['false'],
      };

      let detectionModule = {
        name: 'Event Module',
        column: 'module_name',
        operator: 'eq',
        value: ['event'], //event or teleconference
      };

      let teleconferenceModule = {
        name: 'Event Module',
        column: 'module_name',
        operator: 'eq',
        value: ['teleconference'], //event or teleconference
      };

      //final payload to get the list event notification
      let payloadListNotificationDetectionEvent = {
        // pageNo: this.pageNoEvent,
        // pageSize: 99999,
        pageNo: 1,
        pageSize: 10 * this.pageNoEvent, // we times 10 with this,pageNoEvent to get the latest total before/after lazy scrolling
        filter: [...payload.filter, detectionModule, ...payloadFilterDays],
      };

      //final payload to get the list teleconference notification
      let payloadListNotificationTeleconference = {
        // pageNo: this.pageNoTeleconference,
        // pageSize: 99999,
        pageNo: 1,
        pageSize: 10 * this.pageNoTeleconference, // we times 10 with this,pageNoTeleconference to get the latest total before/after lazy scrolling
        filter: [...payload.filter, teleconferenceModule, ...payloadFilterDays],
      };

      //final payload to get the total notification of event notification
      //we only use pageSize===1 because we just want to get the total record of the return api
      let payloadCountUnreadTeleconference = {
        pageSize: 1,
        filter: [
          ...payload.filter,
          detectionModule,
          payloadUnread,
          ...payloadFilterDays,
        ],
      };

      //final payload to get the total notification of teleconference notification
      //we only use pageSize===1 because we just want to get the total record of the return api
      let payloadCountUnreadDetectionEvent = {
        pageSize: 1,
        filter: [
          ...payload.filter,
          teleconferenceModule,
          payloadUnread,
          ...payloadFilterDays,
        ],
      };

      // this._apiNotification
      //   .list({ ...payload, ...payloadListNotificationDetectionEvent })
      //   .subscribe((response: ResponseList<Notification>) => {
      //     console.log('response notif aa 1', response);
      //     this.notifications = response.result.list;
      //     this.notificationEvents = response.result.list;
      //     console.log('notifications aa 1', this.notifications);
      //     console.log('notifications aa 2', this.notificationEvents);
      //   });

      //We use forkJoin to get the result from the api the same time
      forkJoin([
        this._apiNotification.list({
          ...payload,
          ...payloadListNotificationDetectionEvent,
        }),
        this._apiNotification.list({
          ...payload,
          ...payloadListNotificationTeleconference,
        }),
      ]).subscribe(
        ([
          resultListNotificationDetectionEvent,
          resultListNotificationTeleconference,
        ]) => {
          //Check if the return api of event notifications is Success
          //if true then assign the notificationEvents
          if (resultListNotificationDetectionEvent.message === 'Success') {
            this.notificationEvents =
              resultListNotificationDetectionEvent.result.list;
            this.totalEvents =
              resultListNotificationDetectionEvent.result.totalRecords;
          }

          //Check if the return api of teleconferences notifications is Success
          //if true then assign the notificationTeleconferences
          if (resultListNotificationTeleconference.message === 'Success') {
            this.notificationTeleconferences =
              resultListNotificationTeleconference.result.list;
            this.totalTeleconferences =
              resultListNotificationTeleconference.result.totalRecords;
          }

          // Calculate the unread count
          this._calculateUnreadCount(
            payload,
            payloadCountUnreadTeleconference,
            payloadCountUnreadDetectionEvent
          );

          // Mark for check
          // this._changeDetectorRef.markForCheck();
        }
      );

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  //fetch notification for scrooling in event notification
  fetchNotificationEventData() {
    //first get the startDate from json filter-dashboard-data-by-time
    this.apiEvent.getEventFilterTime().then((response) => {
      const startDate = moment()
        .subtract(response.timeValue, response.timeType)
        .format('YYYY-MM-DD HH:mm:ss');

      let payload = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        order: [
          {
            name: 'Created Time',
            column: 'created_at',
            type: 'desc' as const,
          },
        ],
        filter: [
          {
            name: 'userId',
            column: 'notifications.user_id',
            operator: 'eq',
            value: [this.userId],
            extra: '',
            dataType: '',
            virtual: false,
          },
        ],
      };

      // We divide payload to different type to easier joining the payload base on needed

      let payloadFilterDays = [
        {
          column: 'created_at',
          dataType: 'datetime',
          extra: "pattern:'YYYY-MM-DD'",
          name: 'Start Date',
          operator: 'ge',
          value: [startDate],
          virtual: false,
        },
      ];

      let detectionModule = {
        name: 'Event Module',
        column: 'module_name',
        operator: 'eq',
        value: ['event'], //event or teleconference
      };

      //final payload to get the list event notification
      let payloadListNotificationDetectionEvent = {
        pageNo: this.pageNoEvent,
        // pageSize: 99999,
        filter: [...payload.filter, detectionModule, ...payloadFilterDays],
      };

      this._apiNotification
        .list({
          ...payload,
          ...payloadListNotificationDetectionEvent,
        })
        .subscribe((result) => {
          //Check if the return api of event notifications is Success
          //if true then assign the notificationEvents
          if (result.message === 'Success') {
            this.notificationEvents = [
              ...this.notificationEvents,
              ...result.result.list,
            ];
          }
        });

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  //fetch notification for scrooling in teleconference notification
  fetchNotificationTeleconferenceData() {
    //first get the startDate from json filter-dashboard-data-by-time
    this.apiEvent.getEventFilterTime().then((response) => {
      const startDate = moment()
        .subtract(response.timeValue, response.timeType)
        .format('YYYY-MM-DD HH:mm:ss');

      let payload = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        order: [
          {
            name: 'Created Time',
            column: 'created_at',
            type: 'desc' as const,
          },
        ],
        filter: [
          {
            name: 'userId',
            column: 'notifications.user_id',
            operator: 'eq',
            value: [this.userId],
            extra: '',
            dataType: '',
            virtual: false,
          },
        ],
      };

      // We divide payload to different type to easier joining the payload base on needed

      let payloadFilterDays = [
        {
          column: 'created_at',
          dataType: 'datetime',
          extra: "pattern:'YYYY-MM-DD'",
          name: 'Start Date',
          operator: 'ge',
          value: [startDate],
          virtual: false,
        },
      ];

      let teleconferenceModule = {
        name: 'Event Module',
        column: 'module_name',
        operator: 'eq',
        value: ['teleconference'], //event or teleconference
      };

      //final payload to get the list teleconference notification
      let payloadListNotificationTeleconference = {
        pageNo: this.pageNoTeleconference,
        // pageSize: 99999,
        filter: [...payload.filter, teleconferenceModule, ...payloadFilterDays],
      };

      this._apiNotification
        .list({
          ...payload,
          ...payloadListNotificationTeleconference,
        })
        .subscribe((result) => {
          //Check if the return api of teleconference notifications is Success
          //if true then assign the notificationTeleconferences
          if (result.message === 'Success') {
            this.notificationTeleconferences = [
              ...this.notificationTeleconferences,
              ...result.result.list,
            ];
          }
        });

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  scrolledEventIndexChange(event: number) {
    //check if the event is 0 (not scrool) then dont do anything
    if (event === 0) {
      return;
    }

    const totalPage = Math.ceil(this.totalEvents / 10);

    if (this.notificationEvents.length && this.pageNoEvent < totalPage) {
      this.pageNoEvent++;
      this.fetchNotificationEventData();
    }
  }
  scrolledTeleconferenceIndexChange(event: number) {
    //check if the event is 0 (not scrool) then dont do anything
    if (event === 0) {
      return;
    }

    const totalPage = Math.ceil(this.totalTeleconferences / 10);

    if (
      this.notificationTeleconferences.length &&
      this.pageNoTeleconference < totalPage
    ) {
      this.pageNoTeleconference++;
      this.fetchNotificationTeleconferenceData();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the notifications panel
   */
  openPanel(): void {
    // Return if the notifications panel or its origin is not defined
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(
      new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
    );
  }

  /**
   * Close the notifications panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(): void {
    // Mark all as read
    // this._notificationsService.markAllAsRead().subscribe();

    // Subscribe to notification changes
    this._notificationsService.notifications$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notifications[]) => {
        //fetch mark as read API
        this._apiNotification
          .markAsRead(null, this.userId)
          .subscribe((response) => {
            //Re-feach the notifications
            //so the view is updated
            this.fetchNotificationData();
          });
      });
  }

  /**
   * Toggle read status of the given notification
   */
  //   toggleRead(notification: Notification): void {
  //     // Toggle the read status
  //     notification.read = !notification.read;

  //     // Update the notification
  //     this._notificationsService
  //       .update(notification.id, notification)
  //       .subscribe();
  //   }

  /**
   * Delete the given notification
   */
  //   delete(notification: Notification): void {
  //     // Delete the notification
  //     this._notificationsService.delete(notification.id).subscribe();
  //   }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(
          this._notificationsOrigin._elementRef.nativeElement
        )
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  /**
   * Calculate the unread count
   *
   * @private
   */
  //   private _calculateUnreadCount(): void {
  private _calculateUnreadCount(
    payload,
    payloadCountUnreadTeleconference,
    payloadCountUnreadDetectionEvent
  ): void {
    //Use forkJoin to the the count the same time
    forkJoin([
      this._apiNotification.list({
        ...payload,
        ...payloadCountUnreadTeleconference,
      }),
      this._apiNotification.list({
        ...payload,
        ...payloadCountUnreadDetectionEvent,
      }),
    ]).subscribe(
      ([resultCountUnreadDetectionEvent, resultCountUnreadTeleconference]) => {
        //Count the Events notification
        if (resultCountUnreadDetectionEvent.message === 'Success') {
          this.unreadEventCount =
            resultCountUnreadDetectionEvent.result.totalRecords;
        }

        //Count the Teleconferences notification
        if (resultCountUnreadTeleconference.message === 'Success') {
          this.unreadTeleconferenceCount =
            resultCountUnreadTeleconference.result.totalRecords;
        }

        //Total unread notification from event & teleconference notification
        if (
          resultCountUnreadDetectionEvent.message === 'Success' &&
          resultCountUnreadTeleconference.message === 'Success'
        ) {
          this.unreadCount =
            this.unreadEventCount + this.unreadTeleconferenceCount;

          // Mark for check
          this._changeDetectorRef.markForCheck();
        }
      }
    );
  }

  public onClickNotification(
    module: string,
    notificationId: string,
    notificationUrl?: string
  ) {
    // Subscribe to notification changes
    this._notificationsService.notifications$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: Notifications[]) => {
        this._apiNotification
          .markAsRead(notificationId)
          .subscribe((response) => {
            //Re-feach the notifications
            //so the view is updated
            this.fetchNotificationData();
          });
      });

    //if there are notification url
    //we navigate to the corresponding url (e.g.detection details page)
    if (notificationUrl !== '') {
      this.router.navigate(['/detection/details', notificationUrl]);
    }
  }

  selectedTabChange(event) {
    if (event.index === 0) {
      this.notificationEventsSelected = true;
      this.notificationTeleconferencesSelected = false;
    }
    if (event.index === 1) {
      this.notificationTeleconferencesSelected = true;
      this.notificationEventsSelected = false;
    }
  }
}
