<div class="p-5">
  <h3 class="text-3xl font-bold text-neutral-50">Turn on blinking light</h3>

  <p class="text-neutral-100 mt-3">Command robot to turn on blinking lights.</p>

  <!-- Select Start Job  -->
  <div class="mt-5 max-w-[450px]">
    <rm-select-start-job-type
      [label]="'When does this job start?'"
      (selectionChange)="selectStartJobType($event)"
      (jobSchedule)="jobSchedule($event)"
    ></rm-select-start-job-type>
  </div>

  <!-- form for duration turn on light -->
  <form [formGroup]="form" class="flex flex-col gap-2 mt-0">
    <rm-repeat
      [radioButtonLabel]="'On repeat'"
      [inputFieldLabel]="'No. of repeat'"
      [placeholder]="'Enter number of repeat'"
      [inputType]="'number'"
      [min]="1"
      [value]="form.get('repeat')"
      (onRepeatChange)="onRepeatChange($event)"
    ></rm-repeat>
  </form>
</div>
