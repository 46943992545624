<div class="flex justify-between items-center gap-5">
  <ng-container *ngIf="status">
    <mat-icon
      class="scale-90"
      [ngClass]="status === 'failed' ? 'text-white' : 'text-neutral-700'"
      [svgIcon]="
        status === 'success'
          ? 'mat_solid:check_circle'
          : status === 'warning'
          ? 'mat_solid:warning'
          : status === 'failed'
          ? 'mat_solid:error'
          : 'mat_solid:info'
      "
    >
    </mat-icon>
    <div
      class="font-bold text-md"
      [ngClass]="status === 'failed' ? 'text-white' : 'text-neutral-700'"
    >
      {{ message }}
    </div>
  </ng-container>
  <mat-icon
    class="cursor-pointer"
    [ngClass]="status === 'failed' ? 'text-white' : 'text-neutral-700'"
    (click)="dismiss()"
    svgIcon="mat_solid:close"
  ></mat-icon>
</div>
