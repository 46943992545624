import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { Robot } from 'rm-api-services/dist/api-services';
import _ from 'lodash';
import { MapLibreService } from 'app/modules/dashboard/gis-map/services/map-libre.service';
import { LayoutMapService } from 'app/modules/dashboard/layout-map/layout-map.service';
import { NIL as NIL_UUID } from 'uuid';

interface LayoutWithRobot {
  layoutId: string;
  layoutName: string;
  robots: MultileRobotJobCreation[];
}

interface MultileRobotJobCreation extends Robot {
  currentMissionName?: string;
}

@Component({
  selector: 'card-robot-selection',
  templateUrl: './card-robot-selection.component.html',
  styleUrls: ['./card-robot-selection.component.scss'],
})
export class CardRobotSelectionComponent implements OnInit {
  @Input() layoutWithRobots: LayoutWithRobot[] = [];
  @Input() cardSize: 'big' | 'small' = 'big';
  @Input() layoutId: string;
  @Input() siteId: string;
  @Input() selectionType: 'checkbox' | 'radio' = 'checkbox';
  @Input() isShowRobotListNote: boolean = false;
  @Output() selectionChange = new EventEmitter<string>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private leafletService: LayoutMapService,
    private dashboardService: DashboardService,
    private maplibreService: MapLibreService
  ) {}

  ngOnInit(): void {}

  public handleRobotSelection(robotId: string): void {
    this.selectionChange.emit(robotId);
  }

  /**
   * Helper function to show robot location on the map, when user click the location
   * button in the drawer. If the selected robot is on different layout with current
   * layout map in same building, it will render the layout map first then move
   * the map center to selected robot location
   *
   * @param robot
   */
  public showRobotOnMap(robot: Robot): void {
    if (robot.point && this.leafletService.isMapInitiate()) {
      if (
        robot.layoutId !== NIL_UUID &&
        robot.layoutId !== this.layoutId &&
        this.siteId
      ) {
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            layoutId: robot.layoutId,
            siteId: this.siteId,
          },
        });
      }
      let robotPointX = robot.point.x;
      if (this.cardSize === 'small') {
        robotPointX = robot.point.x + 500;
      }
      const coordinates: L.PointExpression = [robotPointX, robot.point.y];
      this.dashboardService.selectedRobotCoordinate$.next(coordinates);
    }

    if (robot.location && this.maplibreService.isMapInitiate()) {
      let robotLng = robot.location.lng;
      if (this.cardSize === 'small') {
        robotLng = robot.location.lng + 0.05;
      }
      const coor: [number, number] = [robotLng, robot.location.lat];
      this.maplibreService.flyTo({
        center: coor,
      });
    }
  }
}
