import { Injectable, OnDestroy } from '@angular/core';
import { get } from 'lodash';
import {
  ApiMission,
  Mission,
  MqttSettings,
  Robot,
} from 'rm-api-services/dist/api-services';
import {
  BehaviorSubject,
  filter,
  map,
  merge,
  Observable,
  of,
  Subscription,
} from 'rxjs';
import { PlaylistService } from './playlist.service';
import { NIL as NIL_UUID } from 'uuid';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';

// a service to get the current job a robot is executing
@Injectable({
  providedIn: 'root',
})
export class CurrentMissionService implements OnDestroy {
  private subjects = new Map<string, BehaviorSubject<Mission[]>>();
  private missionStatusSub: Subscription;
  private missionDetailSub: Subscription;
  private activeJobStatus: number[] = [3, 4, 5];
  constructor(
    private _apiMision: ApiMission,
    private mqttSettings: MqttSettings,
    private playlistService: PlaylistService,
    private dashboardService: DashboardService
  ) {
    this.startWorker();
  }

  public getSubject(robot: Robot): BehaviorSubject<Mission[]> {
    if (!this.subjects.has(robot.id)) {
      this.subjects.set(robot.id, new BehaviorSubject<Mission[]>([]));
    }

    // check if the robot is working (state === 3).
    // Then get the current active mission of the robot
    if (robot.state === 3 && this.subjects.get(robot.id).value.length === 0) {
      this.dashboardService.getAllActiveJobs(robot.id).subscribe((missions) => {
        this.subjects.get(robot.id).next(missions);
      });
    }

    return this.subjects.get(robot.id);
  }

  ngOnDestroy(): void {
    this.stopWorker();
  }

  private startWorker() {
    // subscribe to MQTT instead of using API polling
    this.missionStatusSub = this.mqttSettings.socketMissionStatus$
      .pipe(
        // when we receive mission update from Robot MQTT,
        // We check for the robot ID, only update jobs if robot ID includes in the list.
        filter((mission) => {
          const eventRobotId = get(mission, 'robotId');
          const robotId: string[] = [];
          this.subjects.forEach((missions, idRobot) => {
            robotId.push(idRobot);
          });
          return robotId.includes(eventRobotId);
        })
      )
      .subscribe((resp) => {
        this.subjects.forEach((subject, idRobot) => {
          // only run when subject has been observed and robot is in working state (3)
          if (subject.observed) {
            if (idRobot === resp.robotId) {
              const missionIds = subject.value.map((mission) => mission.id);
              if (missionIds.includes(resp.missionId) && resp.status) {
                const currentMissions = this.updateMissionStatus(
                  resp.missionId,
                  resp.status,
                  subject.value
                );
                subject.next(currentMissions);
              }
            }
          } else {
            subject.next([]);
          }
        });
      });

    // Subscribe to rm/mission topic to get the mission detail, if there is new mission added
    this.missionDetailSub = this.mqttSettings.socketUserMission$
      .pipe(
        // when we receive mission update from the commands that the FE submitted to the MQTT,
        // We check for the robot ID, only update jobs if robot ID includes in the list.
        filter((mission) => {
          const eventRobotId = get(mission, 'robotId');
          const robotId: string[] = [];
          this.subjects.forEach((missions, idRobot) => {
            robotId.push(idRobot);
          });
          return robotId.includes(eventRobotId);
        })
      )
      .subscribe((resp) => {
        this.subjects.forEach((subject, idRobot) => {
          // only run when subject has been observed and robot is in working state (3)
          if (subject.observed) {
            if (idRobot === resp.robotId) {
              const missionIds = subject.value.map((mission) => mission.id);
              if (missionIds.includes(resp.missionId)) {
                const currentMissions = this.updateMissionStatus(
                  resp.missionId,
                  resp.status,
                  subject.value
                );
                subject.next(currentMissions);
              } else {
                this.getMissionDetail(resp.missionId).subscribe((response) => {
                  if (
                    response &&
                    this.activeJobStatus.includes(response.status)
                  ) {
                    const mission = response;
                    const updatedMissions = [mission, ...subject.value];

                    // It uses a Map to store unique objects based on the specified property (missionId).
                    // Finally, it converts the map values back to an array,
                    // resulting in an array without duplicate objects.
                    const uniqueMap = new Map();
                    updatedMissions.forEach((item) => {
                      const key = item['id'];
                      uniqueMap.set(key, item);
                    });
                    const uniqueMissions = Array.from(uniqueMap.values());

                    const sortedMission =
                      this.playlistService.sortMissionList(uniqueMissions);

                    subject.next(sortedMission);
                  }
                });
              }
            }
          } else {
            subject.next([]);
          }
        });
      });
  }

  private stopWorker(): void {
    if (this.missionStatusSub) {
      this.missionStatusSub.unsubscribe();
    }
    if (this.missionDetailSub) {
      this.missionDetailSub.unsubscribe();
    }
  }

  // when we receive mission update,
  // update the active jobs
  private updateMissionStatus(
    id: string,
    status: number,
    currentMissions: Mission[]
  ): Mission[] {
    const missions = currentMissions.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          status: status ? this.mappingMissionStatus(status) : 3,
        };
      }
      return item;
    });

    const activeMissions = missions.filter((mission) =>
      this.activeJobStatus.includes(mission.status)
    );

    const sortedMission = this.playlistService.sortMissionList(activeMissions);
    return sortedMission;
  }

  /**
   * Mapping the mission status from MQTT data. Because mission status is difference
   * that get from the API and MQTT.
   * https://docs.robotmanager.com/docs/server-clientrobot-communication#send-mission-status-to-robotmanager-robotmissionstatuscompanyid
   *
   * @param missionStatus Mission status from MQTT
   * @returns Mission status with API formatted
   */
  private mappingMissionStatus(missionStatus: number): number {
    let status = missionStatus;

    switch (missionStatus) {
      case 1: // Mission active
        status = 5;
        break;
      case 2: // Mission complete
        status = 1;
        break;
      case 4: // Mission cancelled
        status = 2;
        break;
    }

    return status;
  }

  private getMissionDetail(missionId: string): Observable<Mission> {
    if (!missionId && missionId === NIL_UUID) {
      return of(null);
    }

    return this._apiMision.getById(missionId).pipe(map((res) => res.result));
  }
}
