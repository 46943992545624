import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-fullscreen-tool',
  templateUrl: './fullscreen-tool.component.html',
  styleUrls: ['./fullscreen-tool.component.scss'],
})
export class FullscreenToolComponent implements OnInit {
  fullscreen: boolean = false;
  @Input() position: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' =
    'bottomRight';
  @Input() size: 'big' | 'medium' | 'small' = 'small';
  @Output() isFullscreen = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  toggleFullscreen() {
    this.fullscreen = !this.fullscreen;
    this.isFullscreen.emit(this.fullscreen);
  }
}
