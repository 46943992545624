import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { SelectOption } from '../form-select/form-select.component';

@Component({
  selector: 'rm-auto-complete',
  templateUrl: './rm-auto-complete.component.html',
  styleUrls: ['./rm-auto-complete.component.scss'],
})
export class RmAutoCompleteComponent implements OnInit, OnChanges {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() selectOptions: SelectOption[] = [];
  @Input() selectedOption: string = '';
  @Input() validation: string = '';
  @Output() selectionChange = new EventEmitter<SelectOption>();
  @Output() typedHandler = new EventEmitter<string>();
  isPanelOpened: boolean = false;

  public formControl: FormControl = new FormControl();
  public filteredOptions: Observable<SelectOption[]>;

  constructor() {}

  ngOnInit(): void {
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || ''))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedOption) {
      if (this.formControl) {
        this.formControl.setValue(
          this.getAutoCompleteDisplay(this.selectedOption)
        );
      }
    }
  }

  private filter(val: string): SelectOption[] {
    if (val) {
      return this.selectOptions.filter((option) =>
        option.display.toLowerCase().includes(val.toLowerCase())
      );
    }

    return this.selectOptions;
  }

  public handleChangeOption(event: SelectOption): void {
    const value = event.value;
    this.onHandleTyped(value);
    this.formControl.setValue(this.getAutoCompleteDisplay(value));
    this.selectionChange.emit(event);
  }

  /**
   * Helper function to convert the value shown in auto complete field.
   * If the value to convert is JSON, it will get the name attribute in the JSON.
   * If the value to convert is not JSON, it will check the display attribute
   * in the options list, because the value suppose to be the ID.
   * Otherwise it will not convert the value
   *
   * @param item
   * @returns
   */
  public getAutoCompleteDisplay(item: any): string {
    if (item) {
      try {
        const jsonObj = JSON.parse(item);

        return jsonObj.name;
      } catch (error) {
        const selectedOption = this.selectOptions.find(
          (option) => option.value === item
        );
        if (selectedOption) {
          return selectedOption.display;
        }

        return item;
      }
    }

    return '';
  }

  public panelOpened() {
    this.isPanelOpened = true;
  }

  public panelClosed() {
    this.isPanelOpened = false;
  }

  public onHandleTyped(value: string): void {
    this.typedHandler.emit(value);
  }
}
