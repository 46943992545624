<div>
  <!-- isConcurrentSkill is used to check if the broadcast job is main job or concurrent job -->
  <ng-container *ngIf="!isConcurrentSkill">
    <h3 class="text-3xl font-bold text-neutral-50">Broadcast</h3>

    <p class="text-neutral-100 mt-3">
      Command robot to speak via text-to-speech function or upload an audio.
    </p>
  </ng-container>
  <ng-container *ngIf="isConcurrentSkill">
    <h3 class="text-lg text-neutral-50 font-semibold">Broadcast message</h3>

    <p class="text-neutral-100 text-sm mt-1">
      Command robot to broadcast a message while moving.
    </p>
  </ng-container>

  <!-- Select Start Job  -->
  <div class="mt-5 max-w-[450px]" *ngIf="!isConcurrentSkill">
    <rm-select-start-job-type
      [label]="'When does this job start?'"
      (selectionChange)="selectStartJobType($event)"
      (jobSchedule)="jobSchedule($event)"
    ></rm-select-start-job-type>
  </div>

  <!-- isChecked is used to check if the concurent job need broadcast -->
  <!-- isChecked default value is TRUE -->
  <div class="mt-0 w-1/2" *ngIf="isChecked">
    <shared-form-select
      *ngIf="typeOfMesages.length > 0"
      [label]="'Type of message'"
      [data]="typeOfMesages"
      (selectionChange)="selectTypeOfMessage($event)"
    ></shared-form-select>
  </div>

  <div class="mt-2 w-full" *ngIf="selectedTypeOfMessage && isChecked">
    <div class="flex-auto w-full">
      <ng-container *ngIf="selectedTypeOfMessage !== 'upload'">
        <mat-label class="block mb-3">Message</mat-label>
        <div
          class="w-full scrollable"
          itemSize="40"
          maxBufferPx="200"
          minBufferPx="200"
        >
          <shared-form-boxed-radio-button
            *ngIf="selectedTypeOfMessage !== 'custom'"
            [options]="listOfMessages"
            [control]="form.get('broadcastData')"
            (onSelect)="selectMessage($event)"
          ></shared-form-boxed-radio-button>

          <shared-form-textarea
            *ngIf="selectedTypeOfMessage === 'custom'"
            [placeholder]="'Type a message here'"
            [control]="form.get('message')"
            (valueChange)="onTextMessage($event)"
          ></shared-form-textarea>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedTypeOfMessage === 'upload'">
        <!-- Dropzone for audio -->
        <shared-input-file-dropzone
          [control]="form.get('audio')"
          [accept]="'.MP3, .WAV'"
          [maxFileSize]="10485760"
          [multiple]="false"
          (uploadChange)="onUploadChange($event, 'audio')"
          (removeFile)="onRemoveFile()"
        ></shared-input-file-dropzone>
      </ng-container>

      <!-- Repeat Form -->
      <div class="mt-5" *ngIf="selectedTypeOfMessage !== 'custom'">
        <rm-repeat
          [radioButtonLabel]="'On repeat'"
          [inputFieldLabel]="'No. of repeat'"
          [placeholder]="'Enter number of repeat'"
          [inputType]="'number'"
          [min]="1"
          [value]="form.get('repeat')"
          (onRepeatChange)="onRepeatChange($event)"
        ></rm-repeat>
      </div>

      <!-- Divider -->
      <div class="mt-5">
        <mat-divider></mat-divider>
      </div>
      <!-- Blink -->
      <div class="mt-5">
        <slide-toggle
          *ngIf="isBlinker"
          class="mt-2"
          [label]="'Blinking lights'"
          [description]="'Flash blinking lights until this job is completed.'"
          (onChange)="handleBlinkingLightsCheck($event)"
        ></slide-toggle>
      </div>
    </div>
  </div>
</div>
