import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeDateTime',
})
export class RemoveDateTimePipe implements PipeTransform {
  transform(value: string): string {
    // Define two regular expression patterns to match both datetime formats
    const pattern1 = /\d{1,2}\/\d{1,2}\/\d{4}\s+\d{1,2}:\d{2}:\d{2}(\s+[AP]M)?/;
    const pattern2 =
      /\d{1,2}\/\d{1,2}\/\d{4}\s*,?\s*\d{1,2}:\d{2}:\d{2}\s*[AP]M?/;
    const pattern3 = /\d{4}\/\d{1,2}\/\d{1,2}\s+\d{1,2}:\d{2}:\d{2}/;

    // Try to match the input value with either pattern and remove the datetime portion
    let result = value.replace(pattern1, '');
    result = result.replace(pattern2, '');
    result = result.replace(pattern3, '');

    return result;
  }
}

@Pipe({
  name: 'removeBracket',
})
export class RemoveBracketPipe implements PipeTransform {
  transform(value: string): string {
    const result = value.replace(/\s*\[.*?\]\s*/g, '');
    return result;
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [RemoveDateTimePipe, RemoveBracketPipe],
  exports: [RemoveDateTimePipe, RemoveBracketPipe],
  providers: [RemoveDateTimePipe, RemoveBracketPipe],
})
export class RemoveDateTimePipeModule {}
