import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiBase,
  DispatchRobot,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DispatchRobotService extends ApiBase<DispatchRobot> {
  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = 'dispatch';
    this.base = environment.apiUrl;
  }

  /**
   * Updates the dispatch robot by sending a PUT request to the specified URL with the provided payload.
   *
   * @param {string} url - The URL where the dispatch robot should be updated.
   * @param {string} id - The ID of the dispatch robot.
   * @param {Partial<DispatchRobot>} payload - The partial dispatch robot object containing the updated values.
   * @return {Observable<ResponseOne<DispatchRobot>>} - An observable that emits a response containing the updated dispatch robot.
   */
  updateDispatch(
    url: string,
    id: string,
    payload: Partial<DispatchRobot>
  ): Observable<ResponseOne<DispatchRobot>> {
    return this.http.put<ResponseOne<DispatchRobot>>(
      `${this.base}/${url}/${id}`,
      payload
    );
  }

  public createDispatch = (
    query: Partial<DispatchRobot>
  ): Observable<ResponseOne<DispatchRobot>> =>
    this.http.post<ResponseOne<DispatchRobot>>(
      `${this.base}/${this.resourceBaseUrl}/create`,
      query
    );
}
