<div class="p-5">
  <h3 class="text-3xl font-bold text-neutral-50">Return to charge</h3>

  <!-- select the option take action -->
  <div class="mt-5 max-w-[450px]">
    <ng-container *ngIf="robot.state !== 3">
      <p class="text-neutral-100 mt-3">
        Send the robot to the charging station.
      </p>
    </ng-container>

    <ng-container *ngIf="robot.state === 3">
      <shared-form-select
        [data]="takeActionList"
        [label]="'the action you want to take'"
        [controlName]="selectedTakeAction"
      ></shared-form-select>

      <ng-switch [ngSwitch]="selectedTakeAction.value">
        <ng-container *ngSwitchCase="'reallocate'">
          <!-- Re-allocate the current job to others available robots and send the robot to the charging station. -->
          <ng-container *ngIf="robot.state === 3">
            <p class="text-neutral-100 mt-3">
              Re-allocate the current job to others available robots and send
              the robot to the charging station.
            </p>

            <div class="mt-8">
              <div class="w-full my-5">
                <span class="text-xl font-semibold text-neutral-50 mb-8">
                  Select Robot to re-allocate the job
                </span>
              </div>
              <!-- Robot List -->
              <ng-container *ngIf="robotOnlineList.length > 0">
                <mat-radio-group class="boxed-radio-button-selection">
                  <mat-radio-button
                    *ngFor="let robot of robotOnlineList"
                    class="flex justify-between items-center mb-4 robot-list-radio-button"
                    [value]="robot.id"
                    (change)="selectRobot($event.value)"
                  >
                    <app-robot-list-item [robot]="robot"></app-robot-list-item>
                  </mat-radio-button>
                </mat-radio-group>
              </ng-container>
              <!-- All robots offline state -->
              <shared-no-robot-available
                *ngIf="robotOnlineList.length === 0"
              ></shared-no-robot-available>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'abort'">
          <p class="text-neutral-100 mt-3">
            Abort the current job and send the robot to the charging station.
          </p>
        </ng-container>
      </ng-switch>
    </ng-container>
  </div>
</div>
