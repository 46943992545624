<div mat-dialog-title class="text-center text-lg">
  <mat-icon svgIcon="mat_solid:warning"></mat-icon>
  <h3>You are in a call!</h3>
</div>

<mat-dialog-content class="m-0 p-0">
  <div class="flex flex-col justify-center">
    <div class="text-center text-sm text-neutral-100">
      Please end your call before navigate to another page.
    </div>
    <!-- Dialog Action -->
    <div class="flex justify-center mt-4 mb-2 gap-5">
      <!-- <button mat-button (click)="onYesResponse()" class="save-button">
          End call
        </button> -->
      <button
        class="bg-primary text-white rounded-md"
        mat-button
        (click)="onNoResponse()"
      >
        Back
      </button>
    </div>
  </div>
</mat-dialog-content>
