import { AVAILABLE_JOBS, AvailableJob } from './robot-jobs-constants';
import {
  Subscription,
  Subject,
  takeUntil,
  filter,
  map,
  switchMap,
  catchError,
  EMPTY,
} from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ApiRobot,
  Robot,
  Skill,
  MqttSettings,
  ResponseOne,
  Mission,
  ApiMission,
  ResponseList,
} from 'rm-api-services/dist/api-services';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { RobotSocketData, RobotSocketState } from 'app/services/api.types';
import { DashboardService } from '../dashboard.service';
import { ApiLayoutMap } from 'app/services/layout.service';
import { TeleOperationService } from 'app/modules/teleoperations/teleoperations.service';
import { UtilityService } from 'app/services/utility.service';
import { SKILL_NAME_TELEOPS } from 'app/modules/teleoperations/teleoperations.config';

export interface RobotDetail extends Partial<Robot> {
  availableJobs?: AvailableJob[];
  skillArr?: Skill[];
  eventCount?: number;
}

export const statusMapping = {
  1: 'Completed',
  2: 'Aborted',
  3: 'Paused',
  4: 'Scheduled',
  5: 'Active',
  6: 'Failed',
};
@Component({
  selector: 'robot-detail',
  templateUrl: './robot-detail.component.html',
  styleUrls: ['./robot-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RobotDetailComponent implements OnChanges, OnInit, OnDestroy {
  @Input() robotId: string;
  isLoading: boolean = true;
  robot: RobotDetail;
  currentMission: Mission;
  socketRobotSub: Subscription;
  socketRobotStateSub: Subscription;
  socketRobotMissionSub: Subscription;
  socketMissionStatusSub: Subscription;
  socketEvent: Subscription;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  checkOfflineTimer;

  teleopSKill = SKILL_NAME_TELEOPS;

  constructor(
    private mqttSettings: MqttSettings,
    private apiRobot: ApiRobot,
    private apiMission: ApiMission,
    private _datePipe: DatePipe,
    private _dashboardService: DashboardService,
    private apiLayoutMap: ApiLayoutMap,
    private _teleopsSvc: TeleOperationService,
    private _utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    // subscribe to the mqtt topic
    this.socketRobotSub = this.mqttSettings.socketRobotData$.subscribe(
      (data: RobotSocketData) => {
        if (data) {
          if (data.robotId === this.robotId) {
            this.updateRobotDetails(data);
          }
        }
      }
    );

    this.socketRobotStateSub = this.mqttSettings.socketRbtStateData$.subscribe(
      (data) => {
        if (data) {
          if (data.robotId === this.robotId) {
            this.updatRobotState(data);
          }
        }
      }
    );

    // listener for event detection update from mqtt
    this.socketEvent = this.mqttSettings.socketEventData$.subscribe((data) => {
      if (data.id) {
        if (data.robotId === this.robotId) {
          //add event count with +1 to the robot if the robot id is the same as mqtt event robot it
          this.updatRobotEventCount();
        }
      }
    });

    this.checkOfflineTimer && clearInterval(this.checkOfflineTimer);
    this.checkOfflineTimer = setInterval(() => {
      if (this.robot) {
        const currenDate = new Date().getTime();
        const updatedAt = new Date(this.robot.updatedAt).getTime();
        const currentTime = moment(currenDate);
        const updatedTime = moment(updatedAt);
        const differenceInSeconds = currentTime.diff(updatedTime, 'seconds');
        if (updatedAt && differenceInSeconds > 10) {
          this.robot.status = 2;
          this.robot.updatedAt = moment(updatedAt).fromNow();
        }
      }
    }, 3 * 1000); //if robot is offline for 1 minute then show offline status
  }

  ngOnChanges(change: SimpleChanges) {
    if (change.robotId) {
      this.getRobotDetails(change.robotId.currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.socketEvent) {
      this.socketEvent.unsubscribe();
    }
    this.socketRobotSub.unsubscribe();
    this.socketRobotStateSub.unsubscribe();
    this.checkOfflineTimer && clearInterval(this.checkOfflineTimer);
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // update robot status by socket
  private updateRobotDetails(socketData: RobotSocketData) {
    if (socketData && this.robot) {
      // check the length of the timestamp, because the moment library
      // has two functions to convert timestamp into date format
      // and some of the robot, send status using this two formats.
      // the length can be 10 or 13
      if (socketData.timestamp.toString().length > 10) {
        this.robot.lastOnlineTime = moment(socketData.timestamp).fromNow();
      } else {
        this.robot.lastOnlineTime = moment.unix(socketData.timestamp).fromNow();
      }
      this.robot.status = 1;
      this.robot.battery = Number(socketData.battery);
      this.robot.connectivity = socketData.connectivity;
      this.robot.updatedAt = this._utilityService.formatDate(
        new Date().toLocaleString()
      );
    }
  }

  private updatRobotState(socketData: RobotSocketState): void {
    if (this.robot) {
      this.robot.state = socketData.state;
    }
  }

  //add event count with +1 to the robot if the robot id is the same as mqtt event robot it
  private updatRobotEventCount(): void {
    this.robot.eventCount++;
  }

  public getRobotDetails(robotId: string): void {
    if (!robotId) {
      this.robot = undefined;
      return;
    }

    this.isLoading = true;
    this.apiRobot
      .get(`robot/${robotId}`)
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap((res) => {
          if (res.code !== 200 || !res.result) {
            this.robot = undefined;
            console.log('Error', res);
            return EMPTY;
          }

          this.robot = res.result;
          this.robot.battery = Number(this.robot.battery);

          const lastOnlineTime = res.result.lastOnlineTime;
          if (!lastOnlineTime) {
            this.robot.lastOnlineTime = '-';
          } else {
            if (lastOnlineTime.toString().length > 10) {
              this.robot.lastOnlineTime = moment(lastOnlineTime).fromNow();
            } else {
              this.robot.lastOnlineTime = moment
                .unix(lastOnlineTime as any)
                .fromNow();
            }
          }

          return this.apiRobot.getRobotSkills(robotId);
        }),
        catchError((err) => {
          console.log('Error', err);
          return EMPTY;
        })
      )
      .subscribe((res) => {
        if (res.code === 200 && res.result) {
          // get robot skills and update the robot skills array
          this.robot.skillArr = res.result;
          this._dashboardService.listSkill$.next(this.robot.skillArr);
          // get jobs to show based on robot skills
          this.getJobsToShow(this.robot.skillArr);
        } else {
          this.robot = undefined;
          console.log('Error', res);
        }
      })
      .add(() => {
        this.isLoading = false;
      });
  }

  private getJobsToShow(skills: Skill[]): void {
    // Clear any previous available jobs
    this.robot.availableJobs = [];

    // Find matching skills and add jobs to availableJobs
    this.robot.availableJobs = AVAILABLE_JOBS.filter((job) => {
      const matchingSkill = skills.find(
        (skill) => skill.name === job.skillName
      );
      if (matchingSkill) {
        job.skill = matchingSkill;
        return true;
      }
      return false;
    });
  }

  onCreateNewJob(skill: Skill, robot: Robot, jobType: string): void {
    this._dashboardService.selectedTypeCreateJob$.next('specific-robot');
    this._dashboardService.selectedSkill$.next(skill);
    this._dashboardService.selectedJobType$.next(jobType);
    this._dashboardService.selectedRobotId$.next(robot.id);
    this.apiLayoutMap.selectedLayoutId$.next(robot.layoutId);
  }

  gotoTeleops() {
    window.open(`/teleoperation/${this.robotId}`, this.robotId);
  }
}
