import { Observable, catchError, of, switchMap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { enableProdMode, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {
  ResponseOne,
  ResponseWithoutList,
  TrafficGraph,
} from 'rm-api-services/dist/api-services';
import { UtilityService } from './utility.service';
import {
  FullPathPlanner,
  FullPathPlannerPayload,
  PlannedPathPayload,
  PlannedPathResponse,
} from './api.types';

@Injectable({
  providedIn: 'root',
})
export class ApiPlannedPath {
  protected coreApirUrl: string;
  protected resourceBaseUrl = '';
  constructor(public http: HttpClient) {
    this.coreApirUrl = environment.coreApiUrl;
    this.resourceBaseUrl = 'path/planning';
  }

  getPlannedPath(payload: PlannedPathPayload): Observable<PlannedPathResponse> {
    return this.http.post<PlannedPathResponse>(
      `${this.coreApirUrl}/${this.resourceBaseUrl}`,
      payload
    );
  }

  public getFullPlannedPath(
    payload: FullPathPlannerPayload
  ): Observable<ResponseWithoutList<FullPathPlanner>> {
    return this.http.post<ResponseWithoutList<FullPathPlanner>>(
      '/api/path/full/plan',
      payload
    );
  }
}
