import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'robotLastOnline',
})
export class RobotLastOnline implements PipeTransform {
  transform(time: number): string {
    const lastOnlineTime = moment(time).fromNow();
    if (lastOnlineTime !== 'Invalid date') {
      return lastOnlineTime;
    } else {
      return '-';
    }
  }
}
