<form [formGroup]="stateForm" class="w-full mt-1">
  <div class="flex w-full">
    <mat-form-field class="dashboard-search flex-auto mt-3">
      <input
        matInput
        formControlName="searchGroup"
        [placeholder]="'Search robot or building...'"
        [matAutocomplete]="search"
      />
      <mat-autocomplete
        #search="matAutocomplete"
        (optionSelected)="onClickSearch($event.option)"
        [displayWith]="displayFn"
      >
        <mat-optgroup
          *ngFor="let group of stateGroupOptions | async"
          [label]="group.category"
        >
          <mat-option
            *ngFor="let data of group.list"
            [value]="data"
            class="text-base"
          >
            {{ data.name }}
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      <mat-icon
        class="icon-size-5 text-gray-300"
        matPrefix
        [svgIcon]="'heroicons_outline:search'"
      ></mat-icon>
    </mat-form-field>
  </div>
</form>
