import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiBase,
  Camera,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { Observable, switchMap, map, ReplaySubject } from 'rxjs';

interface Asset {
  companyId: string;
  contentLength: number;
  createdBy?: string;
  deletedAt?: string;
  description: string;
  fileName: string;
  filePath: string;
  id: string;
  name: string;
  status: number;
  type: string;
  updatedAt?: string;
  updatedBy?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileInputService extends ApiBase<string> {
  watchIdValue$ = new ReplaySubject<string>();
  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = 'asset';
    this.base = environment.coreApiUrl;
  }

  uploadFile(file: File): Observable<any> {
    return this.http
      .post(this.base + '/asset/create', {
        name: file.name,
        description: file.type,
      })
      .pipe(
        switchMap((response: ResponseOne<string>) => {
          const formData = new FormData();

          formData.append('id', response.result);
          formData.append('file', file);

          return this.http
            .post(this.base + '/asset/upload', formData, {
              reportProgress: true,
              observe: 'events',
            })
            .pipe(
              map((res: any) => ({
                ...res,
                body: { ...res.body, result: response.result },
              }))
            );
        })
      );
  }

  getAsset(id: string): Observable<ResponseOne<Asset>> {
    return this.http.get<ResponseOne<Asset>>(
      this.base + `/asset/get-info/${id}`
    );
  }

  removeAsset(id: string): Observable<ResponseOne<Asset>> {
    return this.http.delete<ResponseOne<Asset>>(this.base + `/asset/${id}`);
  }
}
