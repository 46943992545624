import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
export interface RadioButton {
  label: string;
  value: any;
  checked: boolean;
}

@Component({
  selector: 'radio-button',
  styles: [
    `
      .radio-button {
        ::ng-deep
          .mat-radio-button.mat-accent.mat-radio-checked
          .mat-radio-outer-circle {
          border-color: #7168e4;
          background: #7168e4;
        }
        ::ng-deep .mat-radio-inner-circle {
          background: white !important;
        }
        mat-radio-group.mat-radio-group {
          display: flex;
          gap: 72px;
        }
      }
    `,
  ],
  template: `<section class="radio-button">
    <span class="leading-9">{{ title }}</span>
    <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedOption">
      <mat-radio-button
        *ngFor="let radio of radioButtons"
        [value]="radio.value"
        [checked]="radio.checked"
        (change)="handleChange($event)"
        >{{ radio.label }}</mat-radio-button
      >
    </mat-radio-group>
  </section>`,
})

/**
 * TODO: broadcastState and blinkState currently is not in our system, will be updated soon
 */
export class RadioButtonComponent implements OnInit {
  @Input() radioButtons: RadioButton[] = [];
  @Input() title: string;
  @Input() selectedOption: any;
  @Output() onChange = new EventEmitter();
  ngOnInit(): void {}
  handleChange(event): void {
    this.selectedOption = event.value;
    this.onChange.emit(event);
  }
}
