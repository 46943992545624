<div class="toast-item flex flex-col justify-item-center p-5 drop-shadow-md">
  <!-- header -->
  <div class="flex flex-row justify-center items-center">
    <mat-icon class="text-black" svgIcon="feather:battery"></mat-icon>
    <strong class="mr-auto ml-4">{{ data.data.name }} is low on battery</strong>
  </div>
  <span class="text-sm mt-2">Send this robot to charge asap!</span>
  <!-- Footer -->
  <div class="toast-footer mt-2">
    <button
      mat-button
      class="bg-neutral-400 py-2 px-3 rounded-md text-white"
      (click)="sendToChargingStation(data.data)"
    >
      {{ isInGroupPatrol ? 'Reallocate' : 'Send to charge' }}
    </button>
  </div>
</div>
