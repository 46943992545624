<div class="flex flex-col">
  <span class="mb-4 font-sans text-2xl font-semibold leading-6 text-neutral-50"
    >Malfunctions</span
  >
  <div
    class="flex flex-row w-full px-4 py-2 mb-6 rounded bg-info-300 text-neutral"
  >
    <mat-icon class="mr-2 text-neutral" svgIcon="mat_solid:info"> </mat-icon>
    <span class="text-base font-bold leading-5 text-neutral"
      >To resolve the following issues, call the building manager or officer
      around the area to assist.</span
    >
  </div>

  <div class="flex justify-center" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>

  <div
    class="rounded-lg border-solid border-neutral-400 border-[1px] w-full"
    *ngIf="!isLoading"
  >
    <table
      mat-table
      [dataSource]="robotData"
      class="w-full"
      style="border-radius: 8px !important"
    >
      <!--- Note that these columns can be defined in any order.
    The actual rendered columns are set as a property on the row definition" -->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="text-base leading-5">
          Robot Name
        </th>
        <td mat-cell *matCellDef="let robot" class="pt-4 pb-5 max-w-30">
          {{ robot.name }}
        </td>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="issues">
        <th mat-header-cell *matHeaderCellDef class="text-base leading-5">
          Issue(s)
        </th>
        <td
          mat-cell
          *matCellDef="let robot"
          class="pt-4 pb-5 min-w-30 max-w-80"
        >
          {{ robot.stateDetail }}
        </td>
      </ng-container>

      <!-- Location Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef class="text-base leading-5">
          Location
        </th>
        <td mat-cell *matCellDef="let robot" class="pt-4 pb-5">
          {{ robot.pointName }}
        </td>
      </ng-container>

      <!-- Live View Column -->
      <ng-container matColumnDef="liveView">
        <th mat-header-cell *matHeaderCellDef class="text-base leading-5"></th>
        <td mat-cell *matCellDef="let robot" class="pt-4 pb-5">
          <a
            mat-flat-button
            class="h-10 rounded-md bg-neutral-400 max-h-10 text-neutral-50"
            (click)="gotoTeleops(robot.id)"
          >
            <mat-icon
              class="mr-2 text-neutral-50"
              svgIcon="mat_solid:camera_rear"
            >
            </mat-icon>
            Live View
          </a>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="text-base font-bold leading-5 min-w-30 bg-neutral text-neutral-200"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="bg-neutral-600"
      ></tr>
    </table>
  </div>
</div>
<div mat-dialog-actions class="flex flex-row justify-end">
  <button
    mat-dialog-close
    cdkFocusInitial
    class="text-white transition rounded-md mat-focus-indicator mat-flat-button mat-button-base mat-primary hover:bg-primary-800"
  >
    Close
  </button>
</div>
