import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'shortenedUuid',
})
export class ShortenedUuidPipe implements PipeTransform {
  transform(value: string): string {
    const shortenedUuid = value.replace('-', '').substring(0, 6);
    return shortenedUuid;
  }
}
