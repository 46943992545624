import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiBase,
  Layout,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { Observable, of, ReplaySubject, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiLayoutMap extends ApiBase<Layout> {
  public selectedLayoutId$ = new ReplaySubject<string>(1);
  public routingList$ = new ReplaySubject<string[]>(1);
  public selectedDestinationMarker$ = new ReplaySubject<string>(1);

  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = 'layouts';
  }

  public getLayoutImageByID(layoutId: string): Observable<{
    url: string;
    width: number;
    height: number;
  }> {
    return this.getById(layoutId, true).pipe(
      switchMap((response: ResponseOne<Layout>) => {
        if (response.code === 200) {
          return this.getAssetFile(`${response.result.url}_origin`).pipe(
            switchMap((blob: Blob) => this.convertBlobToBase64(blob)),
            switchMap(async (imageUrl: string) => {
              const dimensions = await this.getImageDimensions(imageUrl);
              return {
                url: imageUrl,
                width: dimensions.width,
                height: dimensions.height,
              };
            })
          );
        } else {
          return of({ url: '', width: 0, height: 0 });
        }
      })
    );
  }

  public async getImageDimensions(
    url: string
  ): Promise<{ width: number; height: number }> {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = () => {
        resolve({ width: 0, height: 0 });
      };
    });
  }
}
