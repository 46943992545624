import { ElementRef, Injectable } from '@angular/core';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { MarkerType, MarkerTypeData } from 'app/services/api.types';
import { LeafletService } from 'app/services/leaflet.service';
import { SafeUrlPipe } from 'app/shared/pipes/safe-url.pipe';
import { Map, marker } from 'leaflet';
import { BehaviorSubject } from 'rxjs';

export interface ToastData {
  id: string;
  type: 'low-battery' | 'job-failed' | 'robot-arrived' | 'robot-malfunction';
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutMapService extends LeafletService {
  private flyToZoom: number;

  public toastQueue$: BehaviorSubject<ToastData[]> = new BehaviorSubject<
    ToastData[]
  >(null);

  constructor(
    protected dashboardService: DashboardService,
    private safeUrl: SafeUrlPipe
  ) {
    super(dashboardService);
    this.MAP_TYPE = 'layout-map';
  }

  /**
   * Helper function to init map using Maplibre library.
   * After map initiated, it will set function when
   * map load, zooming, & finish zooming
   *
   * @param mapRef Map reference for maplibre
   * @param options Leaflet Map Options
   * @param isMiniLayout used to check if init map for mini layout or not (true / false)
   */
  public initMap(mapRef: ElementRef, options: L.MapOptions): void {
    const zoom = localStorage.getItem('zoomLeaflet');

    this.flyToZoom = (zoom && Number(zoom)) || 1;
    this.mapZoom = (zoom && Number(zoom)) || 1;
    this.dashboardService.mapZoom$.next(this.mapZoom);
    this.map = new Map(mapRef.nativeElement, {
      ...options,
      zoom: this.mapZoom,
    });

    this.map.setMinZoom(-2);
    this.map.setMaxZoom(5);
    this.map.addLayer(this._markersGroup);
    this.map.addLayer(this._robotMarkersGroup);
    this.map.addLayer(this._eventMarkersGroup);
    this.map.addLayer(this._sensorMarkersGroup);
    this.map.addLayer(this._graphMarkersGroup);
    this.map.addLayer(this._trafficLanesGroup);
    this.map.addLayer(this._plannedPathGroup);
    this.map.addLayer(this._zonesGroup);

    // set current zoom level for slider when user zoom the map
    // using mouse scroll or pinch gesture, so it will reflect
    // the zoom tool slider thumb position.
    this.map.on('zoom', () => {
      if (!this.isUseZoomTool) {
        this.mapZoom = this.map.getZoom();
        this.dashboardService.mapZoom$.next(this.mapZoom);
      }
    });

    // set the variable isUseZoomTool to false after zoom finish,
    // so when user use mouse scroll or pitch gesture it will
    // reflect the zoom tool slider thumb position.
    // Then save the current zoom level to local storage.
    this.map.on('zoomend', () => {
      if (this.isUseZoomTool) {
        this.isUseZoomTool = false;
      }

      const zoom = this.map.getZoom();
      localStorage.setItem('zoomLeaflet', `${zoom}`);
    });

    // create the pane for traffic engine path
    const myMap = this.map;
    myMap.createPane(this.TRAFFIC_ENGINE_PANE);
    myMap.getPane(this.TRAFFIC_ENGINE_PANE).style.zIndex = '401';
  }

  /**
   * Add marker to a map
   *
   * @param lMarker marker data
   * @param type marker type ('marker' or 'robot')
   * @param options Leaflet Marker Options
   */
  public renderLayoutMarker(
    lMarker: MarkerTypeData,
    type: MarkerType,
    options: Partial<RmMarkerOptions>
  ): void {
    // first, try to find if layout Marker is already in list of markers.
    // if no, create new marker. Else, update the leaflet marker
    const found = this.findMarkerFromLayoutMarker(lMarker, type);

    if (found) {
      // update the marker
      this.updateMarker(found, lMarker, type);
      return;
    }

    // Create marker. Then, set the icon and color
    const lfMarker = this.createMarker(lMarker, type, options);
    this.setMarkerIcon(
      lfMarker,
      type,
      lMarker.id,
      lMarker['name'] || lMarker['title'],
      lMarker['detectionNumber'],
      lMarker['heading'],
      lMarker['blinking'],
      lMarker['malfunctionNumber'],
      false,
      lMarker['type']
    );
  }

  /**
   * Helper function to make the map centered and
   * zoomed for first time when open the layout map
   */
  public setMapCenter(): void {
    const mapcenter = this.map.getCenter();
    this.map.flyTo(mapcenter, this.flyToZoom);
  }

  /**
   * Creates a marker based on the provided data, type, and options.
   *
   * @param {MarkerTypeData} lMarker - The data for the marker.
   * @param {MarkerType} type - The type of the marker ( marker, robot, event, layoutSensor, trafficGraph, trafficGraphMarker).
   * @param {Partial<RmMarkerOptions>} options - Additional options for the marker.
   * @return {L.Marker} - The created marker.
   */
  protected createMarker(
    lMarker: MarkerTypeData,
    type: MarkerType,
    options: Partial<RmMarkerOptions>
  ): L.Marker {
    // create new marker
    let lPoint: L.PointTuple = [0, 0];
    switch (type) {
      case this.MARKER_TYPE.marker:
      case this.MARKER_TYPE.charging:
        if (lMarker['position']) {
          lPoint = [lMarker['position']?.x, lMarker['position']?.y];
        } else {
          lPoint = [lMarker['x'], lMarker['y']];
        }
        break;
      case this.MARKER_TYPE.robot:
        lPoint = [lMarker['point']?.x, lMarker['point']?.y];
        break;
      case this.MARKER_TYPE.event:
        lPoint = [lMarker['layout']?.x, lMarker['layout']?.y];
        break;
      case this.MARKER_TYPE.layoutSensor:
        lPoint = [lMarker['coordinate']?.x, lMarker['coordinate']?.y];
        break;
      case this.MARKER_TYPE.trafficGraph:
      case this.MARKER_TYPE.trafficGraphMarker:
        lPoint = [lMarker['x'], lMarker['y']];
        break;
    }

    options = {
      ...options,
      markerId: lMarker.id,
      iconId: lMarker['marker']?.icon ?? null,
      title: lMarker['name'] || lMarker['title'],
      color: lMarker['metadata']?.color ?? 'grey',
      zIndexOffset:
        type === this.MARKER_TYPE.robot ? 402 : options.zIndexOffset,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(lPoint);

    // create the marker and the events
    const lfMarker = marker(latlng, options);

    if (options.click) {
      lfMarker.on('click', options.click);
    }
    if (options.dragstart) {
      lfMarker.on('dragstart', options.dragstart);
    }
    if (options.dragend) {
      lfMarker.on('dragend', options.dragend);
    }

    const refId = `${lMarker.id}_${this.MAP_TYPE}_${type}`;
    // add to markers group
    switch (type) {
      case this.MARKER_TYPE.marker:
      case this.MARKER_TYPE.charging:
        this._markersGroup.addLayer(lfMarker);
        break;
      case this.MARKER_TYPE.robot:
        this._robotMarkersGroup.addLayer(lfMarker);
        break;
      case this.MARKER_TYPE.event:
        this._eventMarkersGroup.addLayer(lfMarker);
        break;
      case this.MARKER_TYPE.layoutSensor:
        // bind the popup for layout sensor markers
        lfMarker.bindPopup(this.createLayoutSensorPopupContent(lMarker), {
          className: 'layout-sensor-popup-container',
          closeButton: true,
          minWidth: 250,
          maxWidth: 500,
          autoPan: true,
        });

        this._sensorMarkersGroup.addLayer(lfMarker);
        break;
      case this.MARKER_TYPE.trafficGraph:
        this._graphMarkersGroup.addLayer(lfMarker);
        break;
    }
    // add to the index
    this._markers[refId] = lfMarker;

    return lfMarker;
  }

  /**
   * Helper function to generate HTML DOM used for render marker on the map
   * @param type Marker type can be 'marker', 'robot', 'event', or 'layout-sensor'
   * @param markerId Marker ID used as reference for leaflet map
   * @param label Label showed for each marker (optional)
   * @param detectionNumber detection number to showed in the marker. It is used in marker with type 'marker' or 'robot' (optional)
   * @param heading Heading angle robot faced when moving in the layout. It is used in marker with type 'robot' (optional)
   * @returns
   */
  protected generateHtmlDomString(
    type: MarkerType,
    markerId: string,
    label?: string,
    detectionNumber?: number,
    heading?: number,
    isBlinking?: boolean,
    malfunctionNumber?: number,
    isShowSonarRobot?: boolean,
    extraType?: string
  ): string {
    const isDetection = detectionNumber && detectionNumber > 0;
    const isMalfunction = malfunctionNumber && malfunctionNumber > 0;
    const detectionStr =
      detectionNumber && detectionNumber > 1 ? 'detections' : 'detection';
    const malfuctionIcon = isMalfunction
      ? `<img class="w-4 detection-icon" src="/assets/images/markers/malfunction-robot-logo.svg">`
      : '';
    const detectionIcon = isDetection
      ? `<img class="w-4 detection-icon" src="/assets/images/markers/detection-logo.svg">`
      : '';
    const errorIcon =
      isDetection || isMalfunction
        ? `<span class="icon-container -mt-[25px] -ml-[42px] absolute flex items-center justify-center w-10 gap-2">
            ${detectionIcon}
            ${malfuctionIcon}
          </span>`
        : '';
    let detectionBlink = isDetection
      ? `<div class="detection-blinking robot"></div>`
      : '';

    if (isBlinking) {
      detectionBlink = `<div class="detection-blinking robot hidden"></div>`;
    }

    let htmlString = '';
    switch (type) {
      case this.MARKER_TYPE.marker:
      case this.MARKER_TYPE.trafficGraphMarker:
        htmlString = `
        <div id="${markerId}_${this.MAP_TYPE}_${type}">
          <span class="marker-label normal">${label ?? ''}</span>
          <div class="marker normal">
            <div class="marker-icon"></div>
          </div>
          <div class="marker-shadow"></div>
        </div>
    `;
        break;
      case this.MARKER_TYPE.robot:
        htmlString = `
        <div id="${markerId}_${this.MAP_TYPE}_${type}">
          <span class="robot-label ${
            isDetection || isMalfunction ? 'detection' : 'normal'
          }">
            ${errorIcon}
            ${label ?? ''}
            <span class="robot-detection">${
              isDetection ? detectionNumber : ''
            } ${isDetection ? detectionStr : ''}</span>
          </span>
          <div class="robot-container ${
            isDetection || isMalfunction ? 'detection' : 'normal'
          }">
            <div class="robot ${
              isDetection || isMalfunction ? 'detection' : 'normal'
            }">
              <div class="robot-icon ${
                isDetection || isMalfunction ? 'detection' : 'normal'
              }"></div>
            </div>
          </div>
          <div class="robot-sonar ${
            isDetection || isMalfunction ? 'detection' : 'normal'
          }" style="transform-origin: center bottom 0px; transform: rotate(${
          heading ?? 0
        }deg);"></div>
        ${detectionBlink}
        <div class="dispatch-blinking robot ${
          isBlinking ? '' : 'hidden'
        }"></div>
        </div>
    `;
        break;
      case this.MARKER_TYPE.event:
      case this.MARKER_TYPE.layoutSensor:
        if (extraType === 'Camera') {
          htmlString = `
          <div id="${markerId}_${this.MAP_TYPE}_${type}">
            <div class="${type}-camera-icon"></div>
            <div class="${type}-camera"></div>
          </div>
      `;
        } else {
          htmlString = `
            <div id="${markerId}_${this.MAP_TYPE}_${type}">
              <span class="${type}-label">${label ?? ''}</span>
              <div class="${type}-icon"></div>
              <div class="${type}"></div>
            </div>
        `;
        }
        break;
      case this.MARKER_TYPE.trafficGraph:
        htmlString = `
            <div id="${markerId}_${this.MAP_TYPE}_${type}">
              <div class="traffic-graph"></div>
              <div class="traffic-graph-blinking hidden"></div>
            </div>
        `;
        break;

      case this.MARKER_TYPE.charging:
        htmlString = `
          <div id="${markerId}_${this.MAP_TYPE}_${type}">
            <span class="charging-label normal">${label ?? ''}</span>
            <div class="charging normal">
              <div class="charging-icon"></div>
            </div>
            <div class="charging-shadow"></div>
          </div>
          `;
    }

    return htmlString;
  }

  /**
   * Generates the popup content for layout sensor markers.
   *
   * @param lMarker - The marker data object.
   * @returns The generated popup content.
   */
  private createLayoutSensorPopupContent(lMarker: MarkerTypeData): string {
    // Remove underscore and capitalize the first letter
    lMarker['type'] = lMarker['type'].replace('_', ' ');
    lMarker['type'] =
      lMarker['type'].charAt(0).toUpperCase() + lMarker['type'].slice(1);

    const name = lMarker['name'];
    const url = lMarker['outputUrl'];

    let popupContent = '<div class="container">';
    popupContent += `<div class="text-center my-3 mx-5">${name} (${lMarker['type']})</div>`;
    popupContent += url
      ? `<iframe allow="autoplay; encrypted-media" src="${url}" style="width: 500px; height: 281.25px;" frameborder="0" allowfullscreen></iframe>`
      : '';
    popupContent += '</div>';

    return popupContent;
  }
}

interface RmMarkerOptions extends L.MarkerOptions {
  markerId: string;
  color?: string;
  iconId?: string;
  click?: L.LeafletEventHandlerFn;
  dragstart?: L.LeafletEventHandlerFn;
  dragend?: L.LeafletEventHandlerFn;
  label?: string;
}
