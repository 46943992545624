import { Injectable } from '@angular/core';
import { ApiCameras } from 'app/services/camera.service';
import _ from 'lodash';
import {
  ApiRobot,
  ResponseWithoutList,
  Camera,
  Skill,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { BehaviorSubject, Observable, ReplaySubject, tap } from 'rxjs';
import { CallRobot } from './teleconference/teleconference.component';
import { SKILL_NAME_TELEOPS } from './teleoperations.config';

@Injectable({
  providedIn: 'root',
})
export class TeleOperationService {
  private _cameras: ReplaySubject<ResponseWithoutList<Camera>> =
    new ReplaySubject<ResponseWithoutList<Camera>>(1);
  public robotId$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _skills: ReplaySubject<ResponseWithoutList<Skill>> =
    new ReplaySubject<ResponseWithoutList<Skill>>(1);
  _robotSkills = [];
  _enableControl: boolean = false;
  enableControl: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _updateMessageType: number;
  updateMessageType: ReplaySubject<number> = new ReplaySubject<number>(1);
  _answerCall: boolean = false;
  answerCall: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  incomingCall$: BehaviorSubject<CallRobot> = new BehaviorSubject<CallRobot>(
    null
  );
  _robotOffline: boolean = false;
  robotOffline: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightW: boolean = false;
  highlightW: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightA: boolean = false;
  highlightA: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightS: boolean = false;
  highlightS: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightD: boolean = false;
  highlightD: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightUp: boolean = false;
  highlightUp: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightLeft: boolean = false;
  highlightLeft: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightRight: boolean = false;
  highlightRight: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightDown: boolean = false;
  highlightDown: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightH: boolean = false;
  highlightH: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightM: boolean = false;
  highlightM: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightSpace: boolean = false;
  highlightSpace: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightZoomOut: boolean = false;
  highlightZoomOut: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _highlightZoomIn: boolean = false;
  highlightZoomIn: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  _cameraHeightValue: number = 0;
  cameraHeightValue: ReplaySubject<number> = new ReplaySubject<number>();

  inCall: boolean = false;
  typingOnTts: boolean = false;

  constructor(private apiCameras: ApiCameras, private _apiRobot: ApiRobot) {
    this.setEnableControl(this._enableControl);
  }

  // get camera$(): Observable<ResponseWithoutList<Camera>> {
  //   return this._cameras.asObservable();
  // }

  getRobotCameras(id: string): Observable<ResponseWithoutList<Camera>> {
    return this.apiCameras.getByRobotId(id).pipe(
      tap((res) => {
        this._cameras.next(res);
      })
    );
  }

  getRobotSkills(id: string): Observable<ResponseWithoutList<Skill>> {
    return this._apiRobot.getRobotSkills(id).pipe(
      tap((res) => {
        this._skills.next(res);
      })
    );
  }

  teleoperationValidity(id, callback) {
    this.getRobotSkills(id).subscribe((response: ResponseOne<any>) => {
      if (response.code === 200 || response.code === 201) {
        if (response.result.length > 0) {
          if (
            _.findLastIndex(response.result, function (o: any) {
              return o.name.includes(SKILL_NAME_TELEOPS);
            }) > -1
          ) {
            callback(true);
          } else {
            callback(false);
          }
        } else {
          callback(false);
        }
      } else {
        callback(false);
      }
    });
  }

  getEnableControl(): boolean {
    return this._enableControl;
  }

  onEnableCOntrol(): Observable<boolean> {
    return this.enableControl.asObservable();
  }

  setEnableControl(flag: boolean): void {
    this._enableControl = flag;
    this.enableControl.next(this._enableControl);
  }

  getMessageType(): number {
    return this._updateMessageType;
  }

  onUpdateMessage(): Observable<number> {
    return this.updateMessageType.asObservable();
  }

  setMessageType(type: number): void {
    this._updateMessageType = type;
    this.updateMessageType.next(this._updateMessageType);
  }

  getAnswerCall(): boolean {
    return this._answerCall;
  }

  onAnswerCall(): Observable<boolean> {
    return this.answerCall.asObservable();
  }

  setAnswerCall(flag: boolean): void {
    this._answerCall = flag;
    this.answerCall.next(this._answerCall);
  }

  getRobotOffline(): boolean {
    return this._robotOffline;
  }

  onRobotOffline(): Observable<boolean> {
    return this.robotOffline.asObservable();
  }

  setRobotOffline(flag: boolean): void {
    this._robotOffline = flag;
    this.robotOffline.next(this._robotOffline);
  }

  gethighlightW(): boolean {
    return this._highlightW;
  }

  onHighlightW(): Observable<boolean> {
    return this.highlightW.asObservable();
  }

  setHighlightW(flag: boolean): void {
    this._highlightW = flag;
    this.highlightW.next(this._highlightW);
  }

  gethighlightA(): boolean {
    return this._highlightA;
  }

  onHighlightA(): Observable<boolean> {
    return this.highlightA.asObservable();
  }

  setHighlightA(flag: boolean): void {
    this._highlightA = flag;
    this.highlightA.next(this._highlightA);
  }

  gethighlightS(): boolean {
    return this._highlightS;
  }

  onHighlightS(): Observable<boolean> {
    return this.highlightS.asObservable();
  }

  setHighlightS(flag: boolean): void {
    this._highlightS = flag;
    this.highlightS.next(this._highlightS);
  }

  gethighlightD(): boolean {
    return this._highlightA;
  }

  onHighlightD(): Observable<boolean> {
    return this.highlightD.asObservable();
  }

  setHighlightD(flag: boolean): void {
    this._highlightD = flag;
    this.highlightD.next(this._highlightD);
  }

  gethighlightUp(): boolean {
    return this._highlightUp;
  }

  onHighlightUp(): Observable<boolean> {
    return this.highlightUp.asObservable();
  }

  setHighlightUp(flag: boolean): void {
    this._highlightUp = flag;
    this.highlightUp.next(this._highlightUp);
  }

  gethighlightDown(): boolean {
    return this._highlightDown;
  }

  onHighlightDown(): Observable<boolean> {
    return this.highlightDown.asObservable();
  }

  setHighlightDown(flag: boolean): void {
    this._highlightDown = flag;
    this.highlightDown.next(this._highlightDown);
  }

  gethighlightLeft(): boolean {
    return this._highlightLeft;
  }

  onHighlightLeft(): Observable<boolean> {
    return this.highlightLeft.asObservable();
  }

  setHighlightLeft(flag: boolean): void {
    this._highlightLeft = flag;
    this.highlightLeft.next(this._highlightLeft);
  }

  gethighlightRight(): boolean {
    return this._highlightRight;
  }

  onHighlightRight(): Observable<boolean> {
    return this.highlightRight.asObservable();
  }

  setHighlightRight(flag: boolean): void {
    this._highlightRight = flag;
    this.highlightRight.next(this._highlightRight);
  }

  gethighlightH(): boolean {
    return this._highlightH;
  }

  onHighlightH(): Observable<boolean> {
    return this.highlightH.asObservable();
  }

  setHighlightH(flag: boolean): void {
    this._highlightH = flag;
    this.highlightH.next(this._highlightH);
  }

  gethighlightM(): boolean {
    return this._highlightM;
  }

  onHighlightM(): Observable<boolean> {
    return this.highlightM.asObservable();
  }

  setHighlightM(flag: boolean): void {
    this._highlightM = flag;
    this.highlightM.next(this._highlightM);
  }

  gethighlightSpace(): boolean {
    return this._highlightSpace;
  }

  onHighlightSpace(): Observable<boolean> {
    return this.highlightSpace.asObservable();
  }

  setHighlightSpace(flag: boolean): void {
    this._highlightSpace = flag;
    this.highlightSpace.next(this._highlightSpace);
  }

  gethighlightZoomOut(): boolean {
    return this._highlightZoomOut;
  }

  onHighlightZoomOut(): Observable<boolean> {
    return this.highlightZoomOut.asObservable();
  }

  setHighlightZoomOut(flag: boolean): void {
    this._highlightZoomOut = flag;
    this.highlightZoomOut.next(this._highlightZoomOut);
  }

  gethighlightZoomIn(): boolean {
    return this._highlightZoomIn;
  }

  onHighlightZoomIn(): Observable<boolean> {
    return this.highlightZoomIn.asObservable();
  }

  setHighlightZoomIn(flag: boolean): void {
    this._highlightZoomIn = flag;
    this.highlightZoomIn.next(this._highlightZoomIn);
  }

  getCameraHeight(): number {
    return this._cameraHeightValue;
  }

  onCameraHeight(): Observable<number> {
    return this.cameraHeightValue.asObservable();
  }

  setCameraHeight(value: number): void {
    this._cameraHeightValue = value;
    this.cameraHeightValue.next(this._cameraHeightValue);
  }
}
