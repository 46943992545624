import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {
  ApiLayout,
  ApiRobot,
  ApiSkill,
  Layout,
  ListQuery,
  ResponseList,
  Robot,
  Skill,
} from 'rm-api-services/dist/api-services';
import { catchError, Observable, ReplaySubject, tap } from 'rxjs';
import { NIL as NIL_UUID } from 'uuid';

export interface MonitoringRobot extends Robot {
  layout?: Layout;
  stateDetail?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  apiUrl: string;
  tempRobots: ResponseList<MonitoringRobot>;
  private _allRobotsTemp: ResponseList<MonitoringRobot>;
  private params: ListQuery = {
    pageNo: 1,
    pageSize: 50,
  };
  private _robots: ReplaySubject<ResponseList<MonitoringRobot>> =
    new ReplaySubject<ResponseList<MonitoringRobot>>(1);
  private _layouts: ReplaySubject<ResponseList<Layout>> = new ReplaySubject<
    ResponseList<Layout>
  >(1);
  private _skills: ReplaySubject<ResponseList<Skill>> = new ReplaySubject<
    ResponseList<Skill>
  >(1);
  private _allRobots: ReplaySubject<ResponseList<MonitoringRobot>> =
    new ReplaySubject<ResponseList<MonitoringRobot>>(1);

  private broadcastMessageSubject: ReplaySubject<string> =
    new ReplaySubject<string>(1);

  /**
   * Constructor
   */
  constructor(
    private _apiRobot: ApiRobot,
    private _apiLayout: ApiLayout,
    private _apiSkill: ApiSkill,
    private http: HttpClient
  ) {
    this.apiUrl = environment.coreApiUrl;
    this._apiLayout.baseUrl = environment.coreApiUrl;
  }

  get robots$(): Observable<ResponseList<MonitoringRobot>> {
    return this._robots.asObservable();
  }

  get layouts$(): Observable<ResponseList<Layout>> {
    return this._layouts.asObservable();
  }

  get skills$(): Observable<ResponseList<Skill>> {
    return this._skills.asObservable();
  }

  get allRobots$(): Observable<ResponseList<MonitoringRobot>> {
    return this._allRobots.asObservable();
  }

  getBroadcastMessageSubject(): ReplaySubject<string> {
    return this.broadcastMessageSubject;
  }

  getAllRobots(
    isNotNext: boolean = false
  ): Observable<ResponseList<MonitoringRobot>> {
    return this._apiRobot.getAllResponseList(this.params).pipe(
      tap((res) => {
        this.tempRobots = res;
        this._allRobotsTemp = res;
        this._robots.next(res);
        this._allRobots.next(res);
      })
    );
  }

  // fileting robot list by selected/loaded layout
  filterRobotByLayout(layoutId?: string, siteId?: string): void {
    if (layoutId) {
      this.tempRobots = {
        ...this._allRobotsTemp,
        result: {
          ...this._allRobotsTemp.result,
          list: this._allRobotsTemp.result.list.filter(
            (x) => x.layoutId == layoutId
          ),
        },
      };

      this._robots.next(this.tempRobots);
    } else if (!layoutId && siteId) {
      this.tempRobots = {
        ...this._allRobotsTemp,
        result: {
          ...this._allRobotsTemp.result,
          list: this._allRobotsTemp.result.list.filter(
            (x) => x.layoutId == null
          ),
        },
      };

      this._robots.next(this.tempRobots);
    } else if (!layoutId && !siteId) {
      this._robots.next(this._allRobotsTemp);
    }
  }

  getAllLayouts(): Observable<ResponseList<Layout>> {
    return this._apiLayout
      .getAllResponseList(this.params)
      .pipe(tap((res) => this._layouts.next(res)));
  }

  getAllSkills(): Observable<ResponseList<Skill>> {
    return this._apiSkill
      .getAllResponseList(this.params)
      .pipe(tap((res) => this._skills.next(res)));
  }

  getLayoutName(layoutId: string): Promise<Layout | null> {
    return new Promise((resolve) => {
      if (layoutId !== NIL_UUID) {
        this.layouts$.subscribe((response) => {
          const tempLayouts = response?.result?.list || [];
          const currentLayout = tempLayouts.find(
            (layout) => layout.id === layoutId
          );
          if (currentLayout) {
            resolve(currentLayout);
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  getBroadcastMessage(): Observable<string> {
    const url = '/assets/config/broadcast-messages.json';
    return this.http.get(url, { responseType: 'text' }).pipe(
      catchError((error) => {
        // Handle any errors that occur during the HTTP request
        console.error('HTTP Error:', error);
        return [];
      }),
      tap((message: string) => {
        // Save the broadcast message in the ReplaySubject
        this.broadcastMessageSubject.next(message);
      })
    );
  }
}
