import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import {
  ApiBase,
  ResponseList,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { Campaign } from './api.types';

@Injectable({
  providedIn: 'root',
})
export class ApiCampaign extends ApiBase<Campaign> {
  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = 'campaign';
    this.base = environment.coreApiUrl;
  }

  public create = (query): Observable<ResponseList<Campaign>> =>
    this.post(`${this.resourceBaseUrl}/create`, query);

  public updateCampaign = (
    id: string,
    payload: Partial<Campaign>
  ): Observable<ResponseOne<Campaign>> =>
    this.http.put<ResponseOne<Campaign>>(
      `${this.base}/${this.resourceBaseUrl}/${id}`,
      payload
    );
}
