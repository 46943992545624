import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from './../shared/ui-components/snack-bar/snack-bar.component';
import { Injectable } from '@angular/core';

export type snackbarType = 'success' | 'warning' | 'failed' | 'info';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(public snackBar: MatSnackBar) {}
  openSnackBar(data: {
    message: string;
    type?: snackbarType;
    panelClass?: string | string[];
    preClose?: () => void;
    duration?: number;
    teleopsPage?: boolean;
  }): void {
    let panelClass = ['bg-info-300'];
    switch (data.type) {
      case 'success':
        panelClass = ['bg-success-400'];
        break;
      case 'warning':
        panelClass = ['bg-warning'];
        break;
      case 'failed':
        panelClass = ['bg-error-700'];
        break;
      case 'info':
        panelClass = ['bg-info-300'];
        break;
      default:
        panelClass = ['bg-info-300'];
        break;
    }

    // add classes below if the snackbar is trigger for control in teleops page
    if (data.teleopsPage) {
      panelClass.push(
        'snackbar-teleops',
        'absolute',
        'top-48',
        'left-0',
        'right-0',
        'mx-auto',
        'max-w-fit'
      );
    }

    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message: data.message,
        type: data.type,
        preClose: () => {
          this.snackBar.dismiss();
        },
      },
      panelClass: panelClass,
      duration: data.duration ?? 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }
  closeSnackBar(): void {
    this.snackBar.dismiss();
  }
}
