import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, QueryList } from '@angular/core';
import {
  ApiBase,
  ResponseList,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { ListQuery, Malfunction } from './api.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MalfunctionService extends ApiBase<Malfunction> {
  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = 'malfunction';
    this.base = environment.apiUrl;
  }

  getNotes(payload: ListQuery): Observable<ResponseList<Malfunction>> {
    return this.post(`${this.resourceBaseUrl}/notes`, payload);
  }

  postNotes(payload: NotesPayload): Observable<ResponseOne<Malfunction>> {
    return this.http.post<ResponseOne<Malfunction>>(
      `${this.base}/${this.resourceBaseUrl}/create-note`,
      payload
    );
  }

  closeMalfunction(malId: string): Observable<ResponseOne<Malfunction>> {
    return this.http.put<ResponseOne<Malfunction>>(
      `${this.base}/${this.resourceBaseUrl}/close-status`,
      {
        malfunctionId: malId,
      }
    );
  }
}

interface NotesPayload {
  note: string;
  malfunctionId: string;
}
