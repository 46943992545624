import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'robotStatus',
})
export class RobotStatusPipe implements PipeTransform {
  transform(value: number): string {
    /**
     * State when online
     * 1: Init
     * 2: Idle
     * 3: Working
     * 4: Charging
     * 5: Error
     * 6:Teleop
     * Link ref: https://docs.robotmanager.com/docs/server-clientrobot-communication#send-robot-state-to-robotmanager-robotstatecompanyid
     */
    switch (value) {
      case 1:
        return 'Init';
      case 2:
        return 'Idle';
      case 3:
        return 'Working';
      case 4:
        return 'Charging';
      case 5:
        return 'Malfunctioning';
      case 6:
        return 'Tele-operating';
      default:
        return 'Online'; // Because if the robot send the robot/state topic, of course the robot is online.
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [RobotStatusPipe],
  exports: [RobotStatusPipe],
})
export class RobotStatusPipesModule {}
