import {
  Job,
  Mission,
  MqttSettings,
  ResponseOne,
  Skill,
} from 'rm-api-services/dist/api-services';
import { DatePipe } from '@angular/common';
import { Robot } from 'api-services';
import { SnackBarService } from '../../../../services/snack-bar.service';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NewJobService } from 'app/services/new-job.service';
import { JobService } from 'app/services/jobs.service';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { RobotSocketData } from 'app/services/api.types';
import moment from 'moment';
import _ from 'lodash';
import { SelectOption } from 'app/shared/ui-components/form-select/form-select.component';
import { FormControl } from '@angular/forms';

interface DetailMission extends Mission {
  robotIds: string[];
  robotNames: string[];
}

@Component({
  selector: 'app-go-charge',
  templateUrl: './go-charge.component.html',
  styleUrls: ['./go-charge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoChargeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() events: Observable<void>;
  @Input() robot?: Robot;
  @Input() robots?: Robot[] = [];
  @Input() jobs: Job[] = [];
  @Input() layoutId: string;
  @Input() robotSkills: Skill[];
  @Output() closeDrawer = new EventEmitter();

  private checkOfflineTimer;
  private eventsSubscription: Subscription;
  private chargeSkill: Skill;
  private gotoSkill: Skill;
  private currentActiveJobs: Mission[];
  private selectedRobotReplace: string;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  private robotStateMap: Map<string, number> = new Map<string, number>();

  public robotOnlineList: Robot[] = [];
  public takeActionList: SelectOption[] = [
    {
      display: 'Abort current job and send to charging',
      value: 'abort',
    },
    {
      display: 'Re-allocate to another robot and send to charging',
      value: 'reallocate',
    },
  ];

  public selectedTakeAction: FormControl = new FormControl('reallocate');

  constructor(
    private snackbarService: SnackBarService,
    private newJob: NewJobService,
    private jobService: JobService,
    private _datePipe: DatePipe,
    private mqttSettings: MqttSettings
  ) {}

  ngOnInit(): void {
    this.eventsSubscription = this.events.subscribe(() => {
      this.submitForm();
    });

    if (this.robotSkills) {
      this.chargeSkill = this.robotSkills.find(
        (skill) => skill.name === 'RM-GO-CHARGE'
      );
    }

    // Subscribe to MQTT robot data topic
    this.mqttSettings.socketRobotData$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data: RobotSocketData) => {
        if (data && data.layout && data.robotId) {
          const robotIndex = this.robots.findIndex(
            (robot) => robot.id === data.robotId
          );

          if (robotIndex > -1) {
            // check if the robot state is not 3 or 5 or 6 (working, malfunction, teleops),
            // then add the robot to robotOnlineList
            const robotState = this.robotStateMap.get(data.robotId) || 0;
            if (![3, 5, 6].includes(robotState)) {
              // Add robot to robotOnlineList if it doesn't exist
              if (!this.robotOnlineList.includes(this.robots[robotIndex])) {
                this.robotOnlineList.push(this.robots[robotIndex]);
              }
            }
          }

          // Update the robot status
          this.updateRobotStatusBySocket(data);
        }
      });

    // Subscribe to MQTT robot state topic
    this.mqttSettings.socketRbtStateData$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        if (data && data.robotId) {
          // Update the robot state

          this.robotStateMap.set(data.robotId, data.state);

          // check if the state is 3, 5, 6 (working, malfunction, teleops)
          //if so, then remove the robot from robotOnlineList
          if ([3, 5, 6].includes(data.state)) {
            const robotIndex = this.robotOnlineList.findIndex(
              (robot) => robot.id === data.robotId
            );
            if (robotIndex > -1) {
              this.robotOnlineList.splice(robotIndex, 1);
            }
          }

          this.updateRobotStateBySocket(data);
        }
      });

    this.checkRobotOfflineTimer();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.robotSkills) {
      if (this.robotSkills) {
        this.chargeSkill = this.robotSkills.find(
          (skill) => skill.name === 'RM-GO-CHARGE'
        );
        this.gotoSkill = this.robotSkills.find(
          (skill) => skill.name === 'RM-GOTO'
        );
      }
    }

    if (changes.jobs) {
      this.currentActiveJobs = changes.jobs.currentValue.filter(
        (job) => job.status === 5
      );
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  selectRobot(robotId: string): void {
    this.selectedRobotReplace = robotId;
  }

  private sendRobotCharging() {
    if (this.robot.state === 4) {
      this.snackbarService.openSnackBar({
        message: `Robot is already in charging station`,
        type: 'failed',
      });
      return;
    }

    if (this.robot.state === 3) {
      if (this.selectedTakeAction.value === 'abort') {
        let count = 0;

        // loop current active jobs to be aborted
        if (this.currentActiveJobs.length > 0) {
          for (const job of this.currentActiveJobs) {
            const data: Partial<DetailMission> = {
              id: job.id,
              status: 2,
            };

            // update active mission to abort
            this.jobService
              .updateMission(data)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((response: ResponseOne<Job>) => {
                count++;
                if (response.code === 200) {
                  this.jobService.updateSumarry$.next(true);
                  if (count === this.currentActiveJobs.length) {
                    // create charge job
                    this.createGotoChargeJob(4);
                  }
                } else {
                  this.snackbarService.openSnackBar({
                    message: response.message,
                    type: 'failed',
                  });
                }
              });
          }
        } else {
          this.snackbarService.openSnackBar({
            message: `There is no active job to abort`,
            type: 'failed',
          });
        }
      } else if (this.selectedTakeAction.value === 'reallocate') {
        // check if the robot replace is selected
        if (!this.selectedRobotReplace) {
          this.snackbarService.openSnackBar({
            message: `Please select a robot to replace`,
            type: 'failed',
          });
          return;
        }
        // re allocate the current job to others available robots
        if (this.currentActiveJobs.length > 0) {
          for (const job of this.currentActiveJobs) {
            const data = {
              id: job.id,
              robotIds: [this.selectedRobotReplace],
            };

            // update active mission to abort
            this.jobService
              .replaceRobots(data)
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((response: ResponseOne<Job>) => {
                if (response.code === 200) {
                  this.jobService.updateSumarry$.next(true);
                  // create charge job
                  this.createGotoChargeJob();
                } else {
                  this.snackbarService.openSnackBar({
                    message: response.message,
                    type: 'failed',
                  });
                }
              });
          }
        } else {
          this.snackbarService.openSnackBar({
            message: `There is no active job to reallocate`,
            type: 'failed',
          });
        }
      }
    } else {
      // create charge job
      this.createGotoChargeJob();
    }
  }

  private createGotoChargeJob(mode: number = 1) {
    const robotIds = this.robot
      ? [this.robot.id]
      : this.robots.map((robot) => robot.id);

    const payload = {
      followPath: 1, // 1 = yes; 2 = no;
      mode: mode, // 1 = Execute immediately; 2 = Start after current job is finished; 3 = Scheduled; 4 = abort current job and immediately start new job; 5 = Start now and pause previous job
      name: 'GO CHARGING ' + moment(new Date()).format('yyyy-MM-DD, HH:mm:ss'),
      priority: 1,
      repeat: 0,
      type: 1,
      tasks: [
        // {
        //   order: 0,
        //   skillId: this.gotoSkill.id,
        //   params: [
        //     {
        //       paramKey: pKey,
        //       paramValue: pValue,
        //     },
        //   ],
        // },
        {
          order: 0,
          skillId: this.chargeSkill.id,
          params: [],
          layoutId: this.layoutId,
        },
      ],
      layoutId: this.layoutId,
      robotIds: [...robotIds],
    };

    const robotsLength = this.robots.length;
    const robotsLengthStr = robotsLength > 1 ? 'robots' : 'robot';
    const successMessage =
      robotsLength > 0
        ? `New job added to ${robotsLength} ${robotsLengthStr} successfully`
        : `Robot returned to charge successfully`;

    this.newJob
      .newJob(payload)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (res.code === 200) {
          this.snackbarService.openSnackBar({
            message: successMessage,
            type: 'success',
          });
          this.closeDrawer.emit();
        } else {
          this.snackbarService.openSnackBar({
            message: `${res.message}`,
            type: 'failed',
          });
        }
      });
  }

  public submitForm(): void {
    if (this.robots.length === 0 && !this.robot) {
      this.snackbarService.openSnackBar({
        message: 'Please select at least 1 robot',
        type: 'failed',
      });
      return;
    }

    this.sendRobotCharging();
    // this.createGotoChargeJob();
  }

  /**
   * Helper function to update robot status based on MQTT message
   *
   * @param socketData Robot data form MQTT socket
   */
  private updateRobotStateBySocket(socketData: RobotSocketData): void {
    this.robots.map((robot) => {
      //  Check if the updated robot status is on the list
      // then update the state
      if (robot.id === socketData.robotId) {
        robot.state = socketData.state;
      }
    });
  }

  /**
   * Helper function to update robot status based on MQTT message
   *
   * @param socketData Robot data form MQTT socket
   */
  private updateRobotStatusBySocket(socketData: RobotSocketData): void {
    this.robots.map((robot) => {
      //  Check if the updated robot status is on the list
      if (robot.id === socketData.robotId) {
        robot.status = 1;
        robot.battery = Number(socketData.battery);
        robot.connectivity = socketData.connectivity;
        // check the length of the timestamp, because the moment library
        // has two functions to convert timestamp into date format
        // and some of the robot, send status using this two formats.
        // the length can be 10 or 13
        if (socketData.timestamp.toString().length > 10) {
          robot.lastOnlineTime = moment(socketData.timestamp).fromNow();
        } else {
          robot.lastOnlineTime = moment.unix(socketData.timestamp).fromNow();
        }
        robot.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');
      }
    });
  }

  /**
   * Helper function to check the robot status from MQTT for every 3 seconds.
   * It is used in robot list in the layout marker popup
   *
   */
  private checkRobotOfflineTimer(): void {
    this.checkOfflineTimer && clearInterval(this.checkOfflineTimer);
    this.checkOfflineTimer = setInterval(() => {
      if (this.robotOnlineList.length > 0) {
        this.robots.map((robot) => {
          if (
            this.robotOnlineList.find(
              (robotOnline) => robotOnline.id === robot.id
            )
          ) {
            const currentTime = moment();
            const updatedTime = moment(robot.updatedAt);
            const differenceInSeconds = currentTime.diff(
              updatedTime,
              'seconds'
            );
            if (robot.updatedAt && differenceInSeconds > 10) {
              robot.status = 2;

              robot.updatedAt = moment().format('YYYY-MM-DD HH:mm:ss');

              // Find the index of the robot in robotOnlineList
              const indexToRemove = this.robotOnlineList.findIndex(
                (onlineRobot) => onlineRobot.id === robot.id
              );

              // Remove the robot from robotOnlineList
              if (indexToRemove !== -1) {
                this.robotOnlineList.splice(indexToRemove, 1);
              }

              // remove the offline robot from selectedRobotReplace
              if (this.selectedRobotReplace === robot.id) {
                this.selectedRobotReplace = undefined;
              }
            }
          }
        });
      }
    }, 3 * 1000);
  }
}
