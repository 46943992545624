import { Subscription } from 'rxjs';
import { MqttSettings } from 'api-services';
import { CallService } from './services/call.service';
import { Component } from '@angular/core';
import { AuthService } from './core/auth/auth.service';
import { PreventOrientation } from 'prevent-orientation';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   *
   * Constructor
   */
  constructor() {
    if (
      screen.orientation.type === 'portrait-primary' ||
      screen.orientation.type === 'portrait-secondary'
    ) {
      new PreventOrientation({
        text: 'Sorry, this device Portrait orientation is not supported',
        color: 'white',
        background: '#1e293b',
        fontSize: '1.2rem',
      }).preventOrientationToAngle(0);
    }
  }
}
