import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-robot-battery-text',
  template: `<div [ngClass]="{ 'text-error': percentage <= 10 }">
    {{ percentage | number : '1.0-0' }}%
  </div>`,
})
export class RobotBatteryTextComponent implements OnInit {
  @Input() percentage: number = 0;

  ngOnInit(): void {}
}
