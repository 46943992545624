import { Component, Input } from '@angular/core';

@Component({
  selector: 'patrol-routing-name',
  styles: [
    `
      .content {
        border-color: theme('colors.neutral.400') !important;
        background-color: theme('colors.neutral.500') !important;
        border-radius: 4px;
        border: 1px solid;
        margin: 0 0 10px 0;
        padding: 12px 14px;
      }
    `,
  ],
  template: `
    <div class="w-full content">
      {{ name }}
    </div>
  `,
})
export class RoutingNameComponent {
  @Input() name: string;
}
