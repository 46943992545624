<div
  class="relative flex w-full justify-center overflow-hidden {{
    classContainerHeight
  }}"
  [ngClass]="{
    'rounded-lg': !eventLocation
  }"
>
  <ng-container *ngIf="isShowAlert">
    <div class="absolute flex z-999 max-w-[80%] mt-5">
      <fuse-alert [appearance]="'soft'" [type]="'accent'" [showIcon]="false">
        <div class="flex gap-2 items-center justify-center">
          <mat-icon
            class="icon-size-6"
            svgIcon="heroicons_solid:cursor-click"
          ></mat-icon>
          <ng-content select="[messageAlert]"></ng-content>
        </div>
      </fuse-alert>
    </div>
  </ng-container>
  <div
    class="w-full h-full bg-[#f6f1e5] relative"
    [ngClass]="{ 'opacity-60': isTeleopMap && isFadingMap }"
  >
    <div
      class="flex absolute top-2 left-2 z-999 font-bold text-sm justify-center items-center bg-slate-900 h-6 w-6 rounded bg-opacity-50"
      *ngIf="isTeleopMap"
      [ngClass]="activeM ? 'text-primary-500' : 'text-shades-white'"
    >
      M
    </div>
    <div
      #miniLayoutMapContainer
      class="w-full h-full layout-map-container"
      [ngClass]="{
        'bg-shades-black': isTeleopMap,
        'cursor-pointer': isTeleopMap
      }"
      (click)="isFadingMap = !isFadingMap"
    ></div>
    <div
      class="flex flex-col absolute bottom-2 left-2 z-999 text-shades-white font-normal text-xs"
      *ngIf="isTeleopMap"
    >
      <p *ngIf="siteName">{{ siteName }}</p>
      <p *ngIf="layoutName">{{ layoutName }}</p>
    </div>
    <zoom-tool
      [zoomStep]="0.5"
      [minZoom]="-5"
      [maxZoom]="5"
      [isUseSlider]="false"
      [isMinimap]="true"
      (setZoom)="setZoom($event)"
    ></zoom-tool>
  </div>
</div>
