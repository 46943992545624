<div
  class="rounded-t-lg text-white text-center font-semibold uppercase py-2 bg-error-700 cursor-pointer"
>
  {{ headerTitle }}
</div>

<!-- Selected Media -->
<!-- skelelton -->
<div class="animate-pulse flex justify-center p-4" *ngIf="!isLoaded && isMedia">
  <div class="h-[350px] w-full bg-neutral-600 rounded"></div>
</div>

<!-- media -->
<div
  *ngIf="mediaFiles && mediaUrl"
  class="bg-error-900 cursor-pointer"
  [ngClass]="isLoaded !== true ? 'hidden w-0' : ''"
>
  <img *ngIf="!isVideo" class="h-auto" [src]="mediaUrl" (load)="onLoaded()" />
  <video
    *ngIf="isVideo"
    class="h-auto"
    [src]="mediaUrl"
    (loadeddata)="onLoaded()"
  ></video>
</div>
<div
  class="flex flex-col rounded-b-lg text-left pl-3 pt-4 pb-2 bg-error-900 cursor-pointer"
>
  <span class="text-white font-medium uppercase">
    {{ timestamp | timestampPipe }}
  </span>
  <span class="text-neutral-100">
    {{ layoutName }}
  </span>
  <span class="text-neutral-100 text-base mt-2">
    Detected by
    <span class="text-white font-medium"> {{ robotName }}</span>
  </span>
</div>
