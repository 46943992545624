import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiBase,
  ListQuery,
  ResponseList,
  Job,
  Mission,
  LayoutMarker,
} from 'rm-api-services/dist/api-services';
import { Observable, Subject } from 'rxjs';

const missionStatusMapping = {
  1: 'Completed',
  2: 'Aborted',
  3: 'Paused',
  4: 'Scheduled',
  5: 'Active',
  6: 'Failed',
};
@Injectable({
  providedIn: 'root',
})
export class JobService extends ApiBase<Mission> {
  updateSumarry$ = new Subject<boolean>();

  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = 'mission';
  }

  public list = (query: ListQuery): Observable<ResponseList<Mission>> =>
    this.post(`${this.resourceBaseUrl}/list`, query);

  updateMission(data: Partial<Mission>) {
    const { id, status, name, description } = data;
    let statusValue = status;
    // if (typeof status === 'string') {
    //   const statusvalue1 = status && status.toLocaleLowerCase();
    //   statusValue = this.missionStatusMapping[statusvalue1];
    // }
    const psd = {
      id: id,
      name: name,
      description: description,
      priority: 0,
      status: statusValue,
      type: 0,
    };
    return this.http.put(this.base + '/mission/update', psd);
  }

  public replaceRobots(payload: { id: string; robotIds: string[] }) {
    return this.http.put(`${this.base}/mission/replace`, payload);
  }

  abortAllActiveMission(robotId: string) {
    return this.http.put(`${this.base}/mission/abort-all/${robotId}`, null);
  }
}
