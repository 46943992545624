<div
  *ngIf="!isInGroupPatrol"
  class="absolute flex flex-row justify-start h-auto gap-3 px-5 py-4 transition-all border-solid rounded-lg right-5 top-3 w-72 bg-error-800 border-error-500 border-x border-y drop-shadow-md"
>
  <ng-container *ngTemplateOutlet="malfunctionToastContent"> </ng-container>
</div>
<div
  *ngIf="isInGroupPatrol"
  class="toast-item flex justify-item-center p-5 drop-shadow-md"
>
  <ng-container *ngTemplateOutlet="malfunctionToastContent"> </ng-container>
</div>

<ng-template #malfunctionToastContent>
  <div>
    <mat-icon
      [svgIcon]="'mat_solid:build'"
      class="malfunction-icon mr-2 text-error-300 scale-x-[-1] pt-[2px]"
    ></mat-icon>
  </div>
  <div class="flex flex-col">
    <span *ngIf="totalRobot > 1" class="block mb-2 font-semibold"
      >{{ totalRobot }} robots are malfunctioning in this building</span
    >
    <span *ngIf="totalRobot === 1" class="block mb-2 font-semibold"
      >{{ robots[0]?.name }} is malfunctioning</span
    >
    <span class="block mb-2">
      {{
        isInGroupPatrol
          ? 'Reallocate patrol route'
          : 'Find out what the issues are!'
      }}
    </span>
    <div class="flex flex-row gap-4">
      <button
        (click)="seeIssue()"
        class="bg-white text-neutral-500 font-medium text-base leading-5 px-3 py-[7px] rounded-md"
      >
        {{ isInGroupPatrol ? 'Reallocate' : 'See Issues' }}
      </button>
      <button
        class="font-medium text-base leading-5 px-3 py-[7px]"
        (click)="closeCardToast()"
      >
        Dismiss
      </button>
    </div>
  </div>
</ng-template>
