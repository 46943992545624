import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-robot-battery',
  template: `<div
    class="ml-4 w-8 h-4 relative rounded"
    [ngClass]="{ 'bg-warning': isCharging, 'bg-neutral-400': !isCharging }"
  >
    <span
      class="absolute text-xs font-bold top-0 left-1"
      [ngClass]="{
        'text-neutral-900': percentage > 20,
        'text-white': percentage <= 20
      }"
      >{{ percentage | number : '1.0-0' }}</span
    >
    <mat-icon
      *ngIf="isCharging"
      class="scale-50 absolute -top-1 right-0 text-neutral-900"
      svgIcon="mat_solid:bolt"
    ></mat-icon>
    <div
      class="rounded h-4"
      [ngClass]="{
        'bg-error-300': percentage <= 20 && !isCharging,
        'bg-neutral-100': percentage > 20 && !isCharging,
        'bg-warning': percentage > 0 && isCharging
      }"
      [style]="'width: ' + percentage + '%'"
    ></div>
    <div
      class="absolute w-1 h-2 -right-1 rounded-br-lg rounded-tr-lg top-1"
      [ngClass]="{ 'bg-warning': isCharging, 'bg-neutral-400': !isCharging }"
    ></div>
  </div>`,
})
export class RobotBatteryComponent implements OnInit {
  @Input() percentage: number = 0;
  @Input() isCharging: boolean = false;

  ngOnInit(): void {}
}
