import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-empty-table',
  template: `<div
    class="w-full h-50 flex items-center justify-center bg-neutral-600"
  >
    <p>{{ text }}</p>
  </div>`,
})
export class EmptyTableComponent implements OnInit {
  @Input() text: string = 'No data found.';

  constructor() {}

  ngOnInit() {}
}
