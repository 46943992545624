import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatInputModule } from '@angular/material/input';
import { CronsPipesModule } from './pipes/cronstrue.pipe';
import { MatButtonModule } from '@angular/material/button';
import { FileSizePipe } from './pipes/filesize.pipe';
import { InputFileDropzoneComponent } from './ui-components/input-file-dropzone/input-file-dropzone.component';
import { FormTextareaComponent } from './ui-components/form-textarea/form-textarea.component';
import { FormBoxedRadioButtonComponent } from './ui-components/form-boxed-radio-button/form-boxed-radio-button.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { RobotSignalComponent } from './ui-components/robot-signal/robot-signal.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetectionPanelComponent } from './layouts/detection-panel/detection-panel.component';
import { MatIconModule } from '@angular/material/icon';
import { ZoomToolComponent } from './ui-components/zoom-tool/zoom-tool.component';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CardDetectionComponent } from './ui-components/card-detection/card-detection.component';
import { CardStatusSmallComponent } from './ui-components/card-status-small/card-status-small.component';
import { MapQuickNavigationComponent } from './ui-components/map-quick-navigation/map-quick-navigation.component';
import { RobotLastOnline } from './pipes/robot-last-online.pipe';
import { NoDetectionComponent } from './ui-components/no-detection/no-detection.component';
import {
  SecondToTimePipe,
  TimestampPipe,
  DateToTimestampAgoPipe,
  FormatDatePipe,
} from './pipes/timestamp.pipe';
import { RobotBatteryComponent } from './ui-components/robot-battery/robot-battery.component';
import { SnackBarComponent } from './ui-components/snack-bar/snack-bar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FuseCardModule } from '@fuse/components/card';
import { CardJobComponent } from './ui-components/card-job/card-job.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { FormSelectComponent } from './ui-components/form-select/form-select.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MiniLayoutMapComponent } from './ui-components/mini-layout-map/mini-layout-map.component';
import { FuseAlertModule } from '@fuse/components/alert';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { RadioButtonComponent } from './ui-components/radio-button/radio-button.component';
import { TextInputComponent } from './ui-components/text-input/text-input.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SlideToggleComponent } from './ui-components/slide-toggle/slide-toggle.component';
import { FormInputSelectComponent } from './ui-components/form-input-select/form-input-select.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HeaderTitleComponent } from './layouts/top-header/top-header.component';
import {
  RobotDescriptionPipe,
  RobotJobStatusPipe,
} from './pipes/robot-job-status.pipe';
import { RmRepeatComponent } from './ui-components/rm-repeat/rm-repeat.component';
import { rmDigitOnly } from './directives/digit-only.directive';
import { IncomingCallComponent } from './ui-components/incoming-call/incoming-call.component';
import { RobotBatteryTextComponent } from './ui-components/robot-battery-text/robot-battery-text.component';
import { FullscreenToolComponent } from './ui-components/fullscreen-tool/fullscreen-tool.component';
import {
  RobotStatusPipe,
  RobotStatusPipesModule,
} from './pipes/robot-status.pipe';
import { KeycapComponent } from './ui-components/keycap/keycap.component';
import { CardRobotSelectionComponent } from './ui-components/card-robot-selection/card-robot-selection.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RemoveDateTimePipeModule } from './pipes/jobname.pipe';
import { RmAutoCompleteComponent } from './ui-components/rm-auto-complete/rm-auto-complete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RmSelectStartJobTypeComponent } from './ui-components/rm-select-start-job-type/rm-select-start-job-type.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { RobotStatusComponent } from './ui-components/robot-status/robot-status.component';
import { RobotDetailBannerComponent } from './ui-components/robot-detail-banner/robot-detail-banner.component';
import { MalfunctionCardToastComponent } from './ui-components/malfunction-card-toast/malfunction-card-toast.component';
import { MalfunctionIssueDialogComponent } from './ui-components/malfunction-card-toast/issue-dialog/issue-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { QueueBarModule } from 'ngx-mat-queue-bar';
import { GlobalIncomingCall } from './ui-components/global-incomingcall/global-incomingcall.component';
import { ConfirmationComponent } from './ui-components/route-confirm-dialog/confirmation.component';
import { CheckSkillPipeModule } from './pipes/check-skill.pipe';
import { LowBatteryToastComponent } from './ui-components/low-battery-toast/low-battery-toast.component';
import { EmptyTableComponent } from './ui-components/empty-table/empty-table.component';
import { LoadingSpinnerComponent } from './ui-components/loading-spinner/loading-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormAutoCompleteComponent } from './ui-components/form-auto-complete/form-auto-complete';
import { JobFailedToastComponent } from './ui-components/job-failed-toast/job-failed.component';
import { ToastContainerComponent } from './ui-components/toast-container/toast-container.component';
import { RobotArrivedToastComponent } from './ui-components/robot-arrived-toast/robot-arrived.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { RmBadgeComponent } from './ui-components/rm-badge/rm-badge.component';
import { RmRemarksComponent } from './ui-components/rm-remarks/rm-remarks.component';
import { PlusMorePipe } from './pipes/plus-more.pipe';
import { ShortenedUuidPipe } from './pipes/shortened-uuid.pipe';
import { PrettifyPrintPipe } from './pipes/pretify-string.pipe';
import { NoRobotAvailableComponent } from './ui-components/no-robot-available/no-robot-available.component';
import { RobotListItemComponent } from './ui-components/robot-list-item/robot-list-item.component';
@NgModule({
  declarations: [
    ZoomToolComponent,
    DetectionPanelComponent,
    CardDetectionComponent,
    CardStatusSmallComponent,
    MapQuickNavigationComponent,
    RobotBatteryComponent,
    RobotLastOnline,
    NoDetectionComponent,
    TimestampPipe,
    SecondToTimePipe,
    DateToTimestampAgoPipe,
    FormatDatePipe,
    RobotSignalComponent,
    SnackBarComponent,
    CardJobComponent,
    FormSelectComponent,
    MiniLayoutMapComponent,
    RadioButtonComponent,
    TextInputComponent,
    SlideToggleComponent,
    FormInputSelectComponent,
    FormBoxedRadioButtonComponent,
    FormTextareaComponent,
    InputFileDropzoneComponent,
    FileSizePipe,
    HeaderTitleComponent,
    RobotJobStatusPipe,
    SafeUrlPipe,
    RobotDescriptionPipe,
    RmRepeatComponent,
    rmDigitOnly,
    RobotBatteryTextComponent,
    IncomingCallComponent,
    FullscreenToolComponent,
    KeycapComponent,
    CardRobotSelectionComponent,
    RmAutoCompleteComponent,
    FormAutoCompleteComponent,
    RmSelectStartJobTypeComponent,
    RobotStatusComponent,
    RobotDetailBannerComponent,
    MalfunctionCardToastComponent,
    MalfunctionIssueDialogComponent,
    GlobalIncomingCall,
    ConfirmationComponent,
    LowBatteryToastComponent,
    EmptyTableComponent,
    LoadingSpinnerComponent,
    JobFailedToastComponent,
    ToastContainerComponent,
    RobotArrivedToastComponent,
    RmBadgeComponent,
    RmRemarksComponent,
    PlusMorePipe,
    ShortenedUuidPipe,
    PrettifyPrintPipe,
    NoRobotAvailableComponent,
    RobotListItemComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    MatIconModule,
    MatSliderModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatMenuModule,
    MatDividerModule,
    NgxDropzoneModule,
    MatButtonModule,
    FuseCardModule,
    FuseAlertModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatInputModule,
    CronsPipesModule,
    ScrollingModule,
    MatDialogModule,
    RobotStatusPipesModule,
    MatTabsModule,
    RemoveDateTimePipeModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTableModule,
    MatPaginatorModule,
    QueueBarModule,
    CheckSkillPipeModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DetectionPanelComponent,
    ZoomToolComponent,
    CardDetectionComponent,
    CardStatusSmallComponent,
    MapQuickNavigationComponent,
    RobotBatteryComponent,
    RobotLastOnline,
    TimestampPipe,
    SecondToTimePipe,
    DateToTimestampAgoPipe,
    FormatDatePipe,
    RobotSignalComponent,
    SnackBarComponent,
    CardJobComponent,
    FormSelectComponent,
    MiniLayoutMapComponent,
    MatCheckboxModule,
    RadioButtonComponent,
    TextInputComponent,
    SlideToggleComponent,
    CronsPipesModule,
    FormInputSelectComponent,
    FormBoxedRadioButtonComponent,
    FormTextareaComponent,
    InputFileDropzoneComponent,
    FileSizePipe,
    HeaderTitleComponent,
    RobotJobStatusPipe,
    SafeUrlPipe,
    RobotDescriptionPipe,
    RmRepeatComponent,
    rmDigitOnly,
    IncomingCallComponent,
    RobotBatteryTextComponent,
    FullscreenToolComponent,
    KeycapComponent,
    RobotStatusPipesModule,
    CardRobotSelectionComponent,
    RobotStatusPipe,
    MatTabsModule,
    ScrollingModule,
    RmAutoCompleteComponent,
    FormAutoCompleteComponent,
    RmSelectStartJobTypeComponent,
    RobotStatusComponent,
    RobotDetailBannerComponent,
    MalfunctionCardToastComponent,
    MalfunctionIssueDialogComponent,
    LowBatteryToastComponent,
    EmptyTableComponent,
    LoadingSpinnerComponent,
    JobFailedToastComponent,
    RobotArrivedToastComponent,
    ToastContainerComponent,
    RmBadgeComponent,
    RmRemarksComponent,
    PlusMorePipe,
    ShortenedUuidPipe,
    PrettifyPrintPipe,
    NoRobotAvailableComponent,
    RobotListItemComponent,
  ],
  providers: [
    DatePipe,
    RobotJobStatusPipe,
    RobotStatusPipe,
    SafeUrlPipe,
    PrettifyPrintPipe,
  ],
})
export class SharedModule {}
