import { EventEmitter } from '@angular/core';
import { Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['top-header.component.scss'],
})
export class HeaderTitleComponent implements OnInit {
  @Input() title: string;
  @Output() onTitleClick = new EventEmitter();

  /**
   * Constructor
   */
  constructor() {}
  ngOnInit() {}

  onClick() {
    this.onTitleClick.emit();
  }
}
