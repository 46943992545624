import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiBase,
  ResponseWithoutList,
  Camera,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiCameras extends ApiBase<any> {
  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = '/api/v2/camera';
  }

  getByRobotId(id): Observable<ResponseWithoutList<Camera>> {
    return this.get(`camera/list/${id}`);
  }
}
