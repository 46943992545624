import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'shared-keycap',
  template: `<div
    [class]="
      'w-8 h-8 border-2 rounded mr-[2px]' +
      ' ' +
      extraClass +
      ' ' +
      classWithLetter
    "
    [ngClass]="{
      'border-neutral-100': letter === null && icon === null
    }"
  >
    <div *ngIf="letter && icon === null">
      {{ letter }}
    </div>
    <mat-icon
      *ngIf="letter === null && icon"
      class="text-white"
      [svgIcon]="icon"
    ></mat-icon>
  </div>`,
})
export class KeycapComponent implements OnInit {
  @Input() letter: string = null;
  @Input() icon: string = null;
  @Input() extraClass: string = null;

  classWithLetter: string = null;

  ngOnInit(): void {
    if (this.letter || this.icon) {
      this.classWithLetter = ' flex items-center justify-center uppercase';
    }
  }
}
