import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RobotDetail } from 'app/modules/dashboard/robot-detail/robot-detail.component';
import { ApiEventDetection } from 'app/services/event.service';
import { Robot } from 'rm-api-services/dist/api-services';
import { MalfunctionIssueDialogComponent } from '../malfunction-card-toast/issue-dialog/issue-dialog.component';

@Component({
  selector: 'robot-detail-banner',
  styles: [
    `
      .robot-detail-banner-icon {
        scale: 0.75;
        transform: scaleX(-1);
      }
    `,
  ],
  template: `<div
    class="flex w-full items-center justify-between py-3 px-5 bg-error-700 text-white cursor-pointer"
    (click)="onCLickBanner()"
  >
    <div class="flex flex-row">
      <mat-icon
        [svgIcon]="'mat_solid:' + icon"
        class="robot-detail-banner-icon mr-2 text-white"
      ></mat-icon>

      <span class="text-white text-base font-semibold">{{ description }}</span>
    </div>
    <mat-icon
      [svgIcon]="'mat_solid:chevron_right'"
      class="text-white icon-size-[18px]"
    ></mat-icon>
  </div>`,
})
export class RobotDetailBannerComponent implements OnChanges, OnInit {
  @Input() isMalfunctioning: boolean;
  @Input() robotEventCount: number;
  @Input() selectedRobotData: RobotDetail;

  description: string;
  icon: string = '';

  constructor(private dialog: MatDialog, private apiEvent: ApiEventDetection) {}

  ngOnInit(): void {
    //assign the icon and description value
    this.assignData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    //check if there are changes in robot state to malfunctioning
    if (changes.isMalfunctioning) {
      this.assignData();
    }

    //check if there are change in robot eventCount of event detection
    if (changes.robotEventCount) {
      this.assignData();
    }
  }

  assignData(): void {
    if (this.isMalfunctioning) {
      this.icon = 'build';
      this.description = 'Robot is malfunctioning';
    } else {
      if (this.robotEventCount > 0) {
        this.icon = 'warning';
        if (this.robotEventCount === 1) {
          this.description = '1 detection';
        } else {
          this.description = `${this.robotEventCount} detections`;
        }
      }
    }
  }

  onCLickBanner(): void {
    if (this.isMalfunctioning) {
      const robotIds: string[] = [];
      robotIds.push(this.selectedRobotData.id);
      this.dialog.open(MalfunctionIssueDialogComponent, {
        width: '40vw',
        panelClass: 'rm-dialog',
        data: robotIds,
      });
    } else {
      this.apiEvent.filterEventBySelectedRobot.next(this.selectedRobotData);
    }
  }
}
