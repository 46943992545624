import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'text-input',
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    `,
  ],
  template: `<section class="text-input">
    <label
      *ngIf="label"
      for="first_name"
      class="block mb-2 text-gray-900 dark:text-white"
      >{{ label }}</label
    >
    <input
      class="bg-neutral-700 border-[1px] border-neutral-400 text-md rounded h-11 px-5 w-full"
      [ngClass]="{
        'border-[#dc2626]':
          controlName && controlName?.invalid && controlName?.touched,
        'border-neutral-400': !(
          controlName &&
          controlName?.invalid &&
          controlName?.touched
        )
      }"
      [type]="type"
      [placeholder]="placeholder"
      [min]="min"
      [formControl]="controlName ? controlName : ''"
      value="{{ value }}"
      [(ngModel)]="value"
      (input)="handleValueChange($event.target.value)"
    />
    <!-- form not valid -->
    <div
      *ngIf="controlName && controlName?.invalid && controlName?.touched"
      class="text-[#dc2626] mt-px"
    >
      <div *ngIf="controlName?.errors" class="flex items-center">
        <mat-icon
          class="scale-75 text-error-300"
          svgIcon="mat_solid:warning"
        ></mat-icon>
        <!-- Error required input -->
        <ng-container *ngIf="controlName?.errors?.required">
          This field is required
        </ng-container>
        <!-- Error invalid minimum value -->
        <ng-container *ngIf="controlName?.errors?.min">
          Minimum value is {{ min }}
        </ng-container>
        <!-- Error invalid phone number format -->
        <ng-container
          *ngIf="
            controlName?.errors?.phoneNumberValidator &&
            !controlName?.errors?.required
          "
        >
          Invalid phone number
        </ng-container>
        <!-- Error invalid input -->
        <ng-container
          *ngIf="controlName?.errors?.invalid && !controlName?.errors?.required"
        >
          Invalid {{ label }}
        </ng-container>
      </div>
    </div>
  </section>`,
})

/**
 * TODO: broadcastState and blinkState currently is not in our system, will be updated soon
 */
export class TextInputComponent implements OnInit {
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() value: string;
  @Input() type: string;
  @Input() min: number;
  @Input() controlName?: FormControl;
  @Output() valueChange? = new EventEmitter<string>();

  ngOnInit(): void {}

  public handleValueChange(text: string): void {
    this.valueChange.emit(text);
  }
}
