import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Skill } from 'rm-api-services/dist/api-services';

type SkillArray = Skill[] | string[];

@Pipe({
  name: 'checkSkill',
})
export class CheckSkillPipe implements PipeTransform {
  transform(skills: SkillArray, skillName: string): boolean {
    if (!skills || !skillName) {
      return false;
    }

    // make it flexible to accept both Skill[] and string[]
    return skills.some((skill) =>
      typeof skill === 'string'
        ? skill.toLowerCase().includes(skillName.toLowerCase())
        : skill.name.toLowerCase().includes(skillName.toLowerCase())
    );
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [CheckSkillPipe],
  exports: [CheckSkillPipe],
  providers: [CheckSkillPipe],
})
export class CheckSkillPipeModule {}
