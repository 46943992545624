import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'shared-form-input-select',
  template: ` <div class="w-full">
    <mat-label *ngIf="formLabel">{{ formLabel }}</mat-label>
    <div class="flex flex-row gap-0 ">
      <div class="inputForm ">
        <mat-form-field>
          <input
            matInput
            type="number"
            [formControl]="inputControl"
            min="0"
            [placeholder]="inputPlaceholder"
          />
          <mat-error *ngIf="inputControl.hasError('required') && errorMsg">
            {{ errorMsg }}
          </mat-error>
          <mat-error *ngIf="inputControl.hasError('min') && errorMsg2">
            {{ errorMsg2 }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="selectForm">
        <mat-form-field>
          <mat-select [formControl]="selectedTypeControl">
            <mat-option *ngFor="let type of types" [value]="type">{{
              type
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>`,
  styles: [
    `
      .selectForm {
        ::ng-deep .mat-form-field.mat-form-field-appearance-fill {
          ::ng-deep .mat-form-field-wrapper {
            ::ng-deep .mat-form-field-flex {
              background-color: theme('colors.neutral.700') !important;
              border: 1px solid theme('colors.neutral.DEFAULT') !important;
              border-radius: 0px 4px 4px 0px !important;
            }
          }
        }
      }

      .inputForm {
        width: 100% !important;

        & ::ng-deep .mat-form-field {
          width: 100% !important;

          &.mat-form-field-appearance-fill {
            &::ng-deep .mat-form-field-wrapper {
              width: 100% !important;

              &::ng-deep .mat-form-field-flex {
                width: 100% !important;
                background-color: theme('colors.neutral.700') !important;
                border: 1px solid theme('colors.neutral.DEFAULT') !important;
                border-radius: 4px 0px 0px 4px !important;
              }
            }
          }
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputSelectComponent implements OnInit {
  @Input() inputPlaceholder: string;
  @Input() formLabel: string;
  @Input() errorMsg?: string = 'This field is required';
  @Input() errorMsg2?: string = 'The minimum value is 1';
  @Input() types: string[];
  @Input() inputControl: FormControl;
  @Input() selectedTypeControl: FormControl;

  constructor() {}
  ngOnInit(): void {}
}
