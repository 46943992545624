import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import {
  Layout,
  Mission,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { ApiMonitor } from './monitor.service';
import {
  Observable,
  Subject,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { ApiLayoutMap } from './layout.service';
import { NIL as NIL_UUID } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class UtilityService implements OnInit, OnDestroy {
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private _datePipe: DatePipe,
    private monitorService: ApiMonitor,
    private apiLayoutMap: ApiLayoutMap
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  private urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw Error('Illegal base64url string!');
      }
    }
    return this.b64DecodeUnicode(output);
  }

  private b64decode(str: string): string {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';

    str = String(str).replace(/=+$/, '');

    if (str.length % 4 === 1) {
      throw new Error(
        "'atob' failed: The string to be decoded is not correctly encoded."
      );
    }

    /* eslint-disable */
    for (
      // initialize result and counters
      let bc = 0, bs: any, buffer: any, idx = 0;
      // get next character
      (buffer = str.charAt(idx++));
      // character found in table? initialize bit storage and add its ascii value;
      ~buffer &&
      ((bs = bc % 4 ? bs * 64 + buffer : buffer),
      // and if not first of each 4 characters,
      // convert the first 8 bits to one ascii character
      bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    /* eslint-enable */

    return output;
  }

  private b64DecodeUnicode(str: any): string {
    return decodeURIComponent(
      Array.prototype.map
        .call(
          this.b64decode(str),
          (c: any) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        )
        .join('')
    );
  }

  public decodeToken(token: string): any {
    // Return if there is no token
    if (!token) {
      return null;
    }

    // Split the token
    const parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error(
        "The inspected token doesn't appear to be a JWT. Check to make sure it has three parts and see https://jwt.io for more."
      );
    }

    // Decode the token using the Base64 decoder
    const decoded = this.urlBase64Decode(parts[1]);

    if (!decoded) {
      throw new Error('Cannot decode the token.');
    }

    return JSON.parse(decoded);
  }

  public formatDate(date: string, format: boolean = false): string {
    try {
      if (format) {
        return `${this._datePipe.transform(date, 'HHmm')} hrs`;
      } else {
        // return `${this._datepipe.transform(date, 'HH:mm')}`;
        return `${this._datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss.SSSZ')}`;
      }
    } catch (err) {
      return date;
    }
  }

  // Format the skills name
  public loadingSkillNames(item: Mission) {
    let skillNameList = '';
    if (item.tasks && Array.isArray(item.tasks) && item.tasks.length > 0) {
      item.tasks.forEach((element) => {
        if (!element.skillName) return;

        let name = element.skillName?.includes('RM-')
          ? element.skillName.replace('RM-', '')
          : element.skillName;
        if (name?.toLocaleLowerCase().includes('patrol')) {
          name = 'Patrolling';
        }
        skillNameList = skillNameList ? skillNameList + ' & ' + name : name;
      });
      return skillNameList;
    }
    return skillNameList;
  }

  // workaround: render the traffic graph node as marker.
  // In Matar, to be less cluttered, we need to filter the traffic graph node
  // which name is longer than 3 characters.
  // e.g. 1, 2, 3, Toilet, Platform.
  // After the filter, the markers to be rendered will be: Toilet, Platform
  public canShowMarkerIconOnLayout(name: string): boolean {
    return name.length > 3;
  }

  /**
   * Get the video access token and sets the video access token to the cookie.
   *
   */
  public setVideoAccessTokenCookie(): void {
    this.monitorService
      .getVideoAccessToken()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        const domainName = this.getMainDomain();
        document.cookie = `vc_token=${res.result};domain=${domainName};path=/`;
      });
  }

  /**
   * Retrieves the main domain, if the domain is an IP address then return as it is,
   * otherwise return the last two parts of the domain which is the main domain.
   *
   * @return {string} The main domain.
   */
  public getMainDomain(): string {
    const domainName = location.hostname;
    const isIP = this.isIpAddress(domainName);

    if (isIP) {
      return domainName;
    }

    const parts = domainName.split('.');
    const partsLength = parts.length;

    // Check if the domain has subdomains (at least three parts)
    if (partsLength >= 3) {
      return `${parts[partsLength - 2]}.${parts[partsLength - 1]}`;
    } else {
      return domainName;
    }
  }

  /**
   * Determines if the given string is a valid IP address.
   *
   * @param {string} domain - The string to be checked.
   * @return {boolean} Returns true if the input is a valid IP address, otherwise false.
   */
  public isIpAddress(domain: string): boolean {
    // Regular expression to match IPv4 and IPv6 addresses
    const ipPattern =
      /^([0-9]{1,3}\.){3}[0-9]{1,3}$|^[0-9a-fA-F:]+:[0-9a-fA-F]{1,4}$/;

    return ipPattern.test(domain);
  }

  /**
   * Helper function to get detail of site
   * @param siteId
   * @returns
   */
  private getSiteDetail(siteId: string): Promise<Layout | null> {
    return new Promise((resolve) => {
      this.apiLayoutMap
        .getById(siteId, true)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((resp) => {
          if (resp.code === 200 && resp.result) {
            resolve(resp.result);
          } else {
            resolve(null);
          }
        });
    });
  }

  /**
   * Helper function to get location name.
   * If the location in the site, it will get
   * the site name using the parentId in the layout data.
   * Then show the site name along side the layout name
   */
  getLocationName(layoutId: string): Observable<string> {
    // get the layout detail first. if the parentId
    // is not null UUID, it will get the site detail
    // then combine remap the result into one observable and
    // return as the location name
    if (!layoutId) return of(null);
    return this.apiLayoutMap.getById(layoutId, true).pipe(
      switchMap((resp: ResponseOne<Layout>) => {
        let site$: Observable<Layout> = of(null);
        if (resp.result && resp.result.parentId !== NIL_UUID) {
          site$ = from(this.getSiteDetail(resp.result.parentId));
        }

        return forkJoin([of(resp.result), site$]);
      }),
      map(([layout, site]) => {
        if (site) {
          return `${site.name}, ${layout.name}`;
        }
        return layout.name;
      })
    );
  }
}
