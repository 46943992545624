<div class="flex flex-col w-full" *ngFor="let layout of layoutWithRobots">
  <ng-container *ngIf="layout.robots.length > 0">
    <!-- Layout Header -->
    <div
      class="mb-2 px-6 py-2 bg-neutral"
      [ngClass]="{
        '-mx-6': cardSize === 'big',
        'mt-6': cardSize === 'big',
        '-mx-4': cardSize === 'small',
        'mt-4': cardSize === 'small'
      }"
    >
      <p class="uppercase text-sm font-bold text-neutral-100">
        {{ layout.layoutName }}
      </p>
    </div>
    <!-- Robot List with Check Box -->
    <ng-container *ngIf="selectionType === 'checkbox'">
      <div
        class="flex justify-between items-center mb-2 rounded-lg bg-neutral-650"
        [ngClass]="{
          'py-5': cardSize === 'big',
          'px-4': cardSize === 'big',
          'p-4': cardSize === 'small',
          border: cardSize === 'small',
          'border-neutral-400': cardSize === 'small'
        }"
        *ngFor="let robot of layout.robots"
      >
        <mat-checkbox
          [checked]="true"
          [disableRipple]="true"
          (change)="handleRobotSelection(robot.id)"
          class="layout-layer-filter"
        >
          <div
            class="flex flex-col items-start justify-center"
            [ngClass]="{
              'ml-2': cardSize === 'big',
              'ml-1.5': cardSize === 'small'
            }"
          >
            <span class="text-base font-semibold text-neutral-50">
              {{ robot.name }}
            </span>
            <ng-container *ngIf="cardSize === 'small'">
              <!-- Robot Status -->
              <span class="-ml-1.5">
                <robot-status [robot]="robot"></robot-status>
              </span>
            </ng-container>
          </div>
        </mat-checkbox>
        <!--  -->
        <ng-container
          *ngTemplateOutlet="robotStatusAndLocation; context: { robot }"
        >
        </ng-container>
      </div>
    </ng-container>

    <!-- Robot list with Radio -->
    <ng-container *ngIf="selectionType === 'radio'">
      <mat-radio-group
        *ngIf="selectionType === 'radio'"
        aria-labelledby="shared-form-boxed-radio-button"
        class="boxed-radio-button-selection"
      >
        <mat-radio-button
          *ngFor="let robot of layout.robots"
          class="flex justify-between items-center mb-2 rounded-lg bg-neutral-650 robot-list-radio-button"
          [ngClass]="{
            'py-5': cardSize === 'big',
            'px-4': cardSize === 'big',
            'p-4': cardSize === 'small',
            border: cardSize === 'small',
            'border-neutral-400': cardSize === 'small'
          }"
          [value]="robot.id"
          (change)="handleRobotSelection($event.value)"
        >
          <div
            class="flex items-center justify-between"
            [ngClass]="{
              'flex-col': cardSize === 'small'
            }"
          >
            <span class="text-base font-semibold text-neutral-50">
              {{ robot.name }}
            </span>
            <ng-container *ngIf="cardSize === 'small'">
              <!-- Robot Status -->
              <span class="-ml-1.5">
                <robot-status [robot]="robot"></robot-status>
              </span>
            </ng-container>
            <ng-container
              *ngTemplateOutlet="robotStatusAndLocation; context: { robot }"
            >
            </ng-container>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </ng-container>
</div>

<ng-template #robotStatusAndLocation let-robot="robot">
  <div
    class="flex items-center justify-between"
    [ngClass]="{ 'min-w-[185px]': cardSize === 'big' }"
  >
    <ng-container *ngIf="cardSize === 'big'">
      <!-- Robot Status -->
      <robot-status [robot]="robot"></robot-status>
    </ng-container>
    <!-- Find Robot Location button -->
    <button
      class="flex items-center justify-center w-8 h-8 bg-transparent hover:bg-neutral-400 p-2 rounded ml-4"
      #tooltip="matTooltip"
      matTooltip="See in map"
      matTooltipPosition="below"
      matTooltipClass="{{
        cardSize === 'big'
          ? 'multiple-robot-job-tooltip'
          : 'multiple-robot-create-job-tooltip'
      }}"
      (click)="showRobotOnMap(robot)"
    >
      <mat-icon svgIcon="feather:crosshair" class="icon-size-4"></mat-icon>
    </button>
  </div>
</ng-template>
