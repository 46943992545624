<!-- display if the current map is GIS -->
<div
  class="flex flex-col bg-neutral-700 h-16 w-auto max-w-90 opacity-90 absolute rounded-md top-4 left-7 px-5 py-2"
  [ngClass]="{ 'z-999': !isJobDrawerOpened, 'z-[200]': isJobDrawerOpened }"
  *ngIf="!layoutId; else layoutWithSite"
>
  <div class="text-neutral-100 text-sm">Currently viewing</div>
  <div class="flex flex-row">
    <mat-icon svgIcon="mat_outline:map" class="text-primary-400"></mat-icon>
    <span class="text-white text-lg font-semibold pl-3"> Street map</span>
  </div>
</div>
<!-- display if the current map is a SITE -->
<ng-template #layoutWithSite>
  <div *ngIf="layoutId && layoutType === 2; else layoutWithoutSite">
    <button
      *ngIf="!isMultipleRobotJobDrawerOpen"
      class="bg-neutral-700 h-16 w-17 opacity-90 absolute rounded-md top-4 left-7 px-5 py-2"
      [ngClass]="{ 'z-999': !isJobDrawerOpened, 'z-[200]': isJobDrawerOpened }"
      (click)="goBack()"
    >
      <mat-icon svgIcon="mat_outline:arrow_back" class="mt-1"></mat-icon>
    </button>
    <ng-container *ngIf="layoutId !== nilUuid">
      <div
        class="flex flex-col w-auto max-w-90 absolute top-4 mr-20"
        [ngClass]="{
          'z-999': !isJobDrawerOpened,
          'z-[200]': isJobDrawerOpened,
          'left-7': isMultipleRobotJobDrawerOpen,
          'left-26': !isMultipleRobotJobDrawerOpen
        }"
      >
        <div
          class="flex flex-row bg-neutral-700 h-16 w-full opacity-90 rounded-md px-5 py-2"
        >
          <mat-icon
            svgIcon="heroicons_solid:office-building"
            class="text-primary-400 mt-3"
          ></mat-icon>

          <div class="flex flex-col grow ml-3">
            <span class="text-neutral-100 text-sm">{{ siteName }}</span>
            <span class="text-white text-lg font-semibold">{{
              layoutName
            }}</span>
          </div>
          <ng-container
            *ngIf="
              selectedJobType !== 'patrol' && selectedJobType !== 'group-patrol'
            "
          >
            <button
              class="bg-neutral-700"
              (click)="isLayoutlistShown = !isLayoutlistShown"
            >
              <mat-icon
                [svgIcon]="
                  isLayoutlistShown
                    ? 'mat_outline:keyboard_arrow_up'
                    : 'mat_outline:keyboard_arrow_down'
                "
                class="mt-1"
              ></mat-icon>
            </button>
          </ng-container>
        </div>

        <!-- Dropdown select layout -->
        <div
          *ngIf="isLayoutlistShown"
          class="flex flex-col gap-3 bg-neutral-700 w-full opacity-90 rounded-md px-5 py-3 mt-1"
        >
          <button
            *ngFor="let layout of layoutList"
            class="flex w-full items-center"
            [ngClass]="{
              'text-white': layoutId !== layout.id,
              'text-primary-400': layoutId === layout.id
            }"
            (click)="selectLayout(layout.id)"
          >
            {{ layout.name }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
<!-- display if the current map is a Layout only -->
<ng-template #layoutWithoutSite>
  <button
    *ngIf="!isMultipleRobotJobDrawerOpen"
    class="bg-neutral-700 h-16 w-17 opacity-90 absolute rounded-md top-4 left-7 px-5 py-2"
    [ngClass]="{ 'z-999': !isJobDrawerOpened, 'z-[200]': isJobDrawerOpened }"
    (click)="goBack()"
  >
    <mat-icon svgIcon="mat_outline:arrow_back" class="mt-1"></mat-icon>
  </button>
  <ng-container *ngIf="layoutId !== nilUuid">
    <div
      class="flex flex-row bg-neutral-700 h-16 w-72 opacity-90 absolute rounded-md top-4 px-5 py-2 mr-20"
      [ngClass]="{
        'z-999': !isJobDrawerOpened,
        'z-[200]': isJobDrawerOpened,
        'left-7': isMultipleRobotJobDrawerOpen,
        'left-26': !isMultipleRobotJobDrawerOpen
      }"
    >
      <mat-icon
        svgIcon="heroicons_solid:office-building"
        class="text-primary-400 mt-3"
      ></mat-icon>

      <div class="flex flex-col ml-3">
        <span class="text-neutral-100 text-sm">Currently viewing</span>
        <span class="text-white text-lg font-semibold">{{ layoutName }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>
