import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { ToastData } from 'app/modules/dashboard/layout-map/layout-map.service';
import { ApiLayoutMap } from 'app/services/layout.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import { ApiRobot, Robot, Skill } from 'rm-api-services/dist/api-services';

@Component({
  selector: 'app-low-battery-toast',
  templateUrl: './low-battery-toast.component.html',
  styleUrls: ['./low-battery-toast.component.scss'],
})
export class LowBatteryToastComponent implements OnInit {
  items: RobotBattery[] = [];
  @Input() data: ToastData;
  @Input() isMalfunction: boolean = false;
  @Input() isInGroupPatrol: boolean = false;
  @Output() rellocateRobot = new EventEmitter<string>();

  private robotSkills: Skill[] = [];
  constructor(
    private dashboardService: DashboardService,
    private apiLayoutMap: ApiLayoutMap,
    private apiRobot: ApiRobot,
    private snackBar: SnackBarService
  ) {}

  ngOnInit(): void {
    this.apiRobot.getRobotSkills(this.data.data.id).subscribe((res) => {
      this.robotSkills = res.result;
    });
  }

  sendToChargingStation(robot: Robot): void {
    // open robot detail dialog
    if (robot) {
      this.dashboardService.selectedRobotId$.next(robot.id);
      if (this.isInGroupPatrol) {
        this.rellocateRobot.emit(robot.id);
      } else {
        // search for charging skill in robotSkills
        const chargingSkill = this.robotSkills.find(
          (skill) => skill.name === 'RM-GO-CHARGE'
        );
        if (chargingSkill) {
          this.onCreateNewJob(chargingSkill, robot, 'charge');
        } else {
          this.snackBar.openSnackBar({
            message: 'Charging skill not found',
            type: 'failed',
          });
        }
      }
    }
  }

  onCreateNewJob(skill: Skill, robot: Robot, jobType: string = 'charge'): void {
    this.dashboardService.selectedTypeCreateJob$.next('specific-robot');
    this.dashboardService.selectedSkill$.next(skill);
    this.dashboardService.selectedJobType$.next(jobType);
    this.dashboardService.selectedRobotId$.next(robot.id);
    this.apiLayoutMap.selectedLayoutId$.next(robot.layoutId);
  }
}

interface RobotBattery {
  id: string;
  name: string;
  percentage?: number;
}
