import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Inject,
} from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent implements OnInit {
  status: string = 'info';
  message: string;
  constructor(
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      type?: 'success' | 'warning' | 'failed' | 'info';
      message: string;
      preClose?: () => void;
    }
  ) {
    this.message = data.message;
    this.status = data.type;
  }

  ngOnInit() {}

  dismiss(): void {
    this.data.preClose();
  }
}
