<div
  class="flex flex-col absolute items-center justify-center bottom-4 z-999"
  [ngClass]="isMinimap ? 'right-4' : 'right-7'"
>
  <button
    class="cursor-pointer bg-neutral hover:bg-neutral-400 flex text-center items-center justify-center align-middle rounded-sm border border-slate-600"
    [ngClass]="isMinimap ? 'w-7 h-7' : 'w-9 h-9'"
    (click)="onZoomIn()"
  >
    <mat-icon class="text-xl text-white" svgIcon="mat_solid:add"></mat-icon>
  </button>
  <ng-container *ngIf="isUseSlider">
    <mat-slider
      class="zoom-slider"
      [max]="maxZoom"
      [min]="minZoom"
      [step]="zoomStep"
      [thumbLabel]="false"
      [vertical]="true"
      [value]="zoomValue"
      (input)="sliderChange($event)"
    >
    </mat-slider>
  </ng-container>
  <button
    class="cursor-pointer bg-neutral hover:bg-neutral-400 flex text-center items-center justify-center align-middle rounded-sm border border-slate-600"
    [ngClass]="isMinimap ? 'w-7 h-7' : 'w-9 h-9'"
    (click)="onZoomOut()"
  >
    <mat-icon class="text-base text-white" svgIcon="mat_solid:remove">
    </mat-icon>
  </button>
</div>
