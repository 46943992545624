import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-replace-current-job',
  templateUrl: './modal-replace-current-job.component.html',
  styleUrls: ['./modal-replace-current-job.component.scss'],
})
export class ModalReplaceCurrentJobComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data,
    public dialogRef: MatDialogRef<ModalReplaceCurrentJobComponent>
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close('confirm');
  }
}
