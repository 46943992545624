import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-status-small',
  templateUrl: './card-status-small.component.html',
  styleUrls: ['./card-status-small.component.scss'],
})
export class CardStatusSmallComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() headerStatus: string;
  @Input() content: string;
  @Input() layoutId: string;
  @Input() robotId: string;
  @Input() layoutName: string;
  @Input() status: number;
  @Input() lastOnline: string;
  @Input() isContentIcon: boolean;
  @Input() contentIcon: string;
  @Input() contentIconType: string;
  @Input() isMalfunction: boolean = false;
  @Output() openRobotDetail = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  openRobotDetailPanel() {
    this.openRobotDetail.emit(this.robotId);
  }
}
