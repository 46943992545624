import { Robot, ResponseOne } from 'rm-api-services/dist/api-services';
import { ApiRobot } from 'api-services';
import { BehaviorSubject, Observable, tap, map } from 'rxjs';
import {
  IncomingCallComponent,
  CallPayload,
} from './../shared/ui-components/incoming-call/incoming-call.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable, OnDestroy } from '@angular/core';
import { GlobalIncomingCall } from './../shared/ui-components/global-incomingcall/global-incomingcall.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CallRobot } from 'app/modules/teleoperations/teleconference/teleconference.component';
import { v4 as uuidv4 } from 'uuid';
import { AuthService, publishTopics } from 'app/core/auth/auth.service';
import { QueueBarService } from 'ngx-mat-queue-bar';
import { QueueBarRef } from 'ngx-mat-queue-bar/lib/queue-bar-ref';

@Injectable({
  providedIn: 'root',
})
export class CallService implements OnDestroy {
  private robotDataSubject = new BehaviorSubject<Robot>(null);
  private queueBarRef: QueueBarRef<any>;
  queueArray = {};
  queueDialogArray = {};
  audio = undefined;
  channel: BroadcastChannel = new BroadcastChannel('stop-ringtone-channel');

  constructor(
    private dialog: MatDialog,
    private apiRobot: ApiRobot,
    public snackBar: MatSnackBar,
    private _authSvc: AuthService,
    private _queueBar: QueueBarService
  ) {}

  openCallModal(data: CallPayload): void {
    this.getRobotData(data.robotId).subscribe((robotData: Robot) => {
      const robotName = robotData.name;
      const newData = {
        name: robotName,
        ...data,
      };
      // dont open modal if it is already opened for same robot
      if (this.dialog.openDialogs.length > 0) {
        const dialogRef = this.dialog.openDialogs[0];

        if (dialogRef.componentInstance.data.robotId === data.robotId) {
          return;
        }
      }

      this.dialog.open(IncomingCallComponent, {
        // width: '320px',
        panelClass: 'call-modal',
        data: newData,
      });
      this.queueDialogArray[data.robotId] = this.dialog.openDialogs[0];
    });
  }

  closeCallModal() {
    this.dialog.closeAll();
  }

  closeCallModalByRobotId(robotId: string) {
    if (this.queueDialogArray[robotId]) {
      console.log('closeCallModalByRobotId', robotId);
      this.queueDialogArray[robotId].close();
    }
  }

  ngOnDestroy() {
    this.channel.close();
  }

  getRobotData(robotId: string): Observable<Robot> {
    const robotData = this.robotDataSubject.value;

    if (robotData && robotData.id === robotId) {
      return this.robotDataSubject.asObservable();
    }

    return this.apiRobot.getRobotDetails(robotId).pipe(
      tap((res: ResponseOne<Robot>) => {
        this.robotDataSubject.next(res.result);
      }),
      map((res: ResponseOne<Robot>) => res.result)
    );
  }

  setRobotData(robotData: Robot): void {
    this.robotDataSubject.next(robotData);
  }

  openGlobalCall(data: CallRobot) {
    this.getRobotData(data.robotId).subscribe((robotData: Robot) => {
      const robotName = robotData.name;
      const newData = {
        name: robotName,
        ...data,
      };

      this.queueBarRef = this._queueBar.openFromComponent(GlobalIncomingCall, {
        duration: 30000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
        data: newData,
      });
      this.queueBarRef.afterDismissed().subscribe(() => {
        this.dismissCall(data.robotId);
      });
      console.log('temp ref ', this.queueBarRef);
      this.queueArray[data.robotId] = this.queueBarRef;
    });
  }

  handleCall(call: CallRobot, id: string) {
    // if the robot is in another page
    if (id !== call.robotId) {
      this.handleCallinanotherPage(call);
    }
  }

  handleCallinanotherPage(call) {
    switch (call.action) {
      case 1:
        if (this.queueArray[call.robotId]) {
          // if multiple calls coming from the robot, remove the older one and add the lateest to the queue
          this.dismissCall(call.robotId);
          setTimeout(() => {
            this.openGlobalCall(call);
          }, 100);
        } else {
          this.openGlobalCall(call);
        }
        break;
      case 2: // when the incoming call ended from robot side
        localStorage.setItem('answeredCall', null);
        this.dismissCall(call.robotId);
        break;
      case 4: // when the call answered, remove the call alert
        this.dismissCall(call.robotId);
        break;
      default:
        console.error('Unknown robot call action: ', call.action);
        break;
    }
  }

  declineCall(call) {
    const timestamp = new Date().getTime();
    const message: CallRobot = {
      id: uuidv4(),
      timestamp: timestamp,
      robotId: call.robotId,
      companyId: this._authSvc.companyId,
      content: call?.content,
      action: 2,
      callId: call?.callId,
    };
    const topicToPublish = publishTopics.callRobot + this._authSvc.companyId;
    this._authSvc.client.unsafePublish(topicToPublish, JSON.stringify(message));
  }

  dismissCall(id) {
    const currentRef = this.queueArray[id];
    currentRef?.dismiss();
    delete this.queueArray[id];

    if (Object.keys(this.queueArray).length === 0) {
      this.stopRingTone(true);
    }
    this.closeCallModalByRobotId(id);
  }

  dismissAllcalls() {
    this.stopRingTone(true);
    // dismiss all snackbars
    for (const [key, value] of Object.entries(this.queueArray)) {
      this.dismissCall(key);
    }
    this.queueArray = {};
  }

  playRingtone() {
    if (this.audio) {
      this.stopRingTone();
    }

    // if there is already a call, dont play the ringtone
    if (Object.keys(this.queueArray).length > 0) {
      return;
    }
    const audio = new Audio();
    this.audio = audio;
    this.audio.src = 'assets/audio/teleconference/call-ringtone.mp3';
    this.audio.load();
    let resp = this.audio.play();
    if (resp !== undefined) {
      resp
        .then((_) => {
          // autoplay starts!
          console.log('auto play starts');
        })
        .catch((error) => {
          //show error
          console.error(error);
        });
    }
    this.audio.loop = true;
  }

  stopRingTone(stopNow?: boolean): void {
    if (this.audio === undefined) {
      return;
    }
    //if there is no call, stop the ringtone
    if (Object.keys(this.queueArray).length === 0 || stopNow) {
      console.log('stoped ringtone');
      // Broadcast a message to all browser tabs to stop the ringtone
      this.channel.postMessage('stop');
      // stop the ringtone
      this.audio.loop = false;
      this.audio.pause();
      this.audio = undefined;
    }
  }
}
