import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'app/app.service';
import {
  DashboardService,
  MonitoringRobot,
} from 'app/modules/dashboard/dashboard.service';
import { MapLibreService } from 'app/modules/dashboard/gis-map/services/map-libre.service';
import { LayoutMapService } from 'app/modules/dashboard/layout-map/layout-map.service';
import { Layout, Location } from 'rm-api-services/dist/api-services';
import {
  combineLatest,
  map,
  Observable,
  startWith,
  Subject,
  Subscription,
} from 'rxjs';
import { NIL as NIL_UUID } from 'uuid';

export interface SearchGroup {
  category: string;
  list: SearchGroupList[];
}

interface Point {
  name?: string;
  x: number;
  y: number;
  z: number;
}

interface SearchGroupList {
  id: string;
  name: string;
  location: Location;
  type: 'robot' | 'layout';
  layoutId?: string;
  siteId?: string;
  point?: Point;
}

export const _filter = (
  opt: SearchGroupList[],
  value: string
): SearchGroupList[] => {
  const filterValue = value.toString().toLowerCase();

  return opt.filter((item) => item.name.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'monitoring-search',
  styleUrls: ['./monitoring-search.component.scss'],
  templateUrl: './monitoring-search.component.html',
})
export class MonitoringSearchComponent implements OnInit, OnDestroy {
  robots: MonitoringRobot[] = [];
  layouts: Layout[] = [];
  stateForm: FormGroup;

  searchGroup: SearchGroup[] = [];

  stateGroupOptions: Observable<SearchGroup[]>;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private searchDataSubs: Subscription;
  private isOptionPanelOpened = false;

  constructor(
    private _formBuilder: FormBuilder,
    private _dashboardService: DashboardService,
    private mapLibreService: MapLibreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private leafletService: LayoutMapService,
    private elementRef: ElementRef,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  onClickSearch(event: MatOption): void {
    this.stateForm.get('searchGroup').setValue('');
    if (event.value.type === 'robot') {
      if (event.value.location && this.mapLibreService.isMapInitiate()) {
        const coor: [number, number] = [
          event.value.location.lng,
          event.value.location.lat,
        ];
        this.mapLibreService.flyTo({
          center: coor,
        });
      } else if (event.value.point && this.leafletService.isMapInitiate()) {
        const coordinates: L.PointExpression = [
          event.value.point.x,
          event.value.point.y,
        ];
        const coor = this.leafletService.xYtoLatlng(coordinates);
        this.leafletService.flyTo(coor);
      }
      this._dashboardService.selectedRobotId$.next(event.value.id);
    } else if (event.value.type === 'layout') {
      const queryParams = event.value.siteId
        ? { siteId: event.value.siteId }
        : { layoutId: event.value.layoutId };

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
      });

      //  close robot details panel
      this._dashboardService.toggleRobotDetails$.next(false);
    }
  }

  displayFn(data: { id: string; name: string }): string {
    return data.name;
  }

  private initForm(): void {
    this.stateForm = this._formBuilder.group({
      searchGroup: '',
    });
  }

  private getData(): Observable<any> {
    return combineLatest({
      robots: this.appService.robots$,
      layouts: this.appService.layouts$,
    });
  }

  private assignSearchGroup(): void {
    const listRobot: SearchGroupList[] = [];
    const listLayout: SearchGroupList[] = [];

    this.robots.map((robot) => {
      let robotName = robot.name;
      if (robot.layoutId !== NIL_UUID) {
        robotName = `${robot.name} (${robot.pointName})`;
      }
      listRobot.push({
        id: robot.id,
        name: robotName,
        location: robot.location,
        type: 'robot',
        point: robot.point,
      });
    });
    listRobot.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    this.layouts.map((layout) => {
      listLayout.push({
        id: layout.id,
        name: layout.name,
        location: layout.location,
        type: 'layout',
        layoutId: layout.type === 1 ? layout.id : undefined,
        siteId: layout.type === 2 ? layout.id : undefined,
      });
    });
    listLayout.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

    this.searchGroup = [
      {
        category: 'Layout',
        list: listLayout,
      },
      {
        category: 'Robot',
        list: listRobot,
      },
    ];
  }

  private _filterGroup(value: string): SearchGroup[] {
    if (value) {
      return this.searchGroup
        .map((group) => ({
          category: group.category,
          list: _filter(group.list, value),
        }))
        .filter((group) => group.list.length > 0);
    }

    return this.searchGroup;
  }

  /**
   * Populate search data (Robots / Layouts) when user click on the search component
   * It will populate the data when the option panel is not opened.
   * @param event
   */
  @HostListener('document:click', ['$event'])
  private populateSearchData(event = null) {
    // Check if the option panel is not opened
    // to populate the search data. If already opened, do nothing
    if (
      !this.isOptionPanelOpened &&
      this.elementRef.nativeElement.contains(event.target)
    ) {
      this.isOptionPanelOpened = true;
      this.stateGroupOptions = this.stateForm
        .get('searchGroup')!
        .valueChanges.pipe(
          startWith(''),
          map((value) => this._filterGroup(value || ''))
        );

      // Get robots and layouts data, forkJoin it so can be called in one subscribe
      this.searchDataSubs = this.getData().subscribe(({ robots, layouts }) => {
        this.robots = robots.result.list;
        this.layouts = layouts.result.list;
        this.assignSearchGroup();
      });
    } else if (
      this.isOptionPanelOpened &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.stateForm.get('searchGroup').setValue('');
      this.isOptionPanelOpened = false;
      if (this.searchDataSubs) {
        this.searchDataSubs.unsubscribe();
      }
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    if (this.searchDataSubs) {
      this.searchDataSubs.unsubscribe();
    }
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
