import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'plusMore',
})
export class PlusMorePipe implements PipeTransform {
  transform(commaString: string, show: number = 3): string {
    if (commaString.includes(',')) {
      const list = commaString.split(',');
      if (list.length > show) {
        return `${list.slice(0, show).join(', ')}, +${list.length - show} more`;
      } else {
        return `${list.slice(0, show).join(', ')}`;
      }
    }
    return commaString;
  }
}
