<div class="flex row">
  <div
    class="flex justify-center items-center bg-neutral-650 border border-neutral-400 rounded-full h-12 w-12"
  >
    <img src="../../../../assets/images/calls/call.gif" alt="call" />
  </div>
  <div class="text-neutral-50 font-semibold text-lg mx-6">
    Incoming call from {{ robotname }}
  </div>
  <!-- Call Action -->
  <div class="flex justify-center gap-6 w-30 h-12 text-center">
    <button
      class="bg-primary text-white rounded-md w-12 h-9"
      (click)="answerCall(callData)"
    >
      <mat-icon class="text-white pt-1" svgIcon="mat_solid:videocam"></mat-icon>
    </button>
    <button
      class="bg-red-500 text-white rounded-md w-12 h-9"
      (click)="declineCall(callData)"
    >
      <mat-icon class="text-white pt-1" svgIcon="mat_solid:close"></mat-icon>
    </button>
  </div>
</div>
