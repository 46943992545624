<div
  class="flex flex-col bg-neutral mx-4 my-4 rounded-lg p-4"
  [ngClass]="{
    'opacity-75': status !== 1,
    'bg-neutral': !isMalfunction,
    'bg-error-900 border-[1px] border-error-500': isMalfunction
  }"
  (click)="status === 1 ? openRobotDetailPanel() : null"
>
  <div class="flex flex-row justify-between mb-2">
    <div>{{ headerTitle }}</div>
    <div class="text-sm">
      <mat-icon
        svgIcon="mat_solid:circle"
        class="w-2 h-2 min-w-2 min-h-2"
        [ngClass]="{
          'text-success': headerStatus === 'Online',
          'text-neutral-300': headerStatus === 'Offline'
        }"
      ></mat-icon>
      {{ headerStatus }}
    </div>
  </div>
  <div class="flex flex-row items-center">
    <div *ngIf="isContentIcon && layoutName">
      <mat-icon
        class="pt-1"
        [svgIcon]="contentIconType + ':' + contentIcon"
      ></mat-icon>
    </div>
    <div class="text-sm">
      <div *ngIf="status === 1">
        <div>
          {{ layoutName ?? '-' }}
        </div>
      </div>
      <div *ngIf="status !== 1">
        Last seen: {{ layoutName ?? '-'
        }}<span>{{ lastOnline ? ', ' + lastOnline : '' }}</span>
      </div>
    </div>
  </div>
  <div class="malfunction-status flex items-center" *ngIf="isMalfunction">
    <mat-icon
      [svgIcon]="'mat_solid:build'"
      class="malfunction-icon mr-2 text-error-300"
    ></mat-icon>
    <span class="text-error-300">Malfunctioning</span>
  </div>
</div>
