import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Robot } from 'rm-api-services/dist/api-services';
import { MalfunctionIssueDialogComponent } from './issue-dialog/issue-dialog.component';

export interface RobotStateDetail extends Robot {
  stateDetail?: string;
}

@Component({
  selector: 'malfunction-card-toast',
  templateUrl: './malfunction-card-toast.component.html',
  styleUrls: ['./malfunction-card-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MalfunctionCardToastComponent implements OnInit {
  @Input() robots: RobotStateDetail[] = [];
  @Input() totalRobot: number = 0;
  @Input() isInGroupPatrol: boolean = false;
  @Output() isShowCardToast = new EventEmitter();
  @Output() rellocateRobot = new EventEmitter<string>();

  // totalRobot: number = 0;

  constructor(public dialog: MatDialog) {}
  ngOnInit(): void {
    this.totalRobot = this.robots.length;
    if (this.totalRobot > 0) {
      this.isShowCardToast.emit(true);
    }
  }
  closeCardToast() {
    this.isShowCardToast.emit(false);
  }

  seeIssue() {
    if (this.isInGroupPatrol) {
      if (this.totalRobot > 0) {
        this.rellocateRobot.emit(this.robots[0].id);
      }
    } else {
      const robotIds: string[] = this.robots.map((robot) => robot.id);
      this.dialog.open(MalfunctionIssueDialogComponent, {
        width: '40vw',
        panelClass: 'rm-dialog',
        data: robotIds,
      });
    }
  }
}
