import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { EventMedia } from 'api-services';

@Component({
  selector: 'app-card-detection',
  templateUrl: './card-detection.component.html',
  styleUrls: ['./card-detection.component.scss'],
})
export class CardDetectionComponent implements OnInit {
  @Input() headerTitle: string;
  @Input() severity: number;
  @Input() isVideo: boolean;
  @Input() mediaUrl: string;
  @Input() mediaFiles: EventMedia[];
  @Input() timestamp: number;
  @Input() layoutName: string;
  @Input() robotName: string;

  isLoaded: boolean = false;
  isMedia: boolean = false;

  constructor() {}

  ngOnInit(): void {
    if (this.mediaFiles) {
      this.isMedia = true;
    }
  }

  onLoaded() {
    this.isLoaded = true;
  }
}
