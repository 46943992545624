/**
 * To remove CSS class name form DOM elements
 *
 * @param removedClassName CSS class name that want to be romoved from the DOM elements without the dot (.)
 * @param querySelectorClassname CSS class name that used to find the DOM element with dot (.), and seperated by comma (,) for multiple classname. E.g. '.container', '.row'
 */
export const removeClassnameFromMultipleElement = (
  removedClassName: string,
  ...querySelectorClassname: string[]
): void => {
  if (querySelectorClassname.length > 0) {
    const elements = document.querySelectorAll(
      querySelectorClassname.join(', ')
    );
    elements.forEach((element) => {
      element.classList.remove(removedClassName);
    });
  }
};

/**
 * To add CSS class name to DOM element with specific parent ID attribute
 * @param elementId ID attribute for parent DOM element
 * @param newClassname CSS class name that want to be added to the DOM elements without the dot (.)
 * @param querySelectorClassname CSS class name that used to find the DOM element with dot (.), and seperated by comma (,) for multiple classname. E.g. '.container', '.row'
 */
export const addClassnameToMultipleElementWithSpecificId = (
  elementId: string,
  newClassname: string,
  ...querySelectorClassname: string[]
): void => {
  if (querySelectorClassname.length > 0) {
    const parent = document.getElementById(elementId);
    if (parent) {
      const elements = parent.querySelectorAll(
        querySelectorClassname.join(', ')
      );
      elements.forEach((element) => {
        element.classList.add(newClassname);
      });
    }
  }
};

/**
 * To remove CSS class name to DOM element with specific parent ID attribute
 * @param elementId ID attribute for parent DOM element
 * @param classname CSS class name that want to be added to the DOM elements without the dot (.)
 * @param querySelectorClassname CSS class name that used to find the DOM element with dot (.), and seperated by comma (,) for multiple classname. E.g. '.container', '.row'
 */
export const removeClassnameToMultipleElementWithSpecificId = (
  elementId: string,
  classname: string,
  ...querySelectorClassname: string[]
): void => {
  if (querySelectorClassname.length > 0) {
    const parent = document.getElementById(elementId);
    if (parent) {
      const elements = parent.querySelectorAll(
        querySelectorClassname.join(', ')
      );
      elements.forEach((element) => {
        element.classList.remove(classname);
      });
    }
  }
};

/**
 * To add CSS class name to parent DOM element with specific child ID attribute
 *
 * @param elementId ID attribute for parent DOM element
 * @param newClassname CSS class name that want to be added to the DOM elements without the dot (.)
 */
export const addClassnameToParentElementWithSpecificId = (
  elementId: string,
  newClassname: string
): void => {
  const element = document.getElementById(elementId);
  if (element) {
    const parent = element.parentElement;
    parent.classList.add(newClassname);
  }
};

/**
 * Get angle between to points in degree
 * reference: https://www.w3schools.blog/angle-between-two-points
 *
 * @param fromPosition Old marker position (x, y) / (lng, lat)
 * @param toPosition New marker position (x, y) / (lng, lat)
 * @returns angle in degree
 */
export const getAngle = (
  fromPosition: [number, number],
  toPosition: [number, number]
): number => {
  // [lng, lat] = [x, y]
  return (
    (Math.atan2(
      toPosition[0] - fromPosition[0],
      toPosition[1] - fromPosition[1]
    ) *
      180) /
    Math.PI
  );
};

/**
 * Helper function to update the marker position
 * repeatedly, as if making the marker moving using animation
 *
 * @param fromPosition Old marker position (x, y) / (lng, lat)
 * @param toPosition New marker position (x, y) / (lng, lat)
 * @param onUpdate Callback used while the marker moving
 * @param frame number of frame used to move marker per second. Higher number make the marker move faster and vice versa
 * @param speed number of pixels used to update the marker position per frame
 * @returns
 */
export const moveToPosition = (
  fromPosition: [number, number],
  toPosition: [number, number],
  onUpdate: (position: [number, number]) => void,
  frame: number,
  speed: number
) => {
  const step = getMovingStepDistance(fromPosition, toPosition, speed);
  const update = (position: [number, number]) => {
    onUpdate(position);
  };
  const arrive = () => {
    moveToLoopByFrame.stop();
  };
  const moveToUpdate = moveTo(update, arrive, fromPosition, toPosition, step);
  const moveToLoopByFrame = updatePositionByFrame(moveToUpdate, frame);
  moveToLoopByFrame.run();
  return moveToLoopByFrame;
};

/**
 * Get number of loop need to animate the marker movement in given distance
 *
 * @param fromPosition Old marker position (x, y) / (lng, lat)
 * @param toPosition New marker position (x, y) / (lng, lat)
 * @param speed Number of pixels used to update the marker position
 * @returns number of loop need to animate the marker movement
 */
const getMovingStepDistance = (
  fromPosition: [number, number],
  toPosition: [number, number],
  speed: number = 5
): number => {
  // d=√((x2 – x1)² + (y2 – y1)²) calculate distance of 2 points using Teorema Pythagoras
  const distance = Math.sqrt(
    Math.pow(toPosition[0] - fromPosition[0], 2) +
      Math.pow(toPosition[1] - fromPosition[1], 2)
  );
  return distance / speed;
};

/**
 * Helper function used to track the marker movement if it
 * already arrived at new position and update the marker position
 * for given pixels number to make the marker moving as if with animation
 *
 * @param onUpdate Callback used while the marker moving
 * @param onArrive Callback used when the marker arrive at new position
 * @param fromPosition Old marker position (x, y) / (lng, lat)
 * @param toPosition New marker position (x, y) / (lng, lat)
 * @param movingStep number of loop used to update the marker position based on distance between old and new marker position
 * @returns function to track the marker movement
 */
const moveTo = (
  onUpdate: (position: [number, number]) => void,
  onArrive: () => void,
  fromPosition: [number, number],
  toPosition: [number, number],
  movingStep: number = 10
): (() => void) => {
  // calculate the offset position for every loop
  const offLngX = (toPosition[0] - fromPosition[0]) / movingStep;
  const offLatY = (toPosition[1] - fromPosition[1]) / movingStep;
  let currentStep = 0;
  return () => {
    // check if the marker still need to move
    if (currentStep <= movingStep) {
      // calculate the position coordinate for every loop, and update current marker position coordinate
      const lngX = fromPosition[0] + offLngX * currentStep;
      const latY = fromPosition[1] + offLatY * currentStep;
      const current: [number, number] = [lngX, latY];
      onUpdate && onUpdate(current);
      currentStep++;
    } else {
      onArrive && onArrive();
    }
  };
};

/**
 * Helper function to animate the marker movement by frame using requestAnimationFrame function
 * @param fn
 * @param frame
 * @returns
 */
const updatePositionByFrame = (
  fn: () => void,
  frame: number = 24
): { run: () => void; stop: () => void } => {
  const delay = 1000 / frame;
  let old = Date.now();
  let timer: number = null;
  let cacheArgs: any;
  let stopped = false;
  const update = (...args: any[]) => {
    if (!stopped) {
      cacheArgs = cacheArgs || args;
      const context = this;
      timer = requestAnimationFrame(() => {
        const now = Date.now();
        if (now - old >= delay) {
          old = now;
          fn.apply(context, cacheArgs);
        }
        update(cacheArgs);
      });
    }
  };
  const stop = () => {
    stopped = true;
    cancelAnimationFrame(timer);
  };
  return {
    run: update,
    stop: stop,
  };
};
