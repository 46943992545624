<div
  class="toast-item cursor-pointer flex flex-row justify-start h-auto gap-2 mb-2 px-5 py-4 transition-all border-solid rounded-lg bg-error-800 border-error-500 border-x border-y drop-shadow-md"
  matTooltip="Click to dismiss"
  matTooltipPosition="below"
>
  <div>
    <mat-icon
      [svgIcon]="'mat_solid:error'"
      class="malfunction-icon mr-2 text-error-300 scale-x-[-1] pt-[2px]"
    ></mat-icon>
  </div>
  <div class="flex flex-col">
    <span class="block mb-2 font-semibold">Job Failed</span>
    <span class="block mb-2"
      >{{ data.data.robot?.name }} is failed to run
      {{ data.data.mission?.name | removeDateTime | removeBracket
      }}{{
        data.data.robot?.pointName.length > 0
          ? ' on layout ' + data.data.robot?.pointName
          : ''
      }}.</span
    >
  </div>
</div>
