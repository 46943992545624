<div class="float-icon">
  <div
    class="flex justify-center items-center bg-neutral-650 border border-neutral-400 rounded-full h-15 w-15"
  >
    <img src="../../../../assets/images/calls/call.gif" alt="call" />
  </div>
</div>
<div mat-dialog-title class="text-center text-lg">
  <h3>Incoming Call from {{ data.name }}</h3>
</div>

<mat-dialog-content class="m-0 px-2 py-0">
  <div class="flex flex-col justify-center">
    <div class="text-center text-sm text-neutral-100">
      Taking calls will pause robot's current mission.
    </div>
    <!-- Call Action -->
    <div class="flex justify-center mt-4 mb-2 gap-5">
      <button
        mat-button
        class="bg-primary text-white rounded-md"
        (click)="answerCall()"
      >
        <mat-icon class="text-white" svgIcon="mat_solid:videocam"></mat-icon>
      </button>
      <button
        mat-button
        class="bg-red-500 text-white mr-2 rounded-md"
        (click)="declineCall()"
      >
        <mat-icon class="text-white" svgIcon="mat_solid:close"></mat-icon>
      </button>
    </div>
  </div>
</mat-dialog-content>
