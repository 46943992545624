<!-- Button -->
<button
  class="flex w-auto items-center cursor-default"
  *ngIf="rmUser?.name || rmUser?.email"
>
  <div class="relative w-7 h-7">
    <div
      class="flex items-center justify-center w-full h-full text-xl text-white uppercase rounded-full bg-primary"
    >
      {{ rmUser.name?.length > 0 ? rmUser.name?.charAt(0) : '' }}
    </div>
  </div>
  <div class="ml-2 font-normal">
    {{ rmUser.name ? rmUser.name : rmUser.email }}
  </div>
</button>
