import { Error401Component } from 'app/modules/admin/pages/error/error-401/error-401.component';
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { TeleoperationsModule } from 'app/modules/teleoperations/teleoperations.module';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/dashboard'
  // {path: '', pathMatch : 'full', redirectTo: 'dashboard'},
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  // 401 error
  {
    path: '401',
    component: Error401Component,
    data: {
      layout: 'empty',
    },
  },

  // Redirect signed in user to the '/dashboards'
  //
  // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  // {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

  // Auth routes for guests
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'home',
        loadChildren: () =>
          import('app/modules/landing/home/home.module').then(
            (m) => m.LandingHomeModule
          ),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: InitialDataResolver,
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('app/modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'teleoperation/:id',
        loadChildren: () =>
          import('app/modules/teleoperations/teleoperations.module').then(
            (m) => m.TeleoperationsModule
          ),
      },
      {
        path: 'detection',
        loadChildren: () =>
          import('app/modules/detection/detection.module').then(
            (m) => m.DetectionModule
          ),
      },
      {
        path: 'record-notes',
        loadChildren: () =>
          import('app/modules/record-notes/record-notes.module').then(
            (m) => m.RecordNotesModule
          ),
      },
      {
        path: 'robots',
        loadChildren: () =>
          import('app/modules/robot/robot.module').then((m) => m.RobotModule),
      },
      {
        path: 'dispatch-robots',
        loadChildren: () =>
          import('app/modules/dispatch-robot/dispatch-robot.module').then(
            (m) => m.DispatchRobotModule
          ),
      },
      {
        path: 'malfunctions',
        loadChildren: () =>
          import('app/modules/malfunction/malfunction.module').then(
            (m) => m.MalfunctionModule
          ),
      },
      {
        path: 'videos',
        loadChildren: () =>
          import('app/modules/videos/videos.module').then(
            (m) => m.VideosModule
          ),
      },
      {
        path: 'patrol-logs',
        loadChildren: () =>
          import('app/modules/patrol-log/patrol-log.module').then(
            (m) => m.PatrolLogModule
          ),
      },
    ],
  },

  // {
  //   path: 'detection/:id',
  //   // resolve: {
  //   //   initialData: InitialDataResolver,
  //   // },
  //   loadChildren: () =>
  //     import('app/modules/detection/detection.module').then(
  //       (m) => m.DetectionModule
  //     ),
  // },
];
