<div class="flex justify-center mt-30">
  <img
    src="assets/images/pages/detection/robot-illustration.svg"
    width="136px"
    height="170px"
    alt="No suspicious detected"
    class="w-1/2"
  />
</div>
<div class="flex flex-col justify-center mt-5 text-center mx-13">
  <span class="font-semibold">
    No suspicious person or item detected at the moment
  </span>
  <span class="text-neutral-200 text-base"
    >Everything looks clear for now! You'll be alerted once there's a
    detection.</span
  >
</div>
