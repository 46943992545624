<div
  class="flex flex-col absolute items-center justify-center z-999"
  [ngClass]="[
    {
      topLeft: 'top-4 left-4',
      topRight: 'top-4 right-4',
      bottomLeft: 'bottom-4 left-4',
      bottomRight: 'bottom-4 right-4'
    }[position]
  ]"
>
  <button
    class="cursor-pointer bg-neutral hover:bg-neutral-400 flex items-center justify-center align-middle rounded-sm border border-neutral-300"
    [ngClass]="{
      'w-7 h-7': size === 'small',
      'w-9 h-9': size === 'medium',
      'w-12 h-12': size === 'big'
    }"
    (click)="toggleFullscreen()"
  >
    <mat-icon svgIcon="fullscreen" class="text-white"></mat-icon>
  </button>
</div>
