import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Mission } from 'rm-api-services/dist/api-services';

@Component({
  selector: 'shared-card-job',
  styles: [
    `
      .description {
        // text overflow, maximum 2 line of message displayed
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .background-menu {
        background: theme('colors.neutral.600') !important;
      }

      /* -------------------------------------------------------------------------
  Handle long cron expressions will be animated to show the full expression
-------------------------------------------------------------------------- */
      .animated {
        overflow: hidden;
        width: 8rem;
        white-space: nowrap;
      }

      .animated > * {
        display: inline-block;
        position: relative;
        animation: 5s linear 0s infinite alternate move;
      }

      .animated > *.min {
        min-width: 100%;
      }

      @keyframes move {
        0%,
        25% {
          transform: translateX(0%);
          left: 0%;
        }
        75%,
        100% {
          transform: translateX(-100%);
          left: 100%;
        }
      }

      /* -------------------------------------------------------------------------
VERTICAL STEPPERS FOR PATROLING (New Job needs to be created)
-------------------------------------------------------------------------- */

      .list-patrol li {
        display: flex;
        color: theme('colors.neutral.100');
      }

      .list-patrol .circle {
        position: relative;
        padding: 0;
      }

      .list-patrol .circle::after {
        content: '';
        position: absolute;
        z-index: 2;
        right: 0;
        top: 0;
        transform: translateX(50%);
        border-radius: 50%;
        background: transparent;
        border: 3px theme('colors.neutral.300') solid;
        width: 1.2em;
        height: 1.2em;
      }

      .list-patrol .last-circle {
        position: relative;
        padding: 0;
      }

      .list-patrol .last-circle::after {
        content: '';
        position: absolute;
        z-index: 2;
        right: 0;
        top: -5px;
        transform: rotate(45deg) translate(70%) !important;
        background: transparent;
        border: 4px theme('colors.neutral.300') solid;
        border-radius: 50% 50% 0%;
        width: 1.2em;
        height: 1.2em;
      }

      .list-patrol .strip {
        padding: 0 1.5em 1.5em 1.5em;
        position: relative;
      }

      .list-patrol .strip::before {
        content: '';
        position: absolute;
        z-index: 1;
        margin-top: 14px;
        left: -1.5px;
        height: 100%;
        border-left: 3px theme('colors.neutral.300') dashed;
      }

      .list-patrol .last-strip {
        padding: 0 1.5em 1.5em 1.5em;
        position: relative;
      }

      .list-patrol .last-strip::before {
        content: '';
        position: absolute;
        z-index: 1;
        margin-top: 10px;
        left: 0;
        height: 100%;
      }

      .list-patrol {
        margin: 1em;
        width: 50%;
      }
    `,
  ],
  template: `<fuse-card
    class="flex flex-col p-4 pb-4 bg-neutral-650 border border-neutral-400 rounded-lg my-2"
    #fuseCard
  >
    <!-- Header drawer -->
    <ng-container fuseCardHeader>
      <div class="w-full">
        <div>
          <span
            *ngIf="job.status === 5"
            class="job-status current rounded-full mb-3 inline-block text-success text-sm font-semibold py-1 px-3 text-center"
            >Current</span
          >
          <span
            *ngIf="job.status === 3"
            class="job-status paused rounded-full mb-3 inline-block text-error text-sm font-semibold py-1 px-3 text-center"
            >Paused</span
          >
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex items-center">
          <div class="text-md font-bold">
            {{
              job.name
                ? (job.name | removeDateTime | removeBracket)
                : job.skills
            }}
          </div>
        </div>
        <div
          class="flex items-center mr-0 gap-2"
          *ngIf="
            !statusCntResume.includes(modifiedJob.statusText) && enableAction
          "
        >
          <!-- Start/Pause Button -->
          <button
            mat-icon-button
            (click)="handleUpdateJob('start')"
            *ngIf="modifiedJob.status.toLowerCase() === 'paused'"
          >
            <mat-icon svgIcon="mat_solid:play_arrow"></mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="handleUpdateJob('pause')"
            *ngIf="modifiedJob.status.toLowerCase() !== 'paused'"
          >
            <mat-icon svgIcon="mat_solid:pause"></mat-icon>
          </button>

          <!-- Settings button -->
          <button mat-icon-button [matMenuTriggerFor]="settings" class="w-6">
            <mat-icon svgIcon="mat_solid:more_horiz"></mat-icon>
          </button>
          <!-- mat-menu settings -->
          <mat-menu #settings="matMenu" class="background-menu">
            <button
              mat-menu-item
              class="text-neutral-50"
              (click)="handleUpdateJob('pause')"
              *ngIf="modifiedJob.status.toLowerCase() !== 'paused'"
            >
              <mat-icon svgIcon="mat_solid:pause"></mat-icon>
              <span class="ml-2">Pause job</span>
            </button>
            <button
              mat-menu-item
              (click)="handleUpdateJob('start')"
              *ngIf="modifiedJob.status.toLowerCase() === 'paused'"
            >
              <mat-icon svgIcon="mat_solid:play_arrow"></mat-icon>
              <span class="ml-2">Resume job</span>
            </button>

            <!-- <button mat-menu-item class="text-neutral-50">
            <mat-icon svgIcon="mat_solid:content_copy"></mat-icon>
            <span class="ml-2">Duplicate job</span>
          </button> -->
            <!-- Divider for abort button -->
            <mat-divider></mat-divider>
            <button
              mat-menu-item
              class="text-error-300"
              (click)="handleUpdateJob('abort')"
            >
              <mat-icon
                class="text-error-300"
                svgIcon="mat_solid:delete"
              ></mat-icon>
              <span class="ml-2">Abort job</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </ng-container>
    <!-- Start Description  -->
    <div
      class="mt-2 description text-sm text-neutral-100"
      *ngIf="
        modifiedJob.description &&
        !modifiedJob.name.toLocaleLowerCase().includes('patrol')
      "
    >
      {{ modifiedJob.description }}
    </div>
    <div
      class="mt-2 description text-sm text-neutral-100"
      *ngIf="
        modifiedJob.description &&
        modifiedJob.name.toLocaleLowerCase().includes('patrol')
      "
    >
      <!-- loop description -->
      <ng-container
        *ngFor="let desc of modifiedJob.description; let last = last"
      >
        <ul class="list-patrol">
          <li>
            <!-- <time datetime="10:03">10:03</time> -->
            <div class="{{ last ? ' last-circle' : 'circle' }}"></div>
            <span class="{{ last ? ' last-strip' : 'strip' }}">{{
              desc | robotDescriptionPipe
            }}</span>
          </li>
        </ul>
      </ng-container>
    </div>
    <!-- End Description -->
    <!-- Footer drawer -->
    <ng-container fuseCardFooter>
      <div
        class="flex items center justify-between mt-5 text-sm text-neutral-100"
      >
        <div class="flex items-center">
          <ng-container *ngIf="modifiedJob.cronExp">
            <mat-icon
              class="w-5 scale-75"
              svgIcon="mat_solid:schedule"
            ></mat-icon>
            <!-- if the text long the add animated class -->
            <div
              [ngClass]="{
                animated: (modifiedJob.cronExp | cronstrue)?.length > 20
              }"
            >
              <span class="mx-0">{{ modifiedJob.cronExp | cronstrue }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="modifiedJob.repeat">
            <mat-icon
              *ngIf="modifiedJob.repeat !== -1"
              class="w-5 scale-75"
              svgIcon="mat_solid:repeat"
            ></mat-icon>
            <span *ngIf="modifiedJob.repeat !== -1" class="mx-0">{{
              modifiedJob.repeat
            }}</span>
            <mat-icon
              *ngIf="modifiedJob.repeat === -1"
              class="w-5 scale-75"
              svgIcon="mat_solid:all_inclusive"
            ></mat-icon>
          </ng-container>
        </div>
        <div class="flex items-center gap-2">
          <ng-container *ngIf="broadcastState">
            <img
              src="../../../../assets/images/jobs-component/chat-on.svg"
              alt="Chat on"
              class="w-1/2"
            />
          </ng-container>
          <ng-container *ngIf="!broadcastState">
            <img
              src="../../../../assets/images/jobs-component/chat-off.svg"
              alt="Chat off"
              class="w-1/2"
            />
          </ng-container>
          <ng-container *ngIf="blinkState">
            <img
              src="../../../../assets/images/jobs-component/blink-on.svg"
              alt="blink on"
              class="w-1/2"
            />
          </ng-container>
          <ng-container *ngIf="!blinkState">
            <img
              src="../../../../assets/images/jobs-component/blink-off.svg"
              alt="blink off"
              class="w-1/2"
            />
          </ng-container>
        </div>
      </div>
    </ng-container>
  </fuse-card>`,
})

/**
 * TODO: broadcastState and blinkState currently is not in our system, will be updated soon
 */
export class CardJobComponent implements OnInit {
  @Input() job: Mission;
  @Input() broadcastState: boolean = false;
  @Input() blinkState: boolean = false;
  @Input() enableAction: boolean = true;
  @Output() updateJob = new EventEmitter<{ job: Mission; action: string }>();

  statusMapping = {
    1: 'Completed',
    2: 'Aborted',
    3: 'Paused',
    4: 'Scheduled',
    5: 'Active',
    6: 'Failed',
  };
  statusCntResume = ['Completed', 'Aborted', 'Failed'];
  modifiedJob: ModifiedMission;

  ngOnInit(): void {
    this.modifiedJob = this.job;
    this.modifiedJob.statusText = this.statusMapping[this.job.status];
  }

  handleUpdateJob(action: 'start' | 'pause' | 'abort'): void {
    this.updateJob.emit({
      job: this.job,
      action: action,
    });
  }
}

interface ModifiedMission extends Mission {
  statusText?: string;
}
