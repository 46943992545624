import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { Mission } from 'rm-api-services/dist/api-services';
export interface RadioButton {
  label: string;
  value: string;
}

@Component({
  selector: 'slide-toggle',
  styles: [
    `
      .slide-toggle {
        ::ng-deep .mat-slide-toggle.mat-checked {
          .mat-slide-toggle-bar {
            background-color: theme('colors.primary.DEFAULT') !important;
            .mat-slide-toggle-thumb {
              background-image: url('/assets/icons/check.svg');
              background-repeat: no-repeat;
              background-position: center;
              background-color: white;
              width: 24px;
              height: 24px;
              margin-left: 12px;
              margin-top: 6px;
            }
            .mat-slide-toggle-ripple {
              margin-left: 6px;
              margin-top: 8px;
            }
          }
        }
        ::ng-deep.mat-slide-toggle-bar {
          background-color: theme('colors.neutral.300');
          height: 30px;
          width: 56px;
          border-radius: 200px;
          margin-right: 10px;
          .mat-slide-toggle-thumb {
            background-color: white;
            background-image: url('/assets/icons/times.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: 24px;
            height: 24px;
            margin-left: 5px;
            margin-top: 6px;
          }
          .mat-slide-toggle-ripple {
            margin-left: 6px;
            margin-top: 8px;
          }
        }
        ::ng-deep.mat-slide-toggle-bar:hover {
          .mat-slide-toggle-persistent-ripple {
            opacity: 0.2 !important;
          }
        }
      }
    `,
  ],
  template: `<section class="slide-toggle">
    <mat-slide-toggle
      (change)="handleChange($event)"
      [disableRipple]="true"
      [checked]="isChecked"
      [disabled]="disabled"
    >
      <div class="grid">
        <span class="text-lg font-semibold text-neutral-50">{{ label }}</span>
        <span class="text-sm text-neutral-100">{{ description }}</span>
      </div></mat-slide-toggle
    >
  </section>`,
})

/**
 * TODO: broadcastState and blinkState currently is not in our system, will be updated soon
 */
export class SlideToggleComponent implements OnInit {
  // @Input() radioButtons: RadioButton[] = [];
  @Input() label: string;
  @Input() description?: string;
  @Output() onChange = new EventEmitter();
  @Input() isChecked: boolean;
  @Input() disabled: boolean;
  ngOnInit(): void {}
  handleChange(event): void {
    // this.isChecked = !this.isChecked;
    this.onChange.emit(event);
  }
}
