import { Component, Input, OnInit } from '@angular/core';
import { EnumType } from 'typescript';

@Component({
  selector: 'shared-robot-signal',
  styles: [
    `
      .wave {
        border: 5px solid transparent;
        border-top-color: currentColor;
        border-radius: 50%;
        border-style: solid;
      }
      .waveStrength-3 .wv4.wave,
      .waveStrength-2 .wv4.wave,
      .waveStrength-2 .wv3.wave,
      .waveStrength-1 .wv4.wave,
      .waveStrength-1 .wv3.wave,
      .waveStrength-1 .wv2.wave {
        border-top-color: theme('colors.neutral.400') !important;
      }
    `,
  ],
  template: ` <div
    class="waveStrength-{{ convertConnectivity(connectivity) }}"
    style="
        transform: translateY(8px);"
  >
    <div class="wv4 wave">
      <div class="wv3 wave">
        <div class="wv2 wave">
          <div class="wv1 wave"></div>
        </div>
      </div>
    </div>
  </div>`,
})
export class RobotSignalComponent implements OnInit {
  @Input() connectivity: number;
  ngOnInit(): void {}

  convertConnectivity(connectivity: number): number {
    if (connectivity <= 20) {
      return 1;
    } else if (connectivity <= 50) {
      return 2;
    } else if (connectivity <= 80) {
      return 3;
    } else if (connectivity <= 100) {
      return 4;
    }
  }
}
