<div>
  <h3 class="text-3xl font-bold text-neutral-50">Go</h3>

  <p class="mt-1 text-neutral-100">
    Command robot to move one-way to a specific destination within the building.
  </p>

  <!-- Select Start Job  -->
  <div class="mt-5 max-w-[450px]">
    <rm-select-start-job-type
      [label]="'When does this job start?'"
      (selectionChange)="selectStartJobType($event)"
      (jobSchedule)="jobSchedule($event)"
    ></rm-select-start-job-type>
  </div>

  <div class="w-1/2 mt-0">
    <shared-form-select
      label="Destination"
      [data]="selectMarkers"
      [selectedOption]="selectedMarker"
      (selectionChange)="handleChangeMarker($event, 'marker')"
    ></shared-form-select>
    <!-- <rm-auto-complete
      label="Destination"
      placeholder="Choose a point"
      [selectOptions]="selectMarkers"
      [selectedOption]="selectedMarker"
      (selectionChange)="handleChangeMarker($event, 'marker')"
    ></rm-auto-complete> -->
  </div>

  <div class="w-full">
    <shared-mini-layout-map
      [isShowAlert]="true"
      [isReadOnlyMap]="false"
      (changeMarker)="handleChangeMarker($event.event, $event.action)"
    >
      <div messageAlert>
        Click on a marker
        <span class="text-info self-center"
          ><mat-icon
            class="icon-size-4"
            svgIcon="mat_solid:location_on"
          ></mat-icon
        ></span>
        to set the destination.
      </div>
    </shared-mini-layout-map>
  </div>

  <ng-container *ngIf="blinkingLightsSkill">
    <mat-divider class="mt-6 mb-8"></mat-divider>
    <div class="w-full">
      <slide-toggle
        class="mt-2"
        [label]="'Blinking lights'"
        [description]="'Flash blinking lights until this job is completed.'"
        (onChange)="handleBlinkingLightsCheck($event)"
      ></slide-toggle>
      <!-- [isChecked]="isBlinkingLightsCheck" -->
    </div>
  </ng-container>

  <ng-container *ngIf="broadcastMessageSkill || ttsMessageSkill">
    <mat-divider class="my-6"></mat-divider>
    <div class="flex flex-row w-full">
      <slide-toggle
        class="mt-2"
        (onChange)="handleBroadcastMessageCheck($event)"
      ></slide-toggle>
      <!-- [isChecked]="isBroadcastMessageCheck" -->
      <div class="flex flex-grow">
        <broadcast-job-creation
          class="w-full"
          [robot]="robot"
          [robotSkills]="robotSkills"
          [skill]="broadcastMessageSkill"
          [isChecked]="isBroadcastMessageCheck"
          [isConcurrentSkill]="true"
          (tasksData)="taskDataBroadcastMessage($event)"
        ></broadcast-job-creation>
        <!-- [robot]="robot"
      [layoutId]="robot.layoutId"
      [skill]="selectedSkill"
      [events]="eventsSubject.asObservable()"
      (closeDrawer)="handleClose()"
      (resetType)="resetType()" -->
      </div>
    </div>
  </ng-container>

  <div class="w-full" #bottomOfJobCreation></div>
</div>
