import { CommonModule } from '@angular/common';
import { Pipe, PipeTransform, NgModule } from '@angular/core';
import cronstrue from 'cronstrue/i18n';

@Pipe({
  name: 'cronstrue',
})
export class CronstruePipe implements PipeTransform {
  transform(value: string): string {
    if (value === '' || value === '-') {
      return 'One time';
    } else {
      return cronstrue.toString(value, {
        verbose: true,
        use24HourTimeFormat: true,
      });
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [CronstruePipe],
  exports: [CronstruePipe],
})
export class CronsPipesModule {}
