<!-- Header -->
<div
  class="relative flex flex-0 justify-between items-center w-full h-16 pl-4 pr-0 md:px-6 z-49 bg-neutral-800 print:hidden"
>
  <div class="flex items-center" (click)="onClick()">
    <span class="text-2xl cursor-pointer">{{ title }}</span>
    <ng-content select="[afterTitle]"></ng-content>
  </div>

  <div class="">
    <ng-content select="[rightSide]"> </ng-content>
  </div>
</div>
