import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timestampPipe',
})
export class TimestampPipe implements PipeTransform {
  transform(value: number): string {
    //e.g. value = 1678032621351 (timestamp), then formatted to 02/03/2023, 03:04 PM
    return moment(value).format('DD/MM/YYYY, hh:mm A');
  }
}

@Pipe({
  name: 'secondToTimePipe',
})
export class SecondToTimePipe implements PipeTransform {
  transform(value: number): string {
    //e.g. value = 2(second), then formatted to 00:00:02
    return moment.utc(value * 1000).format('HH:mm:ss');
  }
}

@Pipe({
  name: 'dateToTimestampAgoPipe',
})
export class DateToTimestampAgoPipe implements PipeTransform {
  transform(value: number): string {
    return moment(value).fromNow();
  }
}

@Pipe({
  name: 'formatDateTimePipe',
})
export class FormatDatePipe implements PipeTransform {
  transform(value: string): string {
    //e.g. value = 2023-03-05T16:02:40.112681Z, then formatted to 06/03/2023, 12:02 AM
    const timestamp = new Date(value).getTime();
    return moment(timestamp).format('DD/MM/YYYY, hh:mm A');
  }
}
