<!-- Navigation -->
<fuse-vertical-navigation
  class="bg-neutral-900 print:hidden"
  [appearance]="'thin'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation.compact"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>
    <!-- Logo -->
    <div class="flex items-center justify-center h-20">
      <img
        class="w-8"
        src="assets/images/logo/logo-rm.svg"
        alt="Robotmanager"
      />
    </div>
  </ng-container>

  <ng-container fuseVerticalNavigationFooter>
    <div
      class="flex items-center justify-center h-20"
      matTooltip="Return to Main Dashboard"
      matTooltipPosition="right"
    >
      <span class="cursor-pointer" (click)="openExternalLink($event)">
        <mat-icon svgIcon="exit_to_app" class="rotate-180"></mat-icon>
      </span>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Content -->
<div class="flex flex-col flex-auto">
  <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
         Otherwise, layout changes won't be registered and the view won't be updated! -->
  <router-outlet *ngIf="true"></router-outlet>
</div>

<!-- Somehow we need this 2 ng-container to check Detection page and to render the detection page correctly-->
<!-- <ng-container *ngIf="isDetectionPage">haha</ng-container> -->
<!-- <ng-container *ngIf="isDetectionPage">
  <app-detection class="w-full"></app-detection>
</ng-container> -->
