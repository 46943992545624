import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'shared-form-select',
  styles: [
    `
      body.dark {
        .mat-form-field.mat-form-field-appearance-fill {
          .mat-form-field-wrapper {
            .mat-form-field-flex {
              background-color: theme('colors.neutral.700') !important;
              border-color: theme('colors.neutral.400') !important;
              border-radius: 4px;
            }
          }
        }
        .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
          background: var(--fuse-primary);
          color: #ffffff;
        }
      }
    `,
  ],
  template: `<div class="w-full flex-auto">
    <!-- It's recommended to use <label></label> instead of <mat-label></mat-label>, 
    material not support placeholder and mat-label simultaneously -->
    <div class="mb-2" *ngIf="label && showLabel">
      <label>Select {{ label }}</label>
    </div>
    <mat-form-field class="flex-auto w-full shared-global-select relative" matTooltip = "{{selectedMsgDesc}}">
      <mat-select
        placeholder="Select {{ label }}"
        [panelClass]="'vapp-select'"
        [value]="selectedOption"
        [formControl]="controlName"
        (openedChange)="toggleSelect($event)"
        disableOptionCentering
        #selectedMsgElement
      >
        <ng-container *ngFor="let selection of data">
          <mat-divider *ngIf="selection.divider"></mat-divider>
          <mat-option
            [value]="selection.value"
            (onSelectionChange)="onSelectionChange(selection, $event)"
            ><mat-icon
              [svgIcon]="selection.icon"
              *ngIf="selection.icon"
            ></mat-icon>
            {{ selection.display }}</mat-option
          >
        </ng-container>
      </mat-select>
      <mat-error *ngIf="controlName.invalid && controlName.touched">
        <mat-icon class="scale-[1.5] text-[#dc2626]">warning</mat-icon> Please
        select {{ label }}
      </mat-error>
    </mat-form-field>
    <div
      class="-mt-4 w-full"
      [ngClass]="{ hidden: !hint, 'mb-4': !hint, 'mb-6': hint }"
    >
      <mat-hint class="text-sm font-normal text-neutral-100">{{
        hint
      }}</mat-hint>
    </div>
  </div>`,
  encapsulation: ViewEncapsulation.None,
})
export class FormSelectComponent implements OnInit, OnChanges {
  
  @Input() showLabel: boolean = true;
  @Input() label: string = null;
  @Input() hint: string = null;
  @Input() data: SelectOption[];
  @Input() selectedOption: any;
  @Input() controlName?: FormControl = new FormControl();
  @Output() selectionChange = new EventEmitter<any>();
  isOpened: boolean = false;
  valueSet: boolean = false;
  selectedMsgDesc = '';
  showTooltip = false;
  @ViewChild('selectedMsgElement', { read: ElementRef }) selectedMsgElement: ElementRef;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.selectedMsgDesc = '';
  }

  ngOnInit(): void {
    if (this.selectedOption) {
      this.valueSet = true;
      this.isOpened = true;
    }
    this.selectedMsgDesc = '';
    this.showTooltip = false;
  }

  onSelectionChange(selection: any, event: any): void {
    if (event.isUserInput) {
      this.selectionChange.emit(selection);
      this.valueSet = true;
      const select = this.selectedMsgElement?.nativeElement;
      if(selection?.display) {
        const element = this.elementRef.nativeElement;
        const textWidth = this.getTextWidth(element, selection.display);
        this.showTooltip = textWidth > select.scrollWidth-18;
      }
      this.selectedMsgDesc = this.showTooltip && selection?.display ? selection.display : '';
    }
  }

  getTextWidth(element: HTMLElement, text: string): number {
    const span = this.renderer.createElement('span');
    this.renderer.setStyle(span, 'visibility', 'hidden');
    this.renderer.appendChild(span, this.renderer.createText(text));
    this.renderer.appendChild(element, span);
    const width = span.offsetWidth;
    this.renderer.removeChild(element, span);
    return width;
  }

  toggleSelect(event) {
    this.isOpened = !event;
  }
}

export interface SelectOption {
  display: string;
  value: any;
  divider?: boolean;
  icon?: string;
}
