import { Component, Input, OnInit } from '@angular/core';
import { ToastData } from 'app/modules/dashboard/layout-map/layout-map.service';
import {
  ApiMission,
  ApiRobot,
  Mission,
  Robot,
} from 'rm-api-services/dist/api-services';

@Component({
  selector: 'shared-job-failed-toast',
  templateUrl: './job-failed.component.html',
  styleUrls: ['./job-failed.component.scss'],
})
export class JobFailedToastComponent implements OnInit {
  public jobs: RobotMission[] = [];
  @Input() data: ToastData;

  constructor(private apiRobot: ApiRobot, private apiMission: ApiMission) {}

  ngOnInit() {}

  getMissionDetail(id: string): Promise<Mission> {
    return new Promise((resolve) => {
      this.apiMission.getById(id).subscribe((res) => {
        if (res.code === 200) {
          resolve(res.result);
        }
      });
    });
  }

  getRobotDetail(id: string): Promise<Robot> {
    return new Promise((resolve) => {
      this.apiRobot.getById(id).subscribe((robot) => {
        resolve(robot.result);
      });
    });
  }
}

export interface RobotMission {
  robot: Robot;
  mission: Mission;
}
