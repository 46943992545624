<div class="container h-auto mx-auto">
  <!-- <div class="relative flex"> -->
  <!-- title -->
  <!-- <div
      *ngIf="
          this.selectedLayoutId && this.selectedLayoutId !== '';
          else allDetection
        "
        class="flex flex-col"
      >
      <span> Detections in this building </span>
      <span class="text-base text-neutral-100" *ngIf="dataLayout">
          {{ dataLayout.name }}
        </span>
      </div>
      <ng-template #allDetection> All detections </ng-template> -->
  <!-- //NEW -->

  <div class="relative flex justify-start items-center mx-5 mt-4 mb-[14px]">
    <!-- label -->
    <div
      class="relative flex items-center cursor-pointer"
      (click)="toggleRobotSelection()"
    >
      <span class="text-lg font-semibold">{{ titleDetectionPanel }} </span>
      <mat-icon
        class="ml-2 mr-4 text-white"
        svgIcon="heroicons_outline:chevron-down"
      ></mat-icon>
    </div>
    <!-- <button class="" mat-icon-button>
      

        
      </button> -->
    <!-- class="flex items-center justify-center flex-none w-auto h-6 px-2 mx-5 my-5 text-xl text-white rounded-lg bg-error-500" -->
    <span
      class="self-center w-auto px-2 text-base font-semibold text-white rounded-lg bg-error-500"
      >{{ this.totalEvent }}
    </span>

    <mat-select
      #selectRobot
      class="hidden"
      panelClass="relative bg-neutral-600 top-[36px] left-[73.5vw] w-[234px]"
      (infiniteScroll)="getRobotToSelect()"
      [complete]="robotOffset === robotTotal"
      [disableOptionCentering]="true"
      (selectionChange)="onClickRobot($event.value)"
      msInfiniteScroll
      [value]="selectedRobotId"
    >
      <!-- class="font-bold text-primary-400" -->
      <mat-option [value]="'allRobots'">All robots</mat-option>
      <mat-option *ngFor="let option of options$ | async" [value]="option.id">{{
        option.name
      }}</mat-option>
    </mat-select>
  </div>
  <!-- </div> -->
  <!-- divider -->
  <div class="mx-5 border-t border-neutral"></div>
  <!-- content -->

  <div
    *ngIf="totalEvent === 0 && isLoading === true"
    class="flex flex-col mx-5 mt-5 overflow-auto scroll-height"
  >
    <div
      class="w-full max-w-sm p-4 mx-auto mb-4 border border-gray-700 rounded-lg shadow"
      *ngFor="let _ of [].constructor(4)"
    >
      <div class="flex space-x-4 animate-pulse">
        <div class="flex-1 py-1 space-y-6">
          <div class="h-3 mb-2 rounded bg-slate-700"></div>
          <div class="h-20 rounded bg-slate-700"></div>
          <div class="space-y-3">
            <div class="h-3 rounded bg-slate-700"></div>
            <div class="h-3 rounded bg-slate-700"></div>
            <div class="h-3 rounded bg-slate-700"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <cdk-virtual-scroll-viewport
    class="flex flex-col mx-5 overflow-auto scroll-height"
    autosize
    itemSize="485"
    (scrolledIndexChange)="scrolledIndexChange($event)"
    *ngIf="totalEvent > 0"
  >
    <div
      *cdkVirtualFor="let data of allEvent; let i = index"
      class="flex flex-col my-5 border rounded-lg"
    >
      <app-card-detection
        [headerTitle]="data.title"
        [severity]="data.severity"
        [isVideo]="data.isVideo"
        [mediaUrl]="data.mediaUrl"
        [mediaFiles]="data.mediaFiles"
        [timestamp]="data.timestamp"
        [layoutName]="data.location"
        [robotName]="data.robotName"
        (click)="goToDetectionDetail(data.id)"
      ></app-card-detection>
    </div>
  </cdk-virtual-scroll-viewport>

  <div class="flex flex-col" *ngIf="totalEvent === 0 && isLoading === false">
    <app-no-detection></app-no-detection>
  </div>
</div>
