import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'prettifyPrint',
})
export class PrettifyPrintPipe implements PipeTransform {
  transform(text: string): string {
    if (text.includes(',')) {
      if (text.length > 1) {
        const items = text.split(',');
        const lastItem = items.pop().trim();
        return items.join(', ') + ' and ' + lastItem;
      }
      return text;
    }
    return text;
  }
}
