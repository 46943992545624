import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { SelectOption } from '../form-select/form-select.component';

@Component({
  selector: 'form-auto-complete',
  template: `<mat-form-field class="w-full relative">
    <input
      type="text"
      matInput
      [formControl]="myControl"
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
      <mat-option
        *ngFor="let option of filteredOptions | async"
        [value]="option"
      >
        {{ option.display }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field> `,
  styles: [''],
})
export class FormAutoCompleteComponent implements OnInit {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() selectOptions: SelectOption[] = [];

  @Input() myControl: FormControl = new FormControl();
  public filteredOptions: Observable<SelectOption[]>;

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.selectOptions.slice();
      })
    );
  }

  displayFn(option: any): string {
    return option && option.display ? option.display : '';
  }

  private _filter(name: string): SelectOption[] {
    const filterValue = name.toLowerCase();

    return this.selectOptions.filter((option) =>
      option.display.toLowerCase().includes(filterValue)
    );
  }
}
