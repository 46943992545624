import { ResponseOne } from 'rm-api-services/dist/api-services';
import { Subscription, Observable } from 'rxjs';
import { ApiRobot, MqttSettings, Robot } from 'api-services';
import { CallService } from './../../../services/call.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
} from '@angular/core';
import { TeleOperationService } from 'app/modules/teleoperations/teleoperations.service';
import { CallRobot } from 'app/modules/teleoperations/teleconference/teleconference.component';
import { AuthService, publishTopics } from 'app/core/auth/auth.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-incoming-call',
  templateUrl: './incoming-call.component.html',
  styleUrls: ['./incoming-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // encapsulation: ViewEncapsulation.None,
})
export class IncomingCallComponent implements OnInit {
  robotData: Robot;
  callData;
  constructor(
    public dialogRef: MatDialogRef<IncomingCallComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CallPayload,
    private teleopsSvc: TeleOperationService,
    private _authSvc: AuthService,
    private callSvc: CallService
  ) {
    this.callData = data;
    this.callSvc.playRingtone();
  }

  ngOnInit(): void {
    // auto close call dialog after 30 seconds
    setTimeout(() => {
      this.closeCallModal();
    }, 30000);

    this.callSvc.channel.onmessage = (event) => {
      if (event.data.type === 'answerCall') {
        // answer call from other tab will close the call, only one call can be answered
        this.closeCallModal();
        this.callSvc.dismissAllcalls();
      }
      // if the user decline also hide the call for this modal if robot id is the same
      if (event.data.type === 'declineCall') {
        if (event.data.data.robotId === this.callData.robotId) {
          this.closeCallModal();
        }
      }
    };
  }

  answerCall() {
    this.callSvc.stopRingTone();
    console.log('answer call', this.callData);
    this.teleopsSvc.incomingCall$.next(this.callData);
    // answer call in the same robot's teleops page => answer the call
    this.teleopsSvc.setAnswerCall(true);

    // Helper param to remove all other incoming calls alert in otherpages
    localStorage.setItem('crossTabAnswer', 'true');
    this.callSvc.dismissAllcalls();

    this.dialogRef.close();
  }

  declineCall() {
    localStorage.setItem('crossTabDecline', JSON.stringify(this.callData)); // if the user decline also hide the call
    const timestamp = new Date().getTime();
    const message: CallRobot = {
      id: uuidv4(),
      timestamp: timestamp,
      robotId: this.callData.robotId,
      companyId: this._authSvc.companyId,
      content: this.callData?.content,
      action: 2,
      callId: this.callData?.callId,
    };
    const topicToPublish = publishTopics.callRobot + this._authSvc.companyId;
    this._authSvc.client.unsafePublish(topicToPublish, JSON.stringify(message));
    this.closeCallModal();
  }

  closeCallModal() {
    this.callSvc.stopRingTone();
    this.dialogRef.close();
  }
}

export interface CallPayload {
  robotId: string;
  companyId: string;
  name?: string;
}
