<div>
  <h3 class="text-3xl font-bold text-neutral-50">Patrol areas</h3>

  <p class="mt-1 text-neutral-100">
    Command robot to move along a route of multiple destinations within a
    building.
  </p>

  <!-- Select Start Job  -->
  <div class="mt-5 max-w-[450px]">
    <rm-select-start-job-type
      [label]="'When does this job start?'"
      (selectionChange)="selectStartJobType($event)"
      (jobSchedule)="jobSchedule($event)"
    ></rm-select-start-job-type>
  </div>

  <p class="mt-0 font-normal text-white">Route</p>

  <div class="flex items-start justify-center gap-4 -mt-4 -ml-4">
    <div class="mt-5 -ml-0.5 w-5/12 flex flex-col justify-center">
      <!-- Cdk Drag & drop -->
      <div
        cdkDropList
        class="flex flex-col justify-center w-full routing-list"
        [ngClass]="{ 'cursor-grabbing': isOnDrag }"
        (cdkDropListDropped)="onDropRoute($event)"
      >
        <!-- Item drag & drop -->
        <div
          cdkDrag
          cdkDragLockAxis="y"
          cdkDragBoundary=".routing-list"
          [cdkDragDisabled]="isDisabledDrag"
          (cdkDragReleased)="isOnDrag = false"
          (cdkDragStarted)="isOnDrag = true"
          *ngFor="let route of routingList; let i = index"
          class="flex justify-between routing-drag-item"
        >
          <!-- left icon -->
          <div
            class="relative top-2.5 flex flex-col items-center"
            [ngClass]="{
              hidden: isOnDrag
            }"
          >
            <!-- circle / location icon -->
            <div
              cdkDragHandle
              class="relative w-5 h-6 mb-px text-center -left-px"
              [ngClass]="{
                'top-1': i === routingList.length - 1,
                'top-0.5': i < routingList.length - 1,
                'cursor-grab': !isDisabledDrag
              }"
              #tooltip="matTooltip"
              matTooltip="Drag to reorder"
              matTooltipPosition="below"
              matTooltipClass="routing-patrol-tooltip"
              [matTooltipDisabled]="isDisabledDrag || isOnDrag"
            >
              <mat-icon
                [svgIcon]="
                  i === routingList.length - 1
                    ? 'mat_outline:location_on'
                    : 'mat_outline:circle'
                "
                class="text-center text-neutral-300"
                [ngClass]="
                  i === routingList.length - 1 ? 'icon-size-5' : 'icon-size-3'
                "
              ></mat-icon>
            </div>
            <!-- ./ circle / location icon -->
            <!-- border dash -->
            <div
              class="relative flex flex-col items-center w-5 h-8 text-center border-dashed -top-1 -left-px"
              [ngClass]="{ hidden: i === routingList.length - 1 }"
            >
              <mat-icon
                svgIcon="dashed-line"
                class="mt-1 text-center text-neutral-300 h-9 min-h-9"
              ></mat-icon>
            </div>
            <!-- ./ border dash -->
          </div>
          <!-- ./ left icon -->
          <!-- Dropdown field -->
          <div class="w-full" [ngClass]="{ 'ml-6': isOnDrag }">
            <div *ngIf="route !== ''">
              <patrol-routing-name
                [name]="getRoutingName(route)"
              ></patrol-routing-name>
            </div>

            <shared-form-select
              *ngIf="route === ''"
              class="w-56 max-w-56"
              [data]="selectMarkers"
              [showLabel]="false"
              [selectedOption]="route"
              (selectionChange)="handleChangeMarker($event, 'option', i)"
            ></shared-form-select>
          </div>

          <!-- delete button -->
          <button
            class="self-center w-5 h-5 mb-3 ml-2"
            (click)="removeRoutingDropdown(i)"
            title="Remove destination"
          >
            <mat-icon
              svgIcon="feather:x-circle"
              class="text-neutral-200 icon-size-4"
            ></mat-icon>
          </button>
          <!-- ./ delete button -->
          <!-- ./ Dropdown field -->
          <!-- Preview item drag -->
          <div *cdkDragPreview class="flex items-center w-44">
            <div class="min-w-4"></div>
            <div class="w-full">
              <patrol-routing-name
                [name]="getRoutingName(route)"
              ></patrol-routing-name>
            </div>
          </div>
          <!-- ./ Preview item drag -->
        </div>
        <!-- ./ End item drag & drop -->
      </div>
      <button
        class="flex items-center justify-center gap-2 bg-neutral-400 hover:bg-neutral-300 p-2 rounded w-[80%] mx-auto"
        (click)="addRoutingDropdown()"
        [disabled]="disableAddPatrolRoute"
      >
        <mat-icon class="text-white" svgIcon="mat_outline:add"></mat-icon>
        Add destination
      </button>
    </div>

    <div class="w-full mt-5">
      <shared-mini-layout-map
        [isShowAlert]="true"
        (changeMarker)="
          handleChangeMarker($event.event, $event.action, lastIndex)
        "
      >
        <div messageAlert>
          Click on a marker
          <span class="text-info self-center"
            ><mat-icon
              class="icon-size-4"
              svgIcon="mat_solid:location_on"
            ></mat-icon
          ></span>
          to set the destination.
        </div>
      </shared-mini-layout-map>
    </div>
  </div>

  <div class="w-full mt-5">
    <rm-repeat
      radioButtonLabel="Repeat patrol"
      inputFieldLabel="No. of repeat"
      placeholder="1"
      inputType="number"
      [min]="1"
      (onRepeatChange)="handleRepeatChange($event)"
    ></rm-repeat>
  </div>

  <ng-container *ngIf="blinkingLightsSkill">
    <mat-divider class="my-6"></mat-divider>
    <div class="w-full">
      <slide-toggle
        class="mt-2"
        [label]="'Blinking lights'"
        [description]="'Flash blinking lights until this job is completed.'"
        (onChange)="handleBlinkingLightsCheck($event)"
      ></slide-toggle>
      <!-- [isChecked]="isBlinkingLightsCheck" -->
    </div>
  </ng-container>

  <ng-container *ngIf="broadcastMessageSkill || ttsMessageSkill">
    <mat-divider class="mt-6 mb-8"></mat-divider>
    <div class="flex flex-row w-full">
      <slide-toggle
        class="mt-2"
        (onChange)="handleBroadcastMessageCheck($event)"
      ></slide-toggle>
      <!-- [isChecked]="isBroadcastMessageCheck" -->
      <div class="flex flex-grow">
        <broadcast-job-creation
          class="w-full"
          [robot]="robot"
          [robotSkills]="robotSkills"
          [skill]="broadcastMessageSkill"
          [isChecked]="isBroadcastMessageCheck"
          [isConcurrentSkill]="true"
          (tasksData)="taskDataBroadcastMessage($event)"
        ></broadcast-job-creation>
        <!-- [robot]="robot"
      [layoutId]="robot.layoutId"
      [skill]="selectedSkill"
      [events]="eventsSubject.asObservable()"
      (closeDrawer)="handleClose()"
      (resetType)="resetType()" -->
      </div>
    </div>
  </ng-container>

  <div class="w-full" #bottomOfJobCreation></div>
</div>
