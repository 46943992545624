import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastData } from 'app/modules/dashboard/layout-map/layout-map.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import {
  ApiMission,
  ApiRobot,
  Mission,
  Robot,
} from 'rm-api-services/dist/api-services';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'shared-robot-arrived-toast',
  templateUrl: './robot-arrived.component.html',
  styleUrls: ['./robot-arrived.component.scss'],
})
export class RobotArrivedToastComponent implements OnInit, OnDestroy {
  @Input() data: ToastData;
  @Output() dismissToast = new EventEmitter();
  public mission: Mission;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private apiRobot: ApiRobot,
    private router: Router,
    private snackbar: SnackBarService,
    private apiMission: ApiMission
  ) {}

  ngOnInit() {
    // get the mission data, to display the dispatch location
    this.getMissionDetail(this.data.data.missionId).then((mission) => {
      this.mission = mission;
    });
  }

  ngOnDestroy() {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getRobotDetail(id: string): Promise<Robot> {
    return new Promise((resolve) => {
      this.apiRobot
        .getById(id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((robot) => {
          resolve(robot.result);
        });
    });
  }

  private getMissionDetail(id: string): Promise<Mission> {
    return new Promise((resolve) => {
      this.apiMission
        .getById(id)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((mission) => {
          resolve(mission.result);
        });
    });
  }

  gotoTeleops() {
    this.dismissToast.emit();

    // Get the robot ID
    const robotId = this.data.data.robot?.id;
    const dispatchId = this.data.data.dispatchId;
    if (robotId && dispatchId) {
      const queryParams = {
        dispatchId: dispatchId,
        robotDispatchId: robotId,
      };

      // Construct the URL with the route and query parameters
      const urlTree = this.router.createUrlTree(['/teleoperation', robotId], {
        queryParams: queryParams,
      });

      // Convert the URL tree to a string
      const url = this.router.serializeUrl(urlTree);

      // Open the URL in a new tab, navigate to the Teleoperation route
      window.open(url, '_blank');
    } else {
      this.snackbar.openSnackBar({
        message: 'Robot ID or Dispatch ID not found',
        type: 'failed',
      });
    }
  }

  gotoDetails(id: string) {
    this.dismissToast.emit();
    this.router.navigate(['dispatch-robots', id]);
  }

  closeCardToast() {
    this.dismissToast.emit();
  }
}

export interface RobotDispatch {
  robot: Robot;
  dispatchId: string;
}
