import { ElementRef, Injectable } from '@angular/core';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import {
  MapRobotMarker,
  MarkerType,
  MarkerTypeData,
  MarkerTypeDatas,
  PointRegion,
  Region,
} from 'app/services/api.types';
import { LeafletService } from 'app/services/leaflet.service';
import { moveToPosition } from 'app/shared/utils/robot-marker-util';
import {
  Map,
  Polygon,
  divIcon,
  imageOverlay,
  layerGroup,
  marker,
  polygon,
  polyline,
} from 'leaflet';
import { get } from 'lodash';

const OVERLAY_IMAGE_WIDTH = 3000;
const OVERLAY_IMAGE_HEIGHT = 1500;
const AVAILABLE_ZONE_AREA_COLOR = {
  blue: '#4c9aff',
  red: '#ff7452',
  yellow: '#ffc400',
  grey: '#bfbfbf',
  violet: '#8777d9',
  green: '#2aad27',
  indigo: '#04b4cd',
};

interface RmMarkerOptions extends L.MarkerOptions {
  markerId: string;
  color?: string;
  iconId?: string;
  click?: L.LeafletEventHandlerFn;
  dragstart?: L.LeafletEventHandlerFn;
  dragend?: L.LeafletEventHandlerFn;
  label?: string;
}

interface RmMapOptions extends L.MapOptions {
  click?: L.LeafletMouseEventHandlerFn;
}

interface RmPolylineOptions extends L.PolylineOptions {
  name?: string;
  markerId?: string;
  color?: string;
  click?: L.LeafletEventHandlerFn;
  dragstart?: L.LeafletEventHandlerFn;
  dragend?: L.LeafletEventHandlerFn;
}

interface ZoneArea extends Omit<Region, 'coordinates' | 'metadata'> {
  coordinates: PointRegion[];
  color: string;
}

@Injectable({
  providedIn: 'root',
})
export class MiniLayoutMapService extends LeafletService {
  private isTeleopMode: boolean = false;
  private teleopZoom: number = 1;
  private savedZoom: { [layoutId: string]: number } = {};
  protected _zonesGroup = layerGroup();
  protected _zones: { [markerId: string]: Polygon } = {};

  constructor(protected dashboardService: DashboardService) {
    super(dashboardService);
    this.MAP_TYPE = 'minimap';
  }

  /**
   * Helper function to init map using Maplibre library.
   * After map initiated, it will set function when
   * map load, zooming, & finish zooming
   *
   * @param mapRef Map reference for maplibre
   * @param options Leaflet Map Options
   * @param isMiniLayout used to check if init map for mini layout or not (true / false)
   */
  public initMap(
    mapRef: ElementRef,
    options: RmMapOptions,
    isTeleopMap?: boolean,
    layoutId?: string
  ): void {
    this.isTeleopMode = isTeleopMap;
    const zoomMini = localStorage.getItem('zoomMini');
    if (zoomMini && isTeleopMap) {
      const parsed = JSON.parse(zoomMini);
      this.teleopZoom = parsed[layoutId];
      this.savedZoom[layoutId] = this.teleopZoom;
    }

    this.mapZoom = -2;
    this.map = new Map(mapRef.nativeElement, {
      ...options,
      zoom: isTeleopMap ? this.teleopZoom : this.mapZoom,
    });

    this.map.setMinZoom(options.minZoom);
    this.map.setMaxZoom(options.maxZoom);
    this.map.addLayer(this._markersGroup);
    this.map.addLayer(this._trafficLanesGroup);
    this.map.addLayer(this._plannedPathGroup);
    this.map.addLayer(this._zonesGroup);

    // set current zoom level for slider when user zoom the map
    // using mouse scroll or pinch gesture, so it will reflect
    // the zoom tool slider thumb position.
    this.map.on('zoom', () => {
      if (!this.isUseZoomTool && !this.isTeleopMode) {
        this.mapZoom = this.map.getZoom();
      }
    });

    // set the variable isUseZoomTool to false after zoom finish,
    // so when user use mouse scroll or pitch gesture it will
    // reflect the zoom tool slider thumb position.
    // Then save the current zoom level to local storage.
    this.map.on('zoomend', () => {
      if (this.isUseZoomTool) {
        this.isUseZoomTool = false;
      }

      if (isTeleopMap && layoutId) {
        // Retrieve existing data from localStorage
        const existingData = localStorage.getItem('zoomMini');
        let zoomData;

        // Parse the existing data to an object if it exists, otherwise initialize an empty object
        if (existingData) {
          zoomData = JSON.parse(existingData);
        } else {
          zoomData = {};
        }
        // Add the new object to the existing object as a property
        zoomData[layoutId] = this.map.getZoom();
        // Stringify the updated object
        const updatedData = JSON.stringify(zoomData);
        // Save the updated stringified data to localStorage
        localStorage.setItem('zoomMini', updatedData);
      }
    });

    if (options.click) {
      this.map.on('click', options.click);
    }
  }

  /**
   * Helper function to load map image and render to the container and set the map edge
   *
   * @param images layout map images
   * @param width images width
   * @param height images height
   */
  public loadMapImage(images: string, width?: number, height?: number): void {
    this._image.width = width || OVERLAY_IMAGE_WIDTH;
    this._image.height = height || OVERLAY_IMAGE_HEIGHT;

    this.imageBounds = this.getImageBounds(1);
    imageOverlay(images, this.imageBounds).addTo(this.map);
    this.map.fitBounds(this.imageBounds);
    this.map.setZoom(this.mapZoom);
  }

  /**
   * Add marker to a map from array marker
   *
   * @param lMarkers marker data to be rendered
   * @param type marker type ('marker' or 'robot')
   * @param options options for leaflet marker
   */
  public renderLayoutMarkers(
    lMarkers: MarkerTypeDatas,
    type: MarkerType,
    options: Partial<RmMarkerOptions>,
    isShowSonar: boolean = false
  ): void {
    lMarkers.forEach((lMarker) => {
      if (type === this.MARKER_TYPE.marker && !lMarker['position']) {
        return;
      } else if (
        (type === this.MARKER_TYPE.robot ||
          type === this.MARKER_TYPE.teleopRobot) &&
        !lMarker['point']
      ) {
        return;
      } else if (type === this.MARKER_TYPE.event && !lMarker['layout']) {
        return;
      } else if (
        type === this.MARKER_TYPE.trafficGraph &&
        !lMarker['x'] &&
        !lMarker['y']
      ) {
        return;
      } else if (
        type === this.MARKER_TYPE.trafficGraphMarker &&
        !lMarker['x'] &&
        !lMarker['y']
      ) {
        return;
      }

      this.renderLayoutMarker(lMarker, type, options, isShowSonar);
    });
  }

  /**
   * Add marker to a map
   *
   * @param lMarker marker data
   * @param type marker type ('marker' or 'robot')
   * @param options Leaflet Marker Options
   */
  public renderLayoutMarker(
    lMarker: MarkerTypeData,
    type: MarkerType,
    options: Partial<RmMarkerOptions>,
    isShowSonar: boolean = false
  ): void {
    // first, try to find if layout Marker is already in list of markers.
    // if no, create new marker. Else, update the leaflet marker
    const found = this.findMarkerFromLayoutMarker(lMarker, type);

    if (found) {
      // update the marker
      this.updateMarker(found, lMarker, type, isShowSonar);
      return;
    }

    // Create marker. Then, set the icon and color
    const lfMarker = this.createMarker(lMarker, type, options);
    this.setMarkerIcon(
      lfMarker,
      type,
      lMarker.id,
      lMarker['name'] || lMarker['title'],
      lMarker['detectionNumber'],
      lMarker['heading'],
      lMarker['blinking'],
      lMarker['malfunctionNumber'],
      isShowSonar
    );
  }

  /**
   * Helper function for update the marker DOM for change the color
   * if there is a new detection occur for that marker robot
   * and update it in the map
   *
   * @param markerData Data which marker to be updated
   */
  public updateMarkerHtml(
    markerData: MapRobotMarker,
    isShowSonar?: boolean
  ): void {
    const marker = this.findMarkerFromLayoutMarker(markerData, 'robot');

    if (marker) {
      this.updateMarker(marker, markerData, 'robot', isShowSonar);
    }
  }

  protected updateMarker(
    lfMarker: L.Marker,
    lMarker: MarkerTypeData,
    type: MarkerType,
    isShowRobotSonar?: boolean
  ) {
    this.setMarkerIcon(
      lfMarker,
      type,
      lMarker.id,
      lMarker['name'] || lMarker['title'],
      lMarker['detectionNumber'],
      lMarker['heading'],
      lMarker['blinking'],
      lMarker['malfunctionNumber'],
      isShowRobotSonar
    );

    let coordinates: L.PointTuple = [0, 0];
    switch (type) {
      case this.MARKER_TYPE.marker:
        coordinates = [lMarker['position']?.x, lMarker['position']?.y];
        break;
      case this.MARKER_TYPE.robot:
        coordinates = [lMarker['point']?.x, lMarker['point']?.y];
        break;
      case this.MARKER_TYPE.event:
        coordinates = [lMarker['layout']?.x, lMarker['layout']?.y];
        break;
      case this.MARKER_TYPE.layoutSensor:
        coordinates = [lMarker['coordinate']?.x, lMarker['coordinate']?.y];
        break;
      case this.MARKER_TYPE.trafficGraph:
      case this.MARKER_TYPE.trafficGraphMarker:
      case this.MARKER_TYPE.dispatchMarker:
        coordinates = [lMarker['x'], lMarker['y']];
        break;
    }

    const latlng = this.xYtoLatlng(coordinates);
    lfMarker.setLatLng(latlng);
  }

  protected setMarkerIcon = (
    selectedMarker: L.Marker,
    type: MarkerType,
    markerId: string,
    label?: string,
    detectionNumber?: number,
    heading?: number,
    isBlinking?: boolean,
    malfunctionNumber?: number,
    isShowRobotSonar?: boolean
  ): void => {
    let iconSize: L.PointExpression = [45, 45];
    let iconAnchor: L.PointExpression = [45 / 2, 45 / 2];

    switch (type) {
      case this.MARKER_TYPE.marker:
      case this.MARKER_TYPE.trafficGraphMarker:
      case this.MARKER_TYPE.dispatchMarker:
        iconSize = [20, 29];
        iconAnchor = [20 / 2, 29];
        break;
      case this.MARKER_TYPE.layoutSensor:
        iconSize = [30, 30];
        iconAnchor = [30 / 2, 30 / 2];
        break;
      case this.MARKER_TYPE.trafficGraph:
        iconSize = [10, 10];
        iconAnchor = [10 / 2, 10 / 2];
        break;
    }

    const icon = divIcon({
      className: 'layout-marker',
      html: this.generateHtmlDomString(
        type,
        markerId,
        label,
        detectionNumber,
        heading,
        isBlinking,
        malfunctionNumber,
        isShowRobotSonar
      ),
      iconSize: iconSize,
      iconAnchor: iconAnchor,
    });
    selectedMarker.setIcon(icon);
  };

  protected createMarker(
    lMarker: MarkerTypeData,
    type: MarkerType,
    options: Partial<RmMarkerOptions>
  ): L.Marker {
    // create new marker
    let lPoint: L.PointTuple = [0, 0];
    switch (type) {
      case this.MARKER_TYPE.marker:
        if (lMarker['position']) {
          lPoint = [lMarker['position']?.x, lMarker['position']?.y];
        } else {
          lPoint = [lMarker['x'], lMarker['y']];
        }
        break;
      case this.MARKER_TYPE.robot:
      case this.MARKER_TYPE.teleopRobot:
        lPoint = [lMarker['point']?.x, lMarker['point']?.y];
        break;
      case this.MARKER_TYPE.event:
        lPoint = [lMarker['layout']?.x, lMarker['layout']?.y];
        break;
      case this.MARKER_TYPE.trafficGraph:
      case this.MARKER_TYPE.trafficGraphMarker:
      case this.MARKER_TYPE.dispatchMarker:
        lPoint = [lMarker['x'], lMarker['y']];
        break;
    }

    options = {
      ...options,
      markerId: lMarker.id,
      iconId: lMarker['marker']?.icon ?? null,
      title: lMarker['name'] || lMarker['title'],
      color: lMarker['metadata']?.color ?? 'grey',
      zIndexOffset:
        type === this.MARKER_TYPE.robot ? 402 : options.zIndexOffset,
    };

    // unproject the Point to latlng using zoom level of 1
    const latlng = this.xYtoLatlng(lPoint);

    // create the marker and the events
    const lfMarker = marker(latlng, options);
    if (options.click) {
      lfMarker.on('click', options.click);
    }
    if (options.dragstart) {
      lfMarker.on('dragstart', options.dragstart);
    }
    if (options.dragend) {
      lfMarker.on('dragend', options.dragend);
    }

    const refId = `${lMarker.id}_${this.MAP_TYPE}_${type}`;
    // add to markers group
    this._markersGroup.addLayer(lfMarker);
    // add to the index
    this._markers[refId] = lfMarker;

    return lfMarker;
  }

  public stopRobotMovement(robots: MapRobotMarker[]): void {
    // stop the current movement if the updated marker still moving
    robots.map((robotMarker) => {
      const refId = `${robotMarker.id}_${this.MAP_TYPE}_${this.MARKER_TYPE.teleopRobot}`;
      if (this.updateRobotPositionFrame[refId]) {
        this.updateRobotPositionFrame[refId].stop();
      }
    });
  }

  /**
   * Helper function to render the ant path like line animation for given coordinates
   *
   * @param refId Reference ID of the path planner that used only for reference by leaflet library
   * @param coordinates List of point coordinate which is traversed by the line
   * @param options AntPath options which is the same as Leaflet Polyline options. https://leafletjs.com/reference.html#polyline-option
   */
  public renderPathPlanned(
    refId: string,
    coordinates: L.LatLng[],
    options: any
  ): void {
    // create line between two point
    const path = polyline(coordinates, options);

    const markerId = `${refId}_${this.MAP_TYPE}`;
    // add to lines group
    this._plannedPathGroup.addLayer(path);
    this.map.addLayer(this._plannedPathGroup);
    // add to index
    this._plannedPath[markerId] = path;
  }

  /**
   * Helper function to re render robot marker
   * repeatedly, as if making the marker move slowly using animation
   * and rotate the marker to make the marker facing toward direction
   *
   * @param robotMarker robot marker that want to updated
   */
  public updateRobotMarkerPosition(
    robotType: MarkerType,
    robotMarker: MapRobotMarker,
    moveFramePerSecond: number = 120,
    movePerPixel: number = 0.5
  ): void {
    // find the marker in the list
    const robot: L.Marker = this.findMarkerFromLayoutMarker(
      robotMarker,
      robotType
    );

    if (robot) {
      // get the old position and new position coordinate
      const fromPosition = robot.getLatLng();
      const toPosition = this.xYtoLatlng([
        robotMarker.point.x,
        robotMarker.point.y,
      ]);

      const refId = `${robotMarker.id}_${this.MAP_TYPE}_${robotType}`;
      // stop the current movement if the updated marker still moving
      if (this.updateRobotPositionFrame[refId]) {
        this.updateRobotPositionFrame[refId].stop();
      }
      // calculate the distance between old position and
      // new position of the marker, also calculate the angle between these points
      // then re render the marker based on updated marker position and angle
      this.updateRobotPositionFrame[refId] = moveToPosition(
        [fromPosition.lng, fromPosition.lat],
        [toPosition.lng, toPosition.lat],
        (position: [number, number]) => {
          const xLat = position[1];
          const yLng = position[0];

          if (!isNaN(xLat) && !isNaN(yLng)) {
            this.setRobotAngle(robotMarker.id, robotType, robotMarker.heading);
            robot.setLatLng([xLat, yLng]);

            // update the map center to follow the robot marker position when moving in teleop page
            if (this.isTeleopMode) {
              this.map.panTo([xLat, yLng], { animate: false });
            }
          }
        },
        moveFramePerSecond, // frame per second to move the marker. Higher number make the marker move faster and vice versa
        movePerPixel // number of pixels used to update the marker position for each loop
      );
    }
  }

  /**
   * Helper function to move the center map to selected marker
   *
   * @param position (lat, Lng) coordinates of the marker
   */
  public flyTo(position: L.LatLng | L.LatLngExpression): void {
    // if is teleopmode then set zoom from config
    if (this.isTeleopMode && this.teleopZoom) {
      this.map.flyTo(position, this.teleopZoom);
    } else {
      this.map.flyTo(position);
    }
  }

  /**
   * Helper function to update the blinking light indicator for robot marker
   * in teleop page when the blinking light toggle button is on.
   * It will update the dom marker class to show or hide the light indicator in the marker
   *
   * @param robotId ID of seletced robot that want to update
   * @param isBlinking status of the blinking light. true if it light on and false if light off
   */
  public updateBlinkingMarkerIndicator(
    refId: string,
    isBlinking: boolean
  ): void {
    const parent = document.getElementById(
      `${refId}_${this.MAP_TYPE}_${this.MARKER_TYPE.teleopRobot}`
    );
    if (parent) {
      const blinkingDom = parent.querySelector('.teleop-robot-blinking');
      if (isBlinking) {
        blinkingDom.classList.remove('hidden');
      } else {
        blinkingDom.classList.add('hidden');
      }
    }
  }

  /**
   * Helper function to remove marker from map
   *
   * @param refId Reference ID of the marker
   * @param type Marker type can be 'marker', 'robot or 'event'
   */
  public removeMarker(refId: string, type: MarkerType): void {
    const searchId = `${refId}_${this.MAP_TYPE}_${type}`;
    const selectedMarker = get(this._markers, searchId, '');
    if (selectedMarker) {
      switch (type) {
        case this.MARKER_TYPE.trafficGraphMarker:
        case this.MARKER_TYPE.dispatchMarker:
          this._markersGroup.removeLayer(selectedMarker);
          break;
        case this.MARKER_TYPE.robot:
          this._markersGroup.removeLayer(selectedMarker);
          break;
      }
      delete this._markers[searchId];
    }
  }

  /**
   * Helper function to update the angle of robot facing, depend on different
   * of angle between start position and destination position
   *
   * @param robotId ID of seletced robot that want to update
   * @param mapType type of map. It can be 'layout-map' or 'minimap'
   * @param robotType type of robot. it can be 'robot' or 'teleop-robot'
   * @param angle angle of robot facing
   */
  protected setRobotAngle(
    robotId: string,
    robotType: MarkerType,
    angle: number
  ): void {
    const parent = document.getElementById(
      `${robotId}_${this.MAP_TYPE}_${robotType}`
    );

    const querySelector =
      robotType === 'robot' ? '.robot-sonar' : '.teleop-robot';

    const origin = robotType === 'robot' ? 'bottom center' : 'center center';

    if (parent) {
      const robotFacingDom = parent.querySelector(querySelector);
      if (robotFacingDom) {
        robotFacingDom.setAttribute(
          'style',
          'transform-origin: ' +
            origin +
            ' 0px;transform: rotate(' +
            angle +
            'deg)'
        );
      }
    }
  }

  /**
   * Helper function to generate HTML DOM used for render marker on the map
   * @param type Marker type can be 'marker', 'robot', 'event', or 'layout-sensor'
   * @param markerId Marker ID used as reference for leaflet map
   * @param label Label showed for each marker (optional)
   * @param detectionNumber detection number to showed in the marker. It is used in marker with type 'marker' or 'robot' (optional)
   * @param heading Heading angle robot faced when moving in the layout. It is used in marker with type 'robot' (optional)
   * @returns
   */
  protected generateHtmlDomString(
    type: MarkerType,
    markerId: string,
    label?: string,
    detectionNumber?: number,
    heading?: number,
    isBlinking?: boolean,
    malfunctionNumber?: number,
    isShowSonarRobot?: boolean,
    extraType?: string
  ): string {
    const isDetection = detectionNumber && detectionNumber > 0;
    const isMalfunction = malfunctionNumber && malfunctionNumber > 0;
    const detectionStr =
      detectionNumber && detectionNumber > 1 ? 'detections' : 'detection';
    const malfuctionIcon = isMalfunction
      ? `<img class="w-4 detection-icon" src="/assets/images/markers/malfunction-robot-logo.svg">`
      : '';
    const detectionIcon = isDetection
      ? `<img class="w-4 detection-icon" src="/assets/images/markers/detection-logo.svg">`
      : '';
    const errorIcon =
      isDetection || isMalfunction
        ? `<span class="icon-container -mt-[25px] -ml-[42px] absolute flex items-center justify-center w-10 gap-2">
            ${detectionIcon}
            ${malfuctionIcon}
          </span>`
        : '';
    const detectionBlink = isDetection
      ? `<div class="detection-blinking robot"></div>`
      : '';

    const sonarRobot = isShowSonarRobot
      ? `<div class="robot-sonar ${
          isDetection || isMalfunction ? 'detection' : 'normal'
        }" style="transform-origin: center bottom 0px; transform: rotate(${
          heading ?? 0
        }deg);"></div>`
      : '';
    let htmlString = '';
    switch (type) {
      case this.MARKER_TYPE.marker:
      case this.MARKER_TYPE.trafficGraphMarker:
        htmlString = `
        <div id="${markerId}_${this.MAP_TYPE}_${type}" class="minimap">
          <span class="marker-label normal">${label ?? ''}</span>
          <div class="marker normal">
            <div class="marker-icon"></div>
          </div>
          <div class="marker-shadow"></div>
        </div>
    `;
        break;
      case this.MARKER_TYPE.dispatchMarker:
        htmlString = `
        <div id="${markerId}_${this.MAP_TYPE}_${type}" class="minimap">
          <span class="marker-label detection">${label ?? ''}</span>
          <div class="marker detection">
            <div class="marker-icon"></div>
          </div>
          <div class="marker-shadow"></div>
        </div>
    `;
        break;
      case this.MARKER_TYPE.robot:
        htmlString = `
        <div id="${markerId}_${this.MAP_TYPE}_${type}">
          <span class="robot-label ${
            isDetection || isMalfunction ? 'detection' : 'normal'
          }">
            ${errorIcon}
            ${label ?? ''}
            <span class="robot-detection">${
              isDetection ? detectionNumber : ''
            } ${isDetection ? detectionStr : ''}</span>
          </span>
          <div class="robot-container ${
            isDetection || isMalfunction ? 'detection' : 'normal'
          }">
            <div class="robot ${
              isDetection || isMalfunction ? 'detection' : 'normal'
            }">
              <div class="robot-icon ${
                isDetection || isMalfunction ? 'detection' : 'normal'
              }"></div>
            </div>
          </div>
          ${sonarRobot}
          ${detectionBlink}
        </div>
    `;
        break;
      case this.MARKER_TYPE.event:
        htmlString = `
            <div id="${markerId}_${this.MAP_TYPE}_${type}" class="minimap">
              <span class="${type}-label">${label ?? ''}</span>
              <div class="${type}-icon"></div>
              <div class="${type}"></div>
            </div>
        `;
        break;
      case this.MARKER_TYPE.trafficGraph:
        htmlString = `
            <div id="${markerId}_${this.MAP_TYPE}_${type}">
              <div class="traffic-graph"></div>
              <div class="traffic-graph-blinking hidden"></div>
            </div>
        `;
        break;
      case this.MARKER_TYPE.teleopRobot:
        htmlString = `
        <div id="${markerId}_${this.MAP_TYPE}_${type}">
          <div class="teleop-robot-container">
            <div class="teleop-robot" style="transform-origin: center center 0px; transform: rotate(${
              heading ?? 0
            }deg);"></div>
            <div class="teleop-robot-blinking ${
              isBlinking ? '' : 'hidden'
            }"></div>
          </div>
        </div>
    `;
        break;
      default:
        htmlString = '';
        break;
    }

    return htmlString;
  }
}
