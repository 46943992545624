import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { Layout } from 'rm-api-services/dist/api-services';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { NIL as NIL_UUID } from 'uuid';

@Component({
  selector: 'app-map-quick-navigation',
  templateUrl: './map-quick-navigation.component.html',
  styleUrls: ['./map-quick-navigation.component.scss'],
})
export class MapQuickNavigationComponent implements OnInit, OnDestroy {
  public layoutId: string;
  public siteId: string;
  public layoutType: number = 1;
  public layoutList: Layout[] = [];
  public siteName: string;
  public layoutName: string;
  public isLayoutlistShown: boolean = false;
  public isMultipleRobotJobDrawerOpen = false;
  public isJobDrawerOpened: boolean = false;
  public selectedJobType: string;
  public nilUuid: string = NIL_UUID;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.isLayoutlistShown = false;
      this.layoutId = params.layoutId;
      this.siteId = params.siteId;
      this.layoutType = this.siteId ? 2 : 1;
      if (this.layoutType === 2) {
        this.appService.layouts$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((response) => {
            const tempLayouts = this.sortLayoutsByName(
              response?.result?.list || []
            );
            const siteDetail = tempLayouts.find(
              (layout) => layout.id === this.siteId
            );
            this.layoutList = tempLayouts.filter(
              (layout) => layout.parentId === this.siteId
            );
            this.siteName = siteDetail?.name || 'Site Name';
            if (this.layoutList.length > 0) {
              this.layoutName = this.layoutList.find(
                (layout) => layout.id === this.layoutId
              ).name;
            } else {
              this.layoutName = '';
            }
          });
      } else {
        this.appService.layouts$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((res) => {
            if (res.code === 200 && res.result) {
              const tempLayouts = res?.result?.list || [];
              const currentLayout = tempLayouts.find(
                (layout) => layout.id === this.layoutId
              );
              if (currentLayout) {
                this.layoutName = currentLayout.name;
              }
            }
          });
      }
    });

    // Used to check if the multiple robot job creation drawer is opened
    // If opened, hide the back button when on specific layout map
    this.dashboardService.isMultipleRobotJobDrawerOpened$
      .pipe(takeUntil(this._unsubscribeAll), debounceTime(0))
      .subscribe((isOpen) => {
        this.isMultipleRobotJobDrawerOpen = isOpen;
      });

    // Used to check if the job creation drawer is opened
    // If opened, push back the quick navigation component
    // behind the drawer overlay
    this.dashboardService.selectedSkill$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((skill) => {
        if (skill) {
          this.isJobDrawerOpened = true;
        } else {
          this.isJobDrawerOpened = false;
        }
      });

    // Used to get the selected job type. it can be 'patrol', 'goto', 'broadcast' or 'blinker'
    this.dashboardService.selectedJobType$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((jobType) => {
        this.selectedJobType = jobType;
      });
  }

  public goBack(): void {
    this.dashboardService.toggleRobotDetails$.next(false); //  close robot details panel on goes back
    this.router.navigate(['dashboard']);
  }

  public selectLayout(id: string): void {
    this.dashboardService.selectedRobotCoordinate$.next(null);
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        layoutId: id,
        siteId: this.siteId,
      },
    });
  }

  private sortLayoutsByName(layouts: Layout[]): Layout[] {
    return layouts.sort((a, b) => a.name.localeCompare(b.name));
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
