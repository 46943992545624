import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiBase, ResponseOne } from 'rm-api-services/dist/api-services';
import { Observable } from 'rxjs';
import { Region } from './api.types';
import { withCache } from '@ngneat/cashew';
import { map } from 'rxjs/operators';

interface RmHttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  context?: HttpContext;
}

@Injectable({
  providedIn: 'root',
})
export class ApiRegion extends ApiBase<Region> {
  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = 'region';
  }

  public listZoneAreas = (
    layoutId: string,
    isCached: boolean = false
  ): Observable<ResponseOne<Region[]>> => {
    let option: RmHttpOptions = {
      headers: this.headers,
    };

    if (isCached) {
      option = { headers: this.headers, context: withCache({ key: layoutId }) };
    }

    return this.http.post<ResponseOne<Region[]>>(
      `${this.base}/${this.resourceBaseUrl}/getByLayout`,
      { layoutId },
      option
    );
  };

  /**
   * Workaround because the server doesnt provide
   * a GET /id end point to get the zone's detail.
   *
   * @param id
   * @param layoutId
   * @returns
   */
  public getZoneArea = (id: string, layoutId: string): Observable<Region> =>
    this.listZoneAreas(layoutId).pipe(
      map((res) => res.result.find((item) => item.id === id))
    );
}
