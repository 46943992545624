import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiBase,
  ResponseWithoutList,
  Camera,
  ResponseOne,
} from 'rm-api-services/dist/api-services';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiMonitor extends ApiBase<any> {
  constructor(http: HttpClient, @Inject('environment') environment: any) {
    super(http, environment);
    this.resourceBaseUrl = '/api/v2/monitor';
  }

  getVideoAccessToken(): Observable<ResponseOne<string>> {
    // return this.http.get<ResponseOne<string>>(
    //   '/core/monitor/generate-vc-token'
    // );
    return this.get('monitor/generate-vc-token');
  }
}
