import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RobotTeleopsService {
  private robotLights = {};
  private subjectMap = {};

  constructor() {}

  setLightState(robotId: string, isBlinking: boolean): void {
    this.robotLights[robotId] = isBlinking;
    const subject = this.getOrCreateSubject(robotId);
    subject.next(isBlinking);
  }

  getLightState(robotId: string): ReplaySubject<boolean> {
    return this.getOrCreateSubject(robotId);
  }

  private getOrCreateSubject(robotId: string): ReplaySubject<boolean> {
    if (!this.subjectMap[robotId]) {
      this.subjectMap[robotId] = new ReplaySubject<boolean>(1);
      const isBlinking = this.robotLights[robotId] || false;
      this.subjectMap[robotId].next(isBlinking);
    }
    return this.subjectMap[robotId];
  }
}
