import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-rm-badge',
  templateUrl: './rm-badge.component.html',
  styleUrls: ['./rm-badge.component.scss'],
})
export class RmBadgeComponent implements OnInit {
  @Input() module: string;
  @Input() statusCode: string | number;
  @Input() rounded: 'rounded' | 'rounded-full' = 'rounded';

  public status: string;
  public styleClass: string[];

  constructor() {}

  ngOnInit(): void {
    // Initialize the badge status and style classes
    this.status = this.getStatusString(this.module, this.statusCode);
    this.styleClass = this.getClasses(this.status);
    this.styleClass.push(this.rounded);
  }

  /**
   * Lifecycle hook that reacts to input property changes.
   * Updates the badge's status, style class, and rounded property.
   * @param changes An object containing the changes to input properties.
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes.statusCode && !changes.statusCode.firstChange) {
      // Get the status string based on the module and current status code
      this.status = this.getStatusString(
        this.module,
        changes.statusCode.currentValue
      );

      // Get the appropriate style classes for the badge based on the status
      this.styleClass = this.getClasses(this.status);

      // Add the 'rounded' class to the style classes
      this.styleClass.push(this.rounded);
    }
  }

  /**
   * Gets the appropriate status string based on the module and status code.
   * @param module The module context.
   * @param statusCode The current status code.
   * @returns The corresponding status string.
   */
  public getStatusString(module: string, statusCode: string | number): string {
    // Check the provided module and map the status code to a corresponding status string
    switch (module) {
      case 'dispatchRobot':
        return this.getDispatchStatusString(statusCode as string);
      case 'detection':
        return this.getDetectionStatusString(statusCode as number);
      case 'robotStatus':
        return this.getRobotStatusString(statusCode as number);
      case 'malfunction':
        return this.getMalfunctionStatusString(statusCode as string);
      case 'mission':
        return this.getMissionStatusString(statusCode as number);
      default:
        // If the module is not recognized, use a generic status string
        return `${module} status ${statusCode}`;
    }
  }

  /**
   * Gets the style classes based on the provided status string.
   * @param statusString The status string to determine the appropriate style classes.
   * @returns An array of style classes.
   */
  public getClasses(statusString: string): string[] {
    // Check the provided status string and return corresponding style classes
    switch (statusString) {
      case `Robot Arrive`:
        return ['bg-info/20', 'text-info-300', 'font-bold'];
      case `Done`:
      case `Open`:
      case `Online`:
      case `Active`:
        return ['bg-success/20', 'text-success-400', 'font-bold'];
      case `Cancelled`:
      case `Closed`:
      case `Offline`:
      case `Completed`:
        return ['bg-neutral-400/20', 'text-neutral-200', 'font-bold'];
      case `Ongoing`:
        return ['bg-primary/20', 'text-primary-300', 'font-bold'];
      case `Aborted`:
        return ['bg-black/20', 'text-white', 'font-bold'];
      case `Paused`:
        return ['bg-error-500/20', 'text-error-300', 'font-bold'];
      case `Scheduled`:
        return ['bg-yellow-500/20', 'text-yellow-300', 'font-bold'];
      case `Failed`:
        return ['bg-error-700/20', 'text-error-500', 'font-bold'];
      default:
        // If the status is not recognized, use default text-white style
        return ['text-white'];
    }
  }

  /**
   * Gets the human-readable status string for dispatch robots based on the provided status code.
   * @param statusCode The status code to determine the corresponding status string.
   * @returns The human-readable status string.
   */
  private getDispatchStatusString(statusCode: string): string {
    // Check the provided status code and return corresponding status string
    switch (statusCode) {
      case `robot_arrive`:
        return 'Robot Arrive';
      case `done`:
        return 'Done';
      case `cancelled`:
        return 'Cancelled';
      case `ongoing`:
        return 'Ongoing';
      default:
        // If the status code is not recognized, return the original code as string
        return `${statusCode}`;
    }
  }

  /**
   * Gets the human-readable status string for detection based on the provided status code.
   * @param statusCode The status code to determine the corresponding status string.
   * @returns The human-readable status string.
   */
  private getDetectionStatusString(statusCode: number): string {
    // Check the provided status code and return corresponding status string
    switch (statusCode) {
      case 1:
        return 'Closed';
      case 2:
        return 'Open';
      default:
        // If the status code is not recognized, return the original code as string
        return `${statusCode}`;
    }
  }

  /**
   * Gets the human-readable status string for robot based on the provided status code.
   * @param statusCode The status code to determine the corresponding status string.
   * @returns The human-readable status string.
   */
  private getRobotStatusString(statusCode: number): string {
    // Check the provided status code and return corresponding status string
    switch (statusCode) {
      case 1:
        return 'Online';
      case 2:
        return 'Offline';
      default:
        // If the status code is not recognized, return the original code as string
        return `${statusCode}`;
    }
  }

  /**
   * Gets the human-readable status string for malfunction based on the provided status code.
   * @param statusCode The status code to determine the corresponding status string.
   * @returns The human-readable status string.
   */
  private getMalfunctionStatusString(statusCode: string): string {
    // Check the provided status code and return corresponding status string
    switch (statusCode) {
      case 'open':
        return 'Open';
      case 'closed':
        return 'Closed';
      default:
        // If the status code is not recognized, return the original code as string
        return `${statusCode}`;
    }
  }

  /**
   * Gets the human-readable status string for mission based on the provided status code.
   * @param statusCode The status code to determine the corresponding status string.
   * @returns The human-readable status string.
   */
  private getMissionStatusString(statusCode: number): string {
    // Check the provided status code and return corresponding status string
    switch (statusCode) {
      case 1:
        return 'Completed';
      case 2:
        return 'Aborted';
      case 3:
        return 'Paused';
      case 4:
        return 'Scheduled';
      case 5:
        return 'Active';
      case 6:
        return 'Failed';
      default:
        // If the status code is not recognized, return the original code as string
        return `${statusCode}`;
    }
  }
}
