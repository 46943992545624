import { Observable, catchError, of, switchMap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { enableProdMode, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ResponseOne } from 'rm-api-services/dist/api-services';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  protected apiUrl: string;
  protected coreApirUrl: string;
  private _options = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  constructor(
    public http: HttpClient,
    private _utilityService: UtilityService
  ) {
    this.apiUrl = environment.apiUrl;
    this.coreApirUrl = environment.coreApiUrl;
  }

  public redeemTokenWithCookie(token: string): Observable<ResponseOne<void>> {
    // save user data in local storage
    const decodedToken = this._utilityService.decodeToken(token);
    localStorage.setItem('user_id', decodedToken.user_id);
    localStorage.setItem('company_id', decodedToken.company_id);
    // remove user name to refresh the user detail
    localStorage.removeItem('user_name');

    return this.http.post<ResponseOne<void>>(
      `${this.apiUrl}/login-with-token`,
      {
        token,
      },
      { withCredentials: true }
    );
  }

  public test(): Observable<ResponseOne<void>> {
    return this.http.get<ResponseOne<void>>(`${this.apiUrl}/test`, {
      withCredentials: true,
    });
  }

  public checkCookie(): Observable<boolean> {
    return this.http
      .get(`${this.apiUrl}/validate-cookie`, {
        withCredentials: true,
      })
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false))
      );
  }
}
